/** @format */

// import React from 'react';

const SidebarForm = (/* props, context */) => null;
// Uncomment following code lines to add Sidebar Form
// return (
//   <div className="sidebar-form"></div>
// );

export default SidebarForm;
