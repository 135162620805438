/**
 * /* eslint react/prefer-stateless-function: 0
 *
 * @format
 */
/* eslint react/prefer-stateless-function: 0
 */

import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { formatDepartmentName } from '../common/actions';

class HasRole extends Component {
  render() {
    const { children, currentUserRole, departmentRoles, currentUserDepartment, superAdmin } =
      this.props;
    const userDepartment = formatDepartmentName(currentUserDepartment);
    if (
      !superAdmin &&
      (!Object.prototype.hasOwnProperty.call(departmentRoles, userDepartment) ||
        departmentRoles[userDepartment].indexOf(currentUserRole) === -1)
    ) {
      return null;
    }
    return <>{children}</>;
  }
}

HasRole.propTypes = {
  children: propTypes.node.isRequired,
  currentUserRole: propTypes.string.isRequired,
  currentUserDepartment: propTypes.string.isRequired,
  departmentRoles: propTypes.instanceOf(Object).isRequired,
  superAdmin: propTypes.bool.isRequired,
};

const getMapStateToProps =
  (extendWith = {}) =>
  (state) => {
    const user = state.user.user || {};
    return {
      currentUserRole: user && user.role ? user.role : 'nobody',
      currentUserDepartment: user && user.department ? user.department.name : 'noDepartment',
      superAdmin: user && user.superadmin ? user.superadmin : false,
      ...extendWith,
    };
  };

export default withRouter(connect(getMapStateToProps())(HasRole));
/* export const IsAdmin = connect(getMapStateToProps({
  departmentRoles: { admin: ['manager'] },
}))(HasRole); */
