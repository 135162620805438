/** @format */

import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';

export default function configureStore(history) {
  const middleware = applyMiddleware(thunk, routerMiddleware(history));

  return createStore(
    reducers,
    composeWithDevTools(
      middleware
      // other store enhancers if any
    )
  );
}
