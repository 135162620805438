/** @format */

import _ from 'lodash';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import ReactGA from 'react-ga4';
import * as types from '../static/_types';
import { BACK_MESSAGE } from '../static/_constants';

// JSON TO CSV EXPORT
function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i += 1) {
    let line = '';

    Object.keys(array[i]).forEach((key) => {
      if (line !== '') {
        line += ',';
      }

      line += array[i][key];
      return key;
    });
    str += `${line}\r\n`;
  }

  return str;
}

export function cnicValidation(event) {
  if (/[a-z]/gi.test(event.target.value) && event.target.value !== '') {
    return false;
  }
  return true;
}

export function handleUnload(e) {
  const message = 'o/';
  (e || window.event).returnValue = message;
  e.preventDefault();
  return e.returnValue;
}

function exportCSVFile(items, fileTitle) {
  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);
  const csv = convertToCSV(jsonObject);
  const exportedFileName = `${fileTitle}.csv` || 'export.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function generateCSV(title, data) {
  const fileTitle = `Graana_${title}_${moment().format('LLLL')}`;

  exportCSVFile(data, fileTitle);
}

export function filterExportData(dataToExport) {
  return dataToExport.map((row) => {
    const newRow = row;
    Object.keys(newRow).forEach((key) => {
      const value = row[key] !== null ? String(row[key]) : '';
      newRow[key] =
        value.indexOf(',') > -1 ||
        value.indexOf('(') > -1 ||
        value.indexOf(')') > -1 ||
        key === 'description'
          ? `"${value.replace(/"/g, '""')}"`
          : value.replace(/"/g, '""');
      return row[key];
    });
    return newRow;
  });
}

export function imagesNamesSort(agency) {
  let agencyCoverImagesNames = '';
  let agencyLogoImagesNames = '';
  const { cover, logo } = agency;
  if ((cover && cover.length) || (logo && logo.length)) {
    const arrayLengthCover = agency.cover ? cover.split('/') : ''; // returns length of array
    const arrayLengthLogo = agency.logo ? logo.split('/') : '';
    const imageNameExtensionCover = agency.cover
      ? cover.split('/')[arrayLengthCover.length - 1]
      : ''; // return name with extension
    const imageNameExtensionLogo = agency.logo ? logo.split('/')[arrayLengthLogo.length - 1] : '';
    const imgNameCover = imageNameExtensionCover.split('.');
    const imgNameLogo = imageNameExtensionLogo.split('.');
    const imageNameCover = imgNameCover[0];
    const imageNameLogo = imgNameLogo[0]; // returns name only without extension
    agencyCoverImagesNames =
      agencyCoverImagesNames === ''
        ? `${imageNameCover}`
        : `${agencyCoverImagesNames};${imageNameCover}`;
    agencyLogoImagesNames =
      agencyLogoImagesNames === ''
        ? `${imageNameLogo}`
        : `${agencyLogoImagesNames};${imageNameLogo}`;
  }
  return {
    agencyCoverImagesNames,
    agencyLogoImagesNames,
  };
}

export function checkForHttps(url) {
  const a = url ? url.split('https') : '';
  if (url && a[0] === url) {
    const b = a[0].split('http');
    return `https${b[1]}`;
  }
  return url;
}

export function imgLink(url, type) {
  const a = url ? url.split('res.cloudinary.com') : '';
  const b = url.split('/upload');
  let newUrl;
  if (type === 'h_100' && a[0] !== url) {
    newUrl = checkForHttps(`${b[0]}/upload/h_100,q_80${b[1]}`);
  } else if (type === 'h_250' && a[0] !== url) {
    newUrl = checkForHttps(`${b[0]}/upload/h_250,q_70${b[1]}`);
  } else if (type === 'h_400' && a[0] !== url) {
    newUrl = checkForHttps(`${b[0]}/upload/h_400,q_70${b[1]}`);
  } else if (type === 'h_500' && a[0] !== url) {
    newUrl = checkForHttps(`${b[0]}/upload/h_500,q_70${b[1]}`);
  } else if (type === 'h_600' && a[0] !== url) {
    newUrl = checkForHttps(`${b[0]}/upload/h_600,q_70${b[1]}`);
  } else if (type === 'h_750' && a[0] !== url) {
    newUrl = checkForHttps(`${b[0]}/upload/h_750,q_70${b[1]}`);
  }
  return newUrl || checkForHttps(url);
}

export function getListingTypeLabel(listingType) {
  let listingLabel;
  if (listingType === null) {
    listingLabel = 'All_Requests';
  } else if (listingType === 'basic') {
    listingLabel = 'Standard';
  } else if (listingType === 'premium') {
    listingLabel = 'Premium';
  } else if (listingType === 'premium_plus') {
    listingLabel = 'Premium Plus';
  }
  return listingLabel || listingType;
}

export function createMarkup(html) {
  return { __html: html ? sanitizeHtml(html) : '' };
}

function numberWithCommas(x) {
  return x.toLocaleString();
}

export function convertPrice(price, currency = 'PKR') {
  if (price > 0) {
    if (currency === 'PKR') {
      if (price >= 10000000) {
        return `${Math.round((price / 10000000) * 100) / 100} Crore PKR`;
      }
      if (price >= 100000) {
        return `${Math.round((price / 100000) * 100) / 100} Lac PKR`;
      }
      if (price >= 1000) {
        return `${numberWithCommas(price)} PKR`;
      }
      if (price < 1000) {
        return `${numberWithCommas(price)} PKR`;
      }
    }
  }
  return price;
}

export function formatDepartmentName(departmentName) {
  // TRIMMED LEFT RIGHT WHITESPACE
  // REPLACED INNER WHITESPACE WITH HYPHEN
  // CONVERT STRING TO LOWERCASE TO BE USED IN URLS
  // /*NOT*/ CONVERTED FIRST LETTER OF STRING TO LOWERCASE
  // /*NOT*/ CONVERTED HYPHENATED LOWER CASE STING TO CAMELCASE
  return departmentName && departmentName.trim().replace(/\s+/g, '-').toLowerCase();
}

export function setUpperCase(item) {
  return item?.replace(/\b\w/g, (l) => l.toUpperCase());
}

export function ellipsis(item) {
  return item.length > 20 ? `${item.substring(0, 19)}...` : item;
}

export function ellipsisDropdown(item) {
  return item.length > 10 ? `${item.substring(0, 9)}...` : item;
}

export function ellipsisImage(item) {
  return item.length > 20 ? `${item.substring(0, 19)}...` : item;
}

export function getUserWithRoutes(userData) {
  const newUserData = userData;
  const userRole = newUserData.role;
  const departmentComponents =
    newUserData.department &&
    newUserData.department.components &&
    JSON.parse(newUserData.department.components);
  const userDepartmentComponents = [];
  if (departmentComponents && departmentComponents.length) {
    newUserData.department.name = formatDepartmentName(newUserData.department.name);
    departmentComponents.map((key) => {
      if (key.roles && key.roles[userRole] && key.roles[userRole].v) {
        userDepartmentComponents.push({
          role: { [userRole]: key.roles[userRole] },
          ...key,
        });
      }
      return key;
    });
  }
  return { ...newUserData, ...{ userRoutes: userDepartmentComponents } };
}

// SAVE IMAGES AS ZIP FILE
export function downloadAllImages(id, images, type) {
  const imageIds = images.map((prop) => (type === 'property' ? prop.url : prop));
  const zip = new JSZip();
  const folder = zip.folder(`${type === 'property' ? 'Property Images' : 'Image Bank'} ${id}`);

  imageIds.forEach((url) => {
    const newUrl = url.replace('http://', 'https://');
    const blobPromise = fetch(newUrl).then((r) => {
      if (r.status === 200) {
        return r.blob();
      }
      return Promise.reject(new Error(r.statusText));
    });
    const name = newUrl.substring(newUrl.lastIndexOf('/'));
    folder.file(name, blobPromise);
    return newUrl;
  });
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, `${type === 'property' ? 'Property Images' : 'Image Bank'} ${id}.zip`);
  });
}

export function extendWithDepartmentRoles(item, user) {
  const departmentName = formatDepartmentName(user.department.name);
  return {
    ...item,
    ...{
      departmentRoles: {
        [departmentName]: [user.role],
      },
    },
  };
}

export function setNavItemsDepartmentRoles(navigation, user) {
  // ADDING USER PERMITTED ROUTES TO NAVIGATION
  let navItems = navigation;
  const allowedRoutes = user.userRoutes;
  // Todo: This should be done using a recursive function to handle multi-level navigation
  navItems = navItems
    .map((item) => {
      const { children } = item;
      let newChildren = [];
      const routeFound = _.find(allowedRoutes, (route) => route.route === item.url);
      let newItem = item.url === '/logout' ? item : null;
      if (routeFound) {
        newItem = extendWithDepartmentRoles(item, user);
      }
      if (children && children.length) {
        newChildren = children
          .map((child) => {
            const childRouteFound = _.find(allowedRoutes, (route) => route.route === child.url);
            let newChild = null;
            if (childRouteFound) {
              newChild = extendWithDepartmentRoles(child, user);
              if (!newItem) {
                newItem = extendWithDepartmentRoles(item, user);
              }
            }
            return newChild;
          })
          .filter((child) => !!child);
      }
      if (newItem && newItem.children) {
        newItem.children = newChildren;
      }
      return newItem;
    })
    .filter((item) => !!item);

  return navItems;
}

export function setHeader(dispatch, payload) {
  dispatch({
    type: types.SET_TITLE,
    payload,
  });
}

export function checkDataChanged(isDataChanged) {
  if (!isDataChanged || window.confirm(BACK_MESSAGE)) {
    return false;
  }
  return true;
}

export function setRoute(name, route, slug) {
  return { name, route, slug };
}

export function logPageView(title) {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
}

export function graanaApi() {
  if (process.env.REACT_APP_ENV === 'development') {
    return 'https://www.graana.rocks/';
  }
  if (window.location.hostname.includes('admin.staging.graana.rocks')) {
    return 'https://www.graana.rocks/';
  }
  return 'https://www.graana.com/';
}

export function propMapApi() {
  if (process.env.REACT_APP_ENV === 'development') {
    return 'https://api.maps.graana.me/api/map/';
    // return 'https://dgven0xegdwne.cloudfront.net/api/map/';
  }
  if (window.location.hostname.includes('admin.staging.graana.rocks')) {
    // return 'https://api.maps.graana.me/api/map/';
    return 'https://dgven0xegdwne.cloudfront.net/api/map/';
  }
  return 'https://dgven0xegdwne.cloudfront.net/api/map/';
}
