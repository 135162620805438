/** @format */

import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { PAGE_SIZE_OPTIONS } from '../static/_constants';

function Pagination({ activePage, pages, handlePageSelect, handlePageSize }) {
  return (
    <div className="text-center">
      <ReactPaginate
        previousLabel="‹"
        nextLabel="›"
        forcePage={activePage - 1}
        breakLabel={
          <span
            role="presentation"
            className="customPage"
            tabIndex="-1"
            onClick={(e) => e.preventDefault()}
          >
            ...
          </span>
        }
        breakClassName="break-me"
        pageCount={pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageSelect}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
      />
      <select
        name="pageSize"
        className="form-select custom-select pagination-page-size"
        onChange={handlePageSize}
        required
      >
        {PAGE_SIZE_OPTIONS.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
}

Pagination.propTypes = {
  activePage: PropTypes.instanceOf().isRequired,
  pages: PropTypes.instanceOf().isRequired,
  handlePageSelect: PropTypes.instanceOf().isRequired,
  handlePageSize: PropTypes.instanceOf().isRequired,
};

export default Pagination;
