/** @format */

import React, { useEffect, useState } from 'react';
import axios from '../../utils/axiosInstance';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DEFAULT_PAGE_SIZE } from '../../static/_constants';
import Loading from '../../components/Loading';
import Total from '../../components/Total';
import Pagination from '../../components/Pagination';
import { logPageView, setHeader } from '../../common/actions';

const loadingMsg = 'Loading Projects...';
function AdvancedProjects(props) {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pages, setPages] = useState(1);
  const [responseMessage, setResponseMessage] = useState(loadingMsg);
  const [total, setTotal] = useState(0);
  const endPoint = '/api/project';

  const getParams = () => ({
    params: {
      page: activePage,
      pageSize,
    },
  });

  const saveResponseToState = (response) => {
    setLoading(false);
    setProjects(response.data.items);
    setTotal(response.data.total);
    setResponseMessage('No Projects Found');
    setPages(Math.ceil(response.data.total / pageSize));
  };

  const fetchProjects = () => {
    axios.get(endPoint, getParams()).then((response) => {
      saveResponseToState(response);
    });
  };

  useEffect(() => {
    const { dispatch } = props;
    setLoading(true);
    setProjects([]);
    setResponseMessage(loadingMsg);
    setHeader(dispatch, 'Advanced Projects');
    logPageView('Advanced Projects');
    fetchProjects();
  }, [pageSize, activePage]);

  const handlePageSelect = (page) => {
    setActivePage(page.selected + 1);
  };

  const handlePageSize = (event) => {
    setPageSize(event.target.value);
    setActivePage(1);
  };

  const renderLoading = () => <Loading loading={loading} />;

  return (
    <div className="row animated fadeIn">
      <div className="col-12">
        <div className="row space-1">
          <div className="col-sm-12 text-end">
            <Link to="/advanced-projects/add-project-form">
              <button type="button" className="btn btn-success">
                Add New Project
              </button>
            </Link>
          </div>
        </div>
        {renderLoading()}
        {total ? <Total activePage={activePage} pageSize={pageSize} total={total} /> : null}
        <div className="table-responsive" style={{ borderTopLeftRadius: 10 }}>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Tagline</th>
                <th>Developer</th>
                <th>Approvers</th>
                <th>Date added</th>
              </tr>
            </thead>
            <tbody>
              {projects && projects.length >= 1 ? (
                projects.map((project) => (
                  <tr key={project.id}>
                    <td>{project.id}</td>
                    <td>{project.projectName}</td>
                    <td>{project.projectTagLine}</td>
                    <td>{project.developers ? project.developers.name : ''}</td>
                    <td>
                      {project.projectApprovers
                        ? project.projectApprovers.map(
                            (approver) =>
                              `${approver.approver ? approver.approver.name : ''} (ID: ${
                                approver.approverId
                              }), `
                          )
                        : ''}
                    </td>
                    <td>
                      {project.createdAt ? moment(project.createdAt).format('MMM DD, HH:mm') : ''}
                    </td>
                    <td style={{ width: '320px' }}>
                      <Link
                        to={`/advanced-projects/project-listings/${project.id}`}
                        className="btn btn-primary"
                        style={{ marginRight: 5, whiteSpace: 'nowrap' }}
                      >
                        Manage Listings
                      </Link>
                      <Link
                        to={`/advanced-projects/edit-project-form/${project.id}`}
                        className="btn btn-primary"
                        style={{ marginRight: 5, whiteSpace: 'nowrap' }}
                      >
                        Edit
                      </Link>
                      <Link
                        to={`/advanced-projects/project-details/${project.id}`}
                        className="btn btn-primary"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12} className="text-center">
                    {responseMessage}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          activePage={activePage}
          pages={pages}
          handlePageSelect={(event) => handlePageSelect(event)}
          handlePageSize={(event) => handlePageSize(event)}
        />
      </div>
    </div>
  );
}

AdvancedProjects.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});
export default connect(mapStateToProps)(AdvancedProjects);
