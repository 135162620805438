/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Input, InputGroup } from 'reactstrap';

class Page500 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(/* error, info */) {
    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                <span className="clearfix">
                  <h1 className="float-start display-3 me-4">500</h1>
                  <h4 className="pt-3">Oops, Looks like something went wrong!</h4>
                  <p className="text-muted float-start">
                    But in some other parallel universe this page is working fine.
                  </p>
                </span>
                <InputGroup className="input-prepend">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-search" />
                    </span>
                  </div>
                  <Input
                    size="16"
                    type="text"
                    placeholder="Search or try hitting back button of browser or reload the page"
                  />
                  <div className="input-group-append">
                    <Button color="info">Search</Button>
                  </div>
                </InputGroup>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return children;
  }
}

Page500.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page500;
