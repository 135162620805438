/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { BACK_MESSAGE } from '../static/_constants';

function AlertMessage({ isDataChanged }) {
  return isDataChanged ? <Prompt when={isDataChanged} message={BACK_MESSAGE} /> : null;
}

AlertMessage.propTypes = {
  isDataChanged: PropTypes.instanceOf().isRequired,
};

export default AlertMessage;
