/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { VERSION_NUMBER } from '../static/_constants';

const Footer = () => (
  <footer className="app-footer">
    <span style={{ marginLeft: '15px' }}>
      <Link
        style={{ color: '#9E9E9E', textDecoration: 'none' }}
        to={window.localStorage.getItem('isHome') ? window.localStorage.getItem('isHome') : '/'}
      >
        Graana Admin{' '}
      </Link>
      {VERSION_NUMBER} &copy; 2022.{' '}
      <span className="ms-auto">
        Powered by&nbsp;
        <a
          style={{ color: '#9E9E9E', textDecoration: 'none' }}
          href="https://graana.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Graana.com
        </a>
      </span>
    </span>
  </footer>
);

const mapStateToProps = (state) => ({
  user: state.user.user,
  home: state.home.home,
});

export default connect(mapStateToProps)(Footer);
