/** @format */

import React, { Component } from 'react';
import axios from '../utils/axiosInstance';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as types from '../static/_types';

class Logout extends Component {
  componentDidMount() {
    const { dispatch, history } = this.props;
    axios.defaults.headers.common.Authorization = '';
    dispatch({
      type: types.LOGOUT_USER,
    });
    Cookie.remove('graana_admin_access_token');
    history.push('/login');
    // window.location.href = ('/login');
  }

  render() {
    return <div />;
  }
}

Logout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(connect()(Logout));
