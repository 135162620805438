/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from '../utils/axiosInstance';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Select from 'react-select';
import { PAGE_SIZE_OPTIONS, DEFAULT_PAGE_SIZE } from '../static/_constants';

import {
  generateCSV,
  filterExportData,
  handleUnload,
  setHeader,
  checkDataChanged,
  logPageView,
} from '../common/actions';
import { COMPLAINTS_HEADER, complaintsRowData } from '../common/ExportCSVData';
import SearchBar from '../components/SearchBar';
import Loading from '../components/Loading';

const btnPrimary = 'btn-primary';
const statusInProgress = 'in-progress';

class Complaints extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      property: {
        city: {},
        agency: {},
      },
      status: 'pending',
      isDataChanged: false,
      tickets: [],
      loading: true,
      activePage: 1,
      pages: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      startDate: null,
      endDate: null,
      focusedInput: null,
      selectedAgencyId: null,
      selectedAgentId: null,
      agents: [],
      agencies: [],
      isLoadingAgents: false,
      isLoadingExternally: false,
      clearButton: false,
      setClearButton: 0,
    };
    this.ticketsEndPoint = 'api/inquiry/complaint';
    this.assignStatusEndpoint = 'api/inquiry/updateStatus';
    this.getTickets = this.getTickets.bind(this);
    this.handlePageSelect = this.handlePageSelect.bind(this);
    this.getCSV = this.getCSV.bind(this);
    this.departments = ['sales', 'tech_support', 'media', 'marketing', 'data_entry'];
  }

  componentDidMount() {
    const { dispatch } = this.props;
    setHeader(dispatch, 'Complaints');
    logPageView('Complaints');
    this.getTickets();
  }

  handleDept(name, value) {
    this.setState({
      department: value.target.value,
    });
  }

  handlePageSelect(page) {
    this.setState({ activePage: page.selected + 1, loading: true, tickets: [] }, () => {
      this.getTickets();
    });
  }

  handleSearchType(value) {
    const { setClearButton, clearButton, q } = this.state;
    this.setState(
      {
        type: value ? value.value : null,
        q: '',
        clearButton: false,
      },
      () => {
        if (!value) {
          if (clearButton || (setClearButton === 2 && !q)) {
            this.setState({
              loading: true,
              tickets: [],
              activePage: 1,
            });
            this.getTickets();
          }
        }
      }
    );
  }

  handleSearch() {
    const { q, type, startDate, endDate } = this.state;
    if ((q && type) || startDate || endDate) {
      this.setState(
        {
          loading: true,
          tickets: [],
        },
        () => {
          this.getTickets();
        }
      );
    } else if (!q && !type) {
      this.setState(
        {
          type: null,
          loading: true,
        },
        () => {
          this.getTickets();
        }
      );
    }
    if (q && type) {
      this.setState({ clearButton: true });
    }
    if (!q && !type && (startDate || endDate)) {
      this.setState({ clearButton: false, setClearButton: 0 });
    } else {
      this.setState({ setClearButton: 2 });
    }
  }

  handlePageSize(event) {
    this.setState(
      {
        pageSize: event.target.value,
        activePage: 1,
        loading: true,
        tickets: [],
      },
      () => {
        this.getTickets();
      }
    );
  }

  handleChange(status) {
    this.setState(
      {
        status,
        loading: true,
        tickets: [],
        activePage: 1,
      },
      () => {
        this.getTickets();
      }
    );
  }

  onDatesChange(startDate, endDate) {
    this.setState(
      {
        startDate,
        endDate,
      },
      () => {
        this.handleSearch();
      }
    );
  }

  getTickets() {
    axios.get(this.ticketsEndPoint, this.getParams()).then((response) => {
      this.saveResponseToState(response);
    });
  }

  getCSV() {
    const { loadingBigData } = this.state;
    if (!loadingBigData) {
      this.setState({ loadingBigData: true });
      axios.get(this.ticketsEndPoint, { params: { all: true } }).then((response) => {
        const complaints = response.data.items;
        const data = [];
        if (complaints && complaints.length) {
          complaints.map((complaint, index) => {
            const rowData = complaintsRowData(complaint, index);
            data.push(rowData);
            return rowData;
          });
        }
        let dataToExport = [COMPLAINTS_HEADER, ...data];
        dataToExport = filterExportData(dataToExport);
        generateCSV('Complaints', dataToExport);
        this.setState({ loadingBigData: false });
      });
    }
  }

  getParams() {
    const { status, activePage, pageSize, q, type, startDate, endDate, clearButton } = this.state;
    return {
      params: {
        status,
        page: activePage,
        q: type && clearButton ? q : null,
        searchby: q ? type : null,
        startDate: moment(startDate).toISOString(),
        endDate: moment(endDate).toISOString(),
        pageSize,
      },
    };
  }

  static getCity(/* query */) {
    return axios
      .get('/api/city', {
        params: {
          all: true,
        },
      })
      .then((response) => ({ options: response.data.items }));
  }

  setCity(city) {
    this.setState((prevState) => ({
      isLoadingExternally: !!city,
      property: {
        ...prevState.property,
        city_id: city ? city.id : null,
        area_id: null,
      },
    }));

    if (city && city.id) {
      axios
        .get('/api/agency', {
          params: {
            city_id: city.id,
            all: true,
          },
        })
        .then((response) => {
          this.setState({
            isLoadingExternally: false,
            agencies: response.data.items,
          });
        });
    }
    this.setState({ isDataChanged: true });
  }

  setAgency(agency) {
    this.fetchAgencyAgents(agency.id);
    this.setState({ selectedAgentId: null });
    this.setState({ selectedAgencyId: agency.id });
    this.setState({ isDataChanged: true });
  }

  setAgents(agent) {
    const { property } = this.state;
    property.user_id = agent.id;
    this.setState({ selectedAgentId: agent.id });
    this.setState({ selectedAgentId: agent.id });
    this.setState({ isDataChanged: true });
  }

  fetchAgencyAgents = (agencyId) => {
    const { property } = this.state;
    property.agency_id = agencyId;
    this.setState({ isLoadingAgents: true, selectedAgencyId: agencyId });

    axios.get(`/api/agency/${agencyId}/users`).then((response) => {
      this.setState({
        isLoadingAgents: false,
        agents: response.data,
      });
    });
  };

  setTitle = (event) => {
    this.setState({ title: event.target.value });
    this.setState({ isDataChanged: true });
  };

  setDescription = (event) => {
    this.setState({ description: event.target.value });
    this.setState({ isDataChanged: true });
  };

  getSearchTypes = () => {
    const id = { name: 'Complaint ID', value: 'id' };
    const email = { name: 'Email', value: 'email' };
    const department = { name: 'Department', value: 'department' };
    return [id, email, department];
  };

  clearSearch() {
    this.setState(
      {
        setClearButton: 1,
        clearButton: false,
        q: '',
        loading: true,
        tickets: [],
        activePage: 1,
      },
      () => {
        this.getTickets();
      }
    );
  }

  changeStatus(status, id) {
    axios
      .put(`${this.assignStatusEndpoint}/${id}`, {
        status,
      })
      .then((res) => {
        if (res.data[0]) {
          window.alert(`Complaint ID: ${id} status changed to ${status}`);
          this.getTickets();
        } else {
          window.alert(`Status not changed for complaint ID' ${id}`);
        }
      });
  }

  saveResponseToState(response) {
    const tickets = response.data.items;
    return this.setState((prevState) => ({
      loading: false,
      tickets,
      total: response.data.total,
      pages: Math.ceil(response.data.total / prevState.pageSize),
    }));
  }

  generateTicket(event) {
    event.preventDefault();
    const { loading } = this.state;

    if (!loading) {
      this.setState({
        loading: true,
      });

      const { selectedAgencyId, selectedAgentId, description, department, title } = this.state;
      axios
        .post('/api/inquiry/createFeedback', {
          agency_id: selectedAgencyId,
          title,
          description,
          department,
          user_id: selectedAgentId,
        })
        .then((response) => {
          if (response) {
            window.alert('Ticket Created');
          }
          this.setState({
            loading: false,
            modalVisible: false,
          });
          this.getTickets();
        })
        .catch((error) => {
          if (error.response.data === 'Email already exist') {
            window.alert('Ticket already exist with the same email address.');
          }
          this.setState({ loading: false });
        });
    }
    this.setState({ isDataChanged: false });
  }

  dataChanged() {
    const { isDataChanged } = this.state;
    const checkData = checkDataChanged(isDataChanged);
    if (!checkData) {
      this.setState({ modalVisible: false, isDataChanged: false });
    } else {
      this.setState({ modalVisible: true });
    }
  }

  renderSearch() {
    const { type, q } = this.state;
    return (
      <input
        className="form-control"
        type="text"
        name="search"
        placeholder="Search"
        value={q}
        onChange={(event) =>
          this.setState({
            q: event.target.value ? event.target.value : undefined,
            clearButton: false,
          })
        }
        onKeyPress={(event) => {
          if (event.key === 'Enter' && type && q) {
            this.handleSearch();
          }
        }}
      />
    );
  }

  renderLoading() {
    const { loading } = this.state;
    return <Loading loading={loading} />;
  }

  render() {
    const { user } = this.props;
    const {
      tickets,
      loading,
      activePage,
      pages,
      q,
      type,
      total,
      pageSize,
      status,
      loadingBigData,
      startDate,
      endDate,
      focusedInput,
      modalVisible,
      property,
      isLoadingExternally,
      selectedAgencyId,
      agencies,
      selectedAgentId,
      isLoadingAgents,
      agents,
      clearButton,
      isDataChanged,
    } = this.state;
    const searchTypes = this.getSearchTypes();
    return (
      <div className="row animated fadeIn">
        {window.removeEventListener('beforeunload', handleUnload)}
        {isDataChanged && modalVisible
          ? window.addEventListener('beforeunload', handleUnload)
          : window.removeEventListener('beforeunload', handleUnload)}
        <Modal
          size="md"
          isOpen={modalVisible}
          toggle={() => {
            this.dataChanged();
          }}
        >
          <ModalHeader
            toggle={() => {
              this.dataChanged();
            }}
          >
            <div className="grey">New Ticket</div>
          </ModalHeader>
          <ModalBody>
            <form
              onSubmit={(event) => {
                this.generateTicket(event);
              }}
            >
              <div>
                <label className="control-label col-md-3 col-sm-3" style={{ paddingLeft: 0 }}>
                  City*
                </label>
                <Select.Async
                  name="city"
                  multi={false}
                  style={{ borderColor: '#c2cfd6', borderRadius: '5px' }}
                  value={property.city_id}
                  onChange={(value) => this.setCity(value)}
                  loadOptions={(input) => Complaints.getCity(input)}
                  valueKey="id"
                  labelKey="name"
                  clearable={false}
                  required
                />
              </div>

              <div className="form-group row">
                <div
                  className="col-md-6 col-sm-6"
                  style={{ flex: '0 0 100%', maxWidth: '100%', paddingTop: 10 }}
                >
                  <label className="control-label col-md-3 col-sm-3" style={{ paddingLeft: 0 }}>
                    Agency*
                  </label>
                  <Select
                    name="agency"
                    style={{ borderColor: '#c2cfd6', borderRadius: '5px' }}
                    isLoading={isLoadingExternally}
                    multi={false}
                    value={selectedAgencyId}
                    onChange={(value) => this.setAgency(value)}
                    options={agencies}
                    valueKey="id"
                    labelKey="name"
                    clearable={false}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6 col-sm-6" style={{ flex: '0 0 100%', maxWidth: '100%' }}>
                  <label className="control-label col-md-3 col-sm-3" style={{ paddingLeft: 0 }}>
                    Agent*
                  </label>
                  <Select
                    name="agent"
                    style={{ borderColor: '#c2cfd6', borderRadius: '5px' }}
                    isLoading={isLoadingAgents}
                    multi={false}
                    value={selectedAgentId}
                    onChange={(value) => this.setAgents(value)}
                    options={agents}
                    valueKey="id"
                    labelKey="first_name"
                    clearable={false}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6 col-sm-6" style={{ flex: '0 0 100%', maxWidth: '100%' }}>
                  <label className="control-label col-md-3 col-sm-3" style={{ paddingLeft: 0 }}>
                    Title*
                  </label>
                  <input
                    className="form-control"
                    style={{ borderColor: '#c2cfd6', borderRadius: '5px' }}
                    type="text"
                    placeholder="Title"
                    name="complainttitle"
                    validations="isAlphanumeric"
                    required
                    onChange={this.setTitle.bind(this)}
                  />
                </div>
              </div>

              <label className="control-label col-md-3 col-sm-3" style={{ paddingLeft: 0 }}>
                Department*
              </label>
              <select
                style={{ borderColor: '#c2cfd6', borderRadius: '5px', marginBottom: '10px' }}
                className="form-select"
                name="department"
                required
                onChange={(select) => this.handleDept('department', select)}
              >
                <option>Select dept...</option>
                {['sales', 'tech_support', 'media', 'marketing', 'data_entry'].map(
                  (item, index) => (
                    <option key={item.id || index} value={item}>
                      {item
                        .split('_')
                        .join(' ')
                        .replace(/\b\w/g, (l) => l.toUpperCase())}
                    </option>
                  )
                )}
              </select>

              <label className="control-label col-md-3 col-sm-3" style={{ paddingLeft: 0 }}>
                Description*
              </label>
              <input
                className="form-control"
                style={{
                  borderColor: '#c2cfd6',
                  borderRadius: '5px',
                  width: '100%',
                  height: '35px',
                  marginBottom: '30px',
                }}
                type="textarea"
                placeholder="&nbsp;Description"
                name="complainttitle"
                validations="isAlphanumeric"
                required
                onChange={this.setDescription.bind(this)}
              />

              <Button
                style={{ color: 'white', width: '100%', marginTop: '10px' }}
                className={`btn btn-block btn-xlg btn-graana-red space-1 ${
                  loading ? 'disabled' : ''
                }`}
              >
                <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} /> Submit
              </Button>
            </form>
          </ModalBody>
        </Modal>
        <div className="col-sm-12">
          <div className="row space-1">
            <SearchBar
              type={type}
              searchTypes={searchTypes}
              q={q}
              clearButton={clearButton}
              handleSearchType={(value) => this.handleSearchType(value)}
              renderSearch={() => this.renderSearch()}
              handleSearch={() => this.handleSearch()}
              clearSearch={() => this.clearSearch()}
            />
            <div className="col-sm-8 text-end">
              <strong> From: </strong> &nbsp;
              <DateRangePicker
                isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                startDate={startDate}
                endDate={endDate}
                displayFormat={() => 'DD/MM/YYYY'}
                startDateId="date_input_start"
                endDateId="date_input_end"
                onDatesChange={({ startDate: dateStart, endDate: dateEnd }) =>
                  this.onDatesChange(dateStart, dateEnd)
                }
                focusedInput={focusedInput}
                onFocusChange={(input) => this.setState({ focusedInput: input })}
                showClearDates
                minimumNights={0}
              />
            </div>
          </div>
          <div className="row space-1">
            <div className="col-sm-6">
              <button
                type="button"
                style={{
                  marginRight: 5,
                }}
                className={`${status === 'pending' ? btnPrimary : ''} btn btn-default`}
                onClick={() => {
                  this.handleChange('pending');
                }}
              >
                Pending
              </button>
              <button
                type="button"
                style={{
                  marginRight: 5,
                }}
                className={`${status === statusInProgress ? btnPrimary : ''} btn btn-default`}
                onClick={() => {
                  this.handleChange(statusInProgress);
                }}
              >
                In progress
              </button>
              <button
                type="button"
                style={{
                  marginRight: 5,
                }}
                className={`${status === 'completed' ? btnPrimary : ''} btn btn-default`}
                onClick={() => {
                  this.handleChange('completed');
                }}
              >
                Completed
              </button>
            </div>
            <div className="col-sm-6 text-end" style={{ display: 'inline' }}>
              <button
                type="button"
                className="btn btn-default btn-success"
                onClick={() => {
                  this.setState({ modalVisible: true });
                }}
              >
                Generate Ticket
              </button>
            </div>
          </div>
          {this.renderLoading()}
          {total ? (
            <div className="text-end row-sm-4" style={{ marginBottom: 5 }}>
              Showing {(activePage - 1) * pageSize + 1} -{' '}
              {activePage * pageSize >= total ? total : activePage * pageSize} / {total}
            </div>
          ) : null}
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th width="30px" className="text-center">
                    ID
                  </th>
                  <th>Title</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Agency</th>
                  <th>Department</th>
                  <th>Status</th>
                  <th>Date Added</th>
                  <th>Move to</th>
                </tr>
              </thead>
              <tbody>
                {tickets &&
                  tickets.map((item, index) => (
                    <tr key={`${index + 1}`}>
                      <td>{item.id}</td>
                      <td>{item.title}</td>
                      <td>
                        {item.user ? `${item.user.first_name} ${item.user.last_name}` : item.name}
                      </td>
                      <td>{item.user ? item.user.email : item.email}</td>
                      <td>{item.agency ? item.agency.name : ''}</td>
                      <td>
                        {item.department ? item.department.split('_').join(' ').toUpperCase() : ''}
                      </td>
                      <td>{item.status}</td>
                      <td>{item.createdAt ? moment(item.createdAt).format('ll') : ''}</td>
                      <td>
                        {item.status === 'pending' ? (
                          <button
                            className="btn btn-default btn-primary"
                            type="submit"
                            onClick={() => this.changeStatus(statusInProgress, item.id)}
                          >
                            Progress
                          </button>
                        ) : (
                          ''
                        )}
                        {item.status === statusInProgress ? (
                          <button
                            className="btn btn-default btn-primary"
                            type="button"
                            onClick={() => this.changeStatus('completed', item.id)}
                          >
                            Completed
                          </button>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {item.unread ? (
                          <p className="text-center unreadCount ">
                            {item.unread} <span className="fa fa-comment" />
                          </p>
                        ) : null}
                      </td>
                      <td>
                        <Link to={`/complaints/complaints-details/${item.id}`}>
                          <span className="fa fa-tasks" title="Details" aria-hidden="true" />
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="text-center">
            <ReactPaginate
              previousLabel="‹"
              nextLabel="›"
              forcePage={activePage - 1}
              breakLabel={
                <span
                  role="presentation"
                  className="customPage"
                  tabIndex="-1"
                  onClick={(e) => e.preventDefault()}
                >
                  ...
                </span>
              }
              breakClassName="break-me"
              pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageSelect}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
            {(user && user.superadmin) ||
            (startDate && endDate && endDate.diff(startDate, 'days') < 32) ? (
              <button type="button" className="download-all btn btn-success" onClick={this.getCSV}>
                Download All&nbsp;
                <i className={`fa ${loadingBigData ? 'fa-spinner fa-pulse red' : 'fa-download'}`} />
              </button>
            ) : null}
            <select
              name="pageSize"
              className="form-select custom-select pagination-page-size"
              onChange={(event) => this.handlePageSize(event)}
              required
            >
              {PAGE_SIZE_OPTIONS.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
Complaints.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(Complaints);
