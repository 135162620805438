/** @format */

// import React from 'react';

const SidebarHeader = (/* props, context */) => null;
// Uncomment following code lines to add Sidebar Header
// return (
//   <div className="sidebar-header"></div>
// )

export default SidebarHeader;
