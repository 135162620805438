/** @format */

export const RES = '#B85C00';
export const COM = '#0070C0';
export const REC = '#2ECC71';
export const OP = '#11ED4B';
export const EDU_INS_PB = '#FFFF00';
export const REL_RELIGION = '#fff';
export const HEALTH = '#FFC9CA';
export const PARKINGS = '#A6ACAF';
