/** @format */

import React from 'react';
import PropTypes from 'prop-types';

function Loading({ loading }) {
  return loading ? (
    <div className="col-sm-12 text-center space-1">
      <i className="fa fa-spinner fa-pulse" style={{ fontSize: '50px' }} />
    </div>
  ) : null;
}

Loading.propTypes = {
  loading: PropTypes.instanceOf().isRequired,
};

export default Loading;
