/** @format */

import React, { useState, useEffect } from 'react';
import './styles.scss';
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import Formsy from 'formsy-react';
import Cookie from 'js-cookie';
import axios from '../utils/axiosInstance';
import CryptoJS from 'crypto-js';
import GraanaLogo from '../components/GraanaLogo';
import { cryptoSecret } from '../utils/OtpUrls';
import { VERSION_NUMBER } from '../static/_constants';

const ResetPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [isFromLogInPage, setIsFromLogInPage] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [logoMargin, setLogoMargin] = useState(35);

  const [newPassword, setNewPassword] = useState(null);
  const [passwordEye, setpasswordEye] = useState(true);
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  const [confPassword, setConfPassword] = useState(null);
  const [confirmEye, setConfirmEye] = useState(true);
  const [confirmFieldType, setConfirmFieldType] = useState('password');

  useEffect(() => {
    if (Cookie.get('graana_admin_access_token_reset')) {
      setIsFromLogInPage(true);
    }
  }, []);

  const passwordEyeClick = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
      setpasswordEye(false);
    } else {
      setPasswordFieldType('password');
      setpasswordEye(true);
    }
  };

  const confirmEyeClick = () => {
    if (confirmFieldType === 'password') {
      setConfirmFieldType('text');
      setConfirmEye(false);
    } else {
      setConfirmFieldType('password');
      setConfirmEye(true);
    }
  };

  const matchPasswordString = (password) => {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (password.length < 1) {
      setLogoMargin(35);
      setErrorMessage(null);
      return false;
    }
    if (!pattern.test(password)) {
      setErrorMessage(
        'Password must consist of minimum 8 characters and contain uppercase, lowercase, numbers and special characters.'
      );
      setLogoMargin(45);
      return false;
    }
    setLogoMargin(35);
    setErrorMessage(null);
    return true;
  };

  const submitForm = () => {
    const { history } = props;
    let body = null;
    let url = null;
    setLoading(true);
    if (newPassword === confPassword) {
      if (isFromLogInPage) {
        axios.defaults.headers.common.Authorization = `Bearer ${Cookie.get(
          'graana_admin_access_token_reset'
        )}`;
        body = {
          password: newPassword,
          confirmPassword: confPassword,
        };
        url = '/api/system-user/reset-password';
      } else {
        const decodedUserId = JSON.parse(
          Buffer.from(props?.location?.state?.userIdEncoded.split('.')[1], 'base64').toString()
        );
        const encryptedUserId = CryptoJS.AES.encrypt(`${decodedUserId}`, cryptoSecret()).toString();
        body = {
          password: newPassword,
          confirmPassword: confPassword,
          userId: encryptedUserId,
          email: props?.location?.state?.phoneOrEmail,
        };
        url = '/api/system-user/forgot-password';
      }

      axios
        .put(url, body)
        .then((response) => {
          setLoading(false);
          window.alert(response.data);
          if (response?.status === 200) {
            Cookie.remove('graana_admin_access_token');
            Cookie.remove('graana_admin_access_token_reset');
            history.push('/login');
          }
        })
        .catch((error) => {
          setLoading(false);
          window.alert(error.response.data);
        });
    } else {
      setLoading(false);
      window.alert('Password you entered does not match.');
    }
  };

  return (
    <div className="app flex-row align-items-center animated fadeIn">
      <Container>
        <Row className="justify-content-center">
          <div className={isFromLogInPage ? 'col-md-5' : 'col-md-8'}>
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <div>
                    <div className="text-center mb-4">
                      <img
                        src="../img/resetPassword.png"
                        alt="forget icon"
                        style={{ height: '77px', width: '77px' }}
                      />
                    </div>
                    <div className="text-center mb-2">
                      <label className="text-center heading">Set New Password</label>
                    </div>
                    <p className="text mb-4">
                      Your new password must be different from previously used password
                    </p>
                  </div>
                  <div>
                    <Formsy onValidSubmit={submitForm}>
                      <InputGroup className="mb-3">
                        <InputGroupText
                          style={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}
                        >
                          <i className="icon-lock" />
                        </InputGroupText>
                        <Input
                          style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                          type={passwordFieldType}
                          placeholder="New Password"
                          required
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                            matchPasswordString(e.target.value);
                          }}
                        />
                        <button
                          type="button"
                          onClick={passwordEyeClick}
                          className="eyeIcon"
                          tabIndex="-1"
                        >
                          <i
                            className={`fa ${passwordEye ? 'fa-eye-slash' : 'fa-eye'}`}
                            style={{ color: '#4c5154' }}
                          />
                        </button>
                      </InputGroup>

                      <InputGroup className="mb-4">
                        <InputGroupText
                          style={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}
                        >
                          <i className="icon-lock" />
                        </InputGroupText>
                        <Input
                          style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                          type={confirmFieldType}
                          placeholder="Confirm Password"
                          required
                          onChange={(e) => setConfPassword(e.target.value)}
                        />
                        <button
                          type="button"
                          onClick={confirmEyeClick}
                          className="eyeIcon"
                          tabIndex="-1"
                        >
                          <i
                            className={`fa ${confirmEye ? 'fa-eye-slash' : 'fa-eye'}`}
                            style={{ color: '#4c5154' }}
                          />
                        </button>
                      </InputGroup>
                      {errorMessage ? (
                        <div className="text-left mb-3">
                          <Row>
                            <Col xs="12">
                              <label htmlFor="" className="" style={{ color: 'red' }}>
                                {errorMessage}
                              </label>
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                      <div className="text-center">
                        <Row>
                          <Col xs="12">
                            <Button
                              color="graana-red"
                              style={{ color: 'white' }}
                              className={`px-4 ${loading ? 'disabled' : ''}`}
                            >
                              <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} />{' '}
                              Reset
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Formsy>
                  </div>
                </CardBody>
                <div className="text-center versionLabel">{VERSION_NUMBER}</div>
              </Card>
              {isFromLogInPage ? null : (
                <GraanaLogo styles={{ width: '70%', margin: '2% auto' }} marginValue={logoMargin} />
              )}
            </CardGroup>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword;
