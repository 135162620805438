/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

function SearchBar({
  type,
  searchTypes,
  q,
  clearButton,
  handleSearchType,
  renderSearch,
  handleSearch,
  clearSearch,
}) {
  return (
    <div className="col-sm-4" style={{ paddingLeft: '30px' }}>
      <div className="row">
        <div className="col-sm-4 no-padding">
          <Select
            name="searchBy"
            value={type}
            style={{ borderRadius: 5 }}
            onChange={(value) => handleSearchType(value)}
            options={searchTypes}
            labelKey="name"
            valueKey="value"
            placeholder="by"
          />
        </div>
        <div className="col-sm-4 no-padding">{renderSearch()}</div>
        <div className="col-sm-4 no-padding">
          {!clearButton ? (
            <button
              type="button"
              onClick={() => handleSearch()}
              className="btn btn-primary"
              disabled={!!(!type || !q)}
            >
              Search
            </button>
          ) : null}
          {clearButton ? (
            <button type="button" className="btn btn-success" onClick={() => clearSearch()}>
              Clear
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  type: PropTypes.instanceOf().isRequired,
  searchTypes: PropTypes.instanceOf().isRequired,
  q: PropTypes.instanceOf().isRequired,
  clearButton: PropTypes.instanceOf().isRequired,
  handleSearchType: PropTypes.instanceOf().isRequired,
  renderSearch: PropTypes.instanceOf().isRequired,
  handleSearch: PropTypes.instanceOf().isRequired,
  clearSearch: PropTypes.instanceOf().isRequired,
};

export default SearchBar;
