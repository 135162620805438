import axios from 'axios';

// Get the base URL from the environment variable
const baseURL = process.env.REACT_APP_BASE_URL;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    newConfig.baseURL = baseURL;
    return newConfig;
  },
  (error) => Promise.reject(error)
);
// Object.setPrototypeOf(axiosInstance, axios);
// Export the Axios instance
axiosInstance.CancelToken = axios.CancelToken;
axiosInstance.isCancel = axios.isCancel;
axiosInstance.all = axios.all;
axiosInstance.spread = axios.spread;
export default axiosInstance;
