/** @format */

import { combineReducers } from 'redux';
import * as types from '../static/_types';

const propertyParams = (state = null, action) => {
  switch (action.type) {
    case types.SET_PROPERTY:
      return action.payload || null;
    case types.CLEAR_PROPERTY:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  propertyParams,
});
