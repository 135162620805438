/** @format */

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
} from 'reactstrap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { Pagination, PaginationItem } from '@mui/material';
import PropTypes from 'prop-types';
import axios from '../../utils/axiosInstance';
import Loading from '../../components/Loading';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../static/_constants';

import {
  handleUnload,
  setHeader,
  logPageView,
  ellipsisDropdown,
  setUpperCase,
} from '../../common/actions';

const dateFormat = 'HH:mm, MMM DD';
const loadingMsg = 'Loading Logs...';
const activeValue = 'property-refreshed';
const emptyLoadingMsg = 'No Logs Found';

function AiraLogs(props) {
  const [logs, setLogs] = useState([]);
  const [q, setQ] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [systemUsers, setSystemUsers] = useState([]);
  const [selectedSystemUser, setSelectedSystemUser] = useState();
  const [systemUserName, setSystemUserName] = useState();
  const [systemUserId, setSystemUserId] = useState();
  const [open, setOpen] = useState();
  const [activityLogsValue, setActivityLogsValue] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [maxDate] = useState(new Date());
  const [selectedFilter, setSelectedFilter] = useState(false);
  const [dartDetailsModal, setDartDetailsModal] = useState(false);
  const [dartDetailsId, setDartDetailsId] = useState();
  const [dartDetailsData, setDartDetailsData] = useState();
  const [activePage, setActivePage] = useState(1);
  const [activePurpose, setActivePurpose] = useState('dart');
  const [activeStatus, setActiveStatus] = useState(activeValue);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pages, setPages] = useState(1);
  const [responseMessage, setResponseMessage] = useState(loadingMsg);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [loadingModal, setLoadingModal] = useState(true);
  const [clearButton] = useState(false);
  const [cancelToken, setCancelToken] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userLoading, setUserLoading] = useState(true);

  const searchInput = useRef(null);
  const airaEndPoint = '/api/dhoondo/kpi-logs';
  const cataloguerEndPoint = '/api/cataloguer/logs';
  const riderEndPoint = '/api/riderLogs/media';

  const getParams = () => ({
    params: {
      page: activePage,
      pageSize,
      systemUserId: activeIndex !== 2 && selectedSystemUser ? systemUserId : null,
      riderId: activeIndex === 2 && selectedSystemUser ? systemUserId : null,
      startDate: startDate ? moment(startDate).toISOString() : null,
      endDate: endDate ? moment(endDate).toISOString() : null,
      leadId: activeIndex === 0 && q ? q : null,
      propertyId: (activeIndex === 1 || activeIndex === 2) && q ? q : null,
    },
  });

  const renderTitle = () => 'Activity Logs';

  const saveResponseToState = (response) => {
    setLoading(false);
    setLogs(response.data.kpis.rows);
    setTotal(response.data.kpis.count);
    setResponseMessage(emptyLoadingMsg);
    setPages(Math.ceil(response.data.kpis.count / pageSize));
  };

  const saveCataloguerResponse = (response) => {
    setLoading(false);
    setLogs(response.data.cataloguerLogs.rows);
    setTotal(response.data.cataloguerLogs.count);
    setResponseMessage(emptyLoadingMsg);
    setPages(Math.ceil(response.data.cataloguerLogs.count / pageSize));
  };

  const saveRiderResponse = (response) => {
    setLoading(false);
    setLogs(response.data.riderLogs.rows);
    setTotal(response.data.riderLogs.count);
    setResponseMessage(emptyLoadingMsg);
    setPages(Math.ceil(response.data.riderLogs.count / pageSize));
  };

  const fetchLogs = () => {
    const { match } = props;
    axios.get(`${airaEndPoint}/${activePurpose}`, getParams(match)).then((response) => {
      saveResponseToState(response);
    });
  };

  const fetchCataloguerLogs = () => {
    const { match } = props;
    axios.get(`${cataloguerEndPoint}/${activeStatus}`, getParams(match)).then((response) => {
      saveCataloguerResponse(response);
    });
  };

  const fetchRiderLogs = () => {
    const { match } = props;
    axios.get(riderEndPoint, getParams(match)).then((response) => {
      saveRiderResponse(response);
    });
  };

  useEffect(() => {
    const { dispatch } = props;
    setHeader(dispatch, renderTitle());
    logPageView(renderTitle());
    setLoading(true);
    if (activeIndex === 0) {
      setLogs([]);
      setResponseMessage(loadingMsg);
      fetchLogs();
    } else if (activeIndex === 1) {
      setLogs([]);
      setResponseMessage(loadingMsg);
      fetchCataloguerLogs();
    } else if (activeIndex === 2) {
      setLogs([]);
      setResponseMessage(loadingMsg);
      fetchRiderLogs();
    }
  }, [
    pageSize,
    activePage,
    activePurpose,
    activeStatus,
    search,
    clearButton,
    activeIndex,
    systemUserName,
    startDate,
    endDate,
  ]);

  const getSystemUser = (e) => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken?.cancel('Operation cancelled due to new request');
    }
    if (e && e.target && e.target.value) {
      setUserEmail(e.target.value);
    } else {
      setUserEmail(null);
    }
    setCancelToken(axios.CancelToken.source());
  };

  useEffect(() => {
    setUserLoading(true);
    setSystemUsers([]);
    axios
      .get(activeIndex === 2 ? '/api/rider' : '/api/system-user/basic-info?getActive=true', {
        cancelToken: cancelToken?.token,
        params: {
          all: true,
          q: userEmail,
        },
      })
      .then((response) => {
        setUserLoading(false);
        setSystemUsers(activeIndex === 2 ? response.data.items : response.data);
      })
      .catch((/* error */) => {
        // console.log(error)
      });
  }, [cancelToken]);

  useEffect(() => {
    getSystemUser();
  }, []);

  const handlePageSelect = (event, page) => {
    setActivePage(page);
    setSelectedFilter(true);
  };

  const handlePageSize = (event) => {
    setPageSize(event.target.value);
    setActivePage(1);
    setSelectedFilter(true);
  };

  const handleChange = (value) => {
    setQ(value ? value.target.value : null);
  };

  const handleSearch = () => {
    setLoading(true);
    setActivePage(1);
    setResponseMessage(loadingMsg);
    setLogs([]);
    setSearch(q);
  };

  const getLeadUser = (detail) => {
    if (detail.assignedBy) {
      return detail.assignedByUser.email;
    }
    if (detail.rejectedBy) {
      return detail.rejectedByUser.email;
    }
    return '';
  };

  const getStatus = (detail) => {
    if (detail.assignedBy) {
      return 'Assigned';
    }
    if (detail.rejectedBy) {
      return 'Rejected';
    }
    return '';
  };

  const getArmsId = (detail) => {
    if (detail.armsLeads && detail.armsLeads[0]) {
      return <td>{detail.armsLeads[0].id}</td>;
    }
    if (detail.armsProjectLeads && detail.armsProjectLeads[0]) {
      return <td>{detail.armsProjectLeads[0].id}</td>;
    }
    return <td> </td>;
  };

  const getLeadDate = (detail) => {
    if (detail.armsLeads && detail.armsLeads[0]) {
      if (detail.armsLeads[0].armsActivityLogs && detail.armsLeads[0].armsActivityLogs[0]) {
        if (detail.armsLeads[0].armsActivityLogs[0].createdAt) {
          return moment(detail.armsLeads[0].armsActivityLogs[0].createdAt).format(dateFormat);
        }
      }
    }
    if (detail.armsProjectLeads && detail.armsProjectLeads[0]) {
      if (
        detail.armsProjectLeads[0].armsActivityLogs &&
        detail.armsProjectLeads[0].armsActivityLogs[0]
      ) {
        if (detail.armsProjectLeads[0].armsActivityLogs[0].createdAt) {
          return moment(detail.armsProjectLeads[0].armsActivityLogs[0].createdAt).format(
            dateFormat
          );
        }
      }
    }
    return '';
  };

  const openDate = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const resetFilters = () => {
    setOpen(false);
    setPageSize(10);
    setActivePage(1);
    setActiveStatus('property-refreshed');
    setSystemUserName('');
    setSelectedSystemUser(null);
    setSystemUserId('');
    setStartDate(null);
    setEndDate(null);
    setUserEmail('');
    getSystemUser();
  };

  const setCity = (value, name) => {
    setSystemUserName(name);
    setSystemUserId(value);
    setSelectedSystemUser(true);
    if (value === '') {
      setSelectedSystemUser(null);
    }
  };

  const onStartDateChange = (value) => {
    if (startDate !== value) {
      if (startDate) {
        setStartDate(moment(value).add(5, 'hours'));
      } else {
        setStartDate(moment(value).add(5, 'hours'));
      }
    }
  };

  const onEndDateChange = (value) => {
    if (endDate !== value) {
      if (endDate) {
        setEndDate(moment(value).add(5, 'hours'));
      } else {
        setEndDate(moment(value).add(5, 'hours'));
      }
    }
  };

  const getDescriptionsType = () => {
    if (activeStatus === 'updated-existing-description') {
      return 'Existing Descriptions';
    }
    if (activeStatus === 'updated-new-description') {
      return 'New Descriptions';
    }
    return 'Type of Descriptions';
  };

  const handleActivityLogChange = (event, newValue) => {
    if (newValue === 1 || newValue === 2) {
      setActivePurpose('');
      if (newValue === 1) {
        setActiveStatus('property-refreshed');
      }
    } else {
      setActivePurpose('dart');
    }
    getSystemUser();
    setActivityLogsValue(newValue);
    setActiveIndex(newValue);
    setActivePage(1);
    setQ('');
    setStartDate(null);
    setEndDate(null);
    setSystemUserName(null);
    setSelectedSystemUser(null);
  };

  const handleActivePurpose = (purpose) => {
    setActivePurpose(purpose);
    setActivePage(1);
  };

  const handleActiveStatus = (status) => {
    setActiveStatus(status);
    setActivePage(1);
  };

  const handleDescriptionsType = (status) => {
    setActiveStatus(status);
    setActivePage(1);
  };

  const handleDartDetailsModal = (id) => {
    setOpen(false);
    setDartDetailsModal(true);
    setDartDetailsId(id);
    setLoadingModal(true);
    axios.get(`/api/dhoondo/log-detail/${id}`).then((response) => {
      setDartDetailsData(response.data.kpiDetais);
      setLoadingModal(false);
    });
  };

  const handleLcrDetailsModal = (id) => {
    setOpen(false);
    setDartDetailsModal(true);
    setDartDetailsId(id);
    setLoadingModal(true);
    axios.get(`/api/dhoondo/log-detail/${id}`).then((response) => {
      setDartDetailsData(response.data.kpiDetais.closedWonLeads);
      setLoadingModal(false);
    });
  };

  const handleQlrDetailsModal = (id) => {
    setOpen(false);
    setDartDetailsModal(true);
    setDartDetailsId(id);
    setLoadingModal(true);
    axios.get(`/api/dhoondo/log-detail/${id}`).then((response) => {
      setDartDetailsData(response.data.kpiDetais.leadDetails);
      setLoadingModal(false);
    });
  };

  const getDetailsModal = (id) => {
    if (activePurpose === 'dart') {
      handleDartDetailsModal(id);
    }
    if (activePurpose === 'lcr') {
      handleLcrDetailsModal(id);
    }
    if (activePurpose === 'qlr') {
      handleQlrDetailsModal(id);
    }
  };

  const getDescriptionAction = (logItem) => {
    if (logItem.action === 'updated-existing-description') {
      return 'Existing Descriptions';
    }
    if (logItem.action === 'updated-new-description') {
      return 'New Descriptions';
    }
    return '';
  };

  const renderSearch = () => (
    <div className="searchBar" style={{ width: '23%' }}>
      <Tooltip
        arrow
        title={
          <span style={{ fontFamily: 'Rubik', fontSize: '11px' }}>{`Search by ${
            activeIndex === 0 ? 'Lead ID' : 'Listing ID'
          }`}</span>
        }
      >
        <input
          className="form-control"
          value={q}
          id="search"
          ref={searchInput}
          type="text"
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleSearch();
            }
          }}
          placeholder={`Search by ${activeIndex === 0 ? 'Lead ID...' : 'Listing ID...'}`}
          onChange={(value) => {
            handleChange(value);
          }}
        />
      </Tooltip>
      <button
        style={{ boxShadow: 'none' }}
        aria-label="button"
        type="button"
        className="fa fa-search commentBtn"
        disabled={!q && !search}
        onClick={() => {
          handleSearch();
        }}
      />
    </div>
  );

  const renderFilters = () => (
    <>
      <div
        className="col"
        style={{
          paddingLeft:
            activeIndex === 1 &&
            (activeStatus === 'updated-description' ||
              activeStatus === 'updated-existing-description' ||
              activeStatus === 'updated-new-description')
              ? '31%'
              : '47%',
        }}
      >
        <UncontrolledDropdown>
          <DropdownToggle
            id="selectedSystemUser"
            className={`text-start ${
              selectedSystemUser ? ' zone-select-selected ' : 'zone-select'
            }`}
            style={{
              backgroundColor: `${selectedSystemUser ? '#EBF6FF' : 'white'}`,
              height: '47px',
              boxShadow: 'none',
              paddingLeft: '10px',
              textTransform: 'capitalize',
              color: `${selectedSystemUser ? '#37a2f8' : 'black'}`,
              fontWeight: 'normal',
              border: `${selectedSystemUser ? '1px solid #EBF6FF' : '1px solid #c2cfd6'}`,
              width: '100%',
            }}
          >
            {systemUserName ? ellipsisDropdown(systemUserName) : null || 'User'}
            {selectedSystemUser ? (
              <UncontrolledTooltip
                target="selectedSystemUser"
                placement="left"
                style={{ borderRadius: '4px' }}
              >
                {' '}
                {systemUserName}{' '}
              </UncontrolledTooltip>
            ) : null}
          </DropdownToggle>
          <DropdownMenu
            style={{
              maxHeight: ' 250px',
              overflow: 'hidden',
              overflowY: 'scroll',
              marginTop: '10px',
              width: '200px',
            }}
          >
            <div className="searchBar" style={{ width: '100%' }}>
              <input
                type="text"
                className="form-control"
                onChange={(e) => getSystemUser(e)}
                value={userEmail}
                style={{ borderRadius: 4, minHeight: 40, paddingRight: 35, border: 'none' }}
                placeholder="Search..."
              />
              {!userLoading ? (
                <button
                  style={{ boxShadow: 'none', top: 5, right: 5, cursor: 'default' }}
                  aria-label="button"
                  type="button"
                  className="fa fa-search commentBtn"
                  disabled
                />
              ) : null}
              <i className={`fa fa-spinner fa-pulse loadingBtn ${userLoading ? '' : 'd-none'}`} />
            </div>
            {systemUsers &&
              systemUsers.map((item) => (
                <DropdownItem
                  className="cus-dropdown"
                  style={{ border: 'none', width: '200px' }}
                  value={item.id}
                  onClick={() => {
                    setCity(item.id, item.email);
                  }}
                >
                  {item.email ? item.email : null}
                </DropdownItem>
              ))}
            {systemUsers && systemUsers.length < 1 && !userLoading ? (
              <DropdownItem
                className="cus-dropdown disabled"
                style={{ border: 'none', width: 200 }}
              >
                No results found
              </DropdownItem>
            ) : null}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      {activeIndex === 1 &&
      (activeStatus === 'updated-description' ||
        activeStatus === 'updated-existing-description' ||
        activeStatus === 'updated-new-description') ? (
        <div className="col" style={{ paddingLeft: '0px', marginRight: '31px' }}>
          <UncontrolledDropdown>
            <DropdownToggle
              id="descriptionsType"
              className={`text-start ${
                activeStatus === 'updated-existing-description' ||
                activeStatus === 'updated-new-description'
                  ? ' zone-select-selected '
                  : 'zone-select'
              }`}
              style={{
                backgroundColor: `${
                  activeStatus === 'updated-existing-description' ||
                  activeStatus === 'updated-new-description'
                    ? '#EBF6FF'
                    : 'white'
                }`,
                height: '47px',
                boxShadow: 'none',
                paddingLeft: '10px',
                color: `${
                  activeStatus === 'updated-existing-description' ||
                  activeStatus === 'updated-new-description'
                    ? '#37a2f8'
                    : 'black'
                }`,
                fontWeight: 'normal',
                border: `${
                  activeStatus === 'updated-existing-description' ||
                  activeStatus === 'updated-new-description'
                    ? '1px solid #EBF6FF'
                    : '1px solid #c2cfd6'
                }`,
                width: '120%',
                whiteSpace: 'pre',
              }}
            >
              {getDescriptionsType()}
            </DropdownToggle>
            <DropdownMenu
              style={{
                overflow: 'hidden',
                overflowY: 'scroll',
                marginTop: '10px',
              }}
            >
              <DropdownItem
                disabled={
                  activeStatus !== 'updated-existing-description' &&
                  activeStatus !== 'updated-new-description'
                }
                className="cus-dropdown"
                style={{ border: 'none' }}
                onClick={() => handleDescriptionsType('updated-description')}
              >
                Select...
              </DropdownItem>
              <DropdownItem
                className="cus-dropdown"
                style={{ border: 'none' }}
                onClick={() => handleDescriptionsType('updated-new-description')}
              >
                New Descriptions
              </DropdownItem>
              <DropdownItem
                className="cus-dropdown"
                style={{ border: 'none' }}
                onClick={() => handleDescriptionsType('updated-existing-description')}
              >
                Existing Descriptions
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ) : null}
    </>
  );

  const renderHeader = () => (
    <div className="row space-1">
      {renderSearch()}
      {renderFilters()}
      <div className="col-auto" style={{ paddingLeft: '0px' }}>
        <button
          type="button"
          className={`btn ${startDate || endDate ? 'selected' : 'btn-outline'}`}
          style={{
            border: `${startDate || endDate ? '1px solid #EBF6FF' : '1px solid #c2cfd6'}`,
            height: '47px',
            fontWeight: 'normal',
            width: '110px',
            boxShadow: 'none',
          }}
          onClick={() => {
            openDate();
          }}
        >
          <CalendarTodayOutlinedIcon
            style={{
              position: 'relative',
              right: '8px',
              fontWeight: 'normal',
              fontSize: '20px',
            }}
          />
          Date
          <KeyboardArrowDownIcon
            style={{
              position: 'relative',
              left: '12px',
              fontWeight: 'normal',
              fontSize: '18px',
            }}
          />
        </button>
      </div>
      <span
        style={{
          borderLeft: '1px solid #DFE0EB',
          height: '47px',
          width: 0,
          paddingLeft: '0px',
        }}
      >
        {' '}
      </span>{' '}
      <div className="col-auto" style={{ paddingLeft: '0px' }}>
        <button
          type="button"
          className="btn btn-default "
          style={{
            color: 'gray',
            boxShadow: 'none',
            fontWeight: 'normal',
            lineHeight: 2.5,
          }}
          onClick={() => resetFilters()}
        >
          Reset
        </button>
      </div>
    </div>
  );

  const renderLoading = () => <Loading loading={loading} />;

  const renderRiderTable = () => {
    if (!total && selectedFilter) {
      return (
        <div className="text-center" style={{ marginTop: '50px', marginBottom: '50px' }}>
          <img
            style={{
              width: '456px',
              height: '350px',
              paddingTop: '50px',
              paddingBottom: '15px',
            }}
            className="mx-auto d-block"
            alt=""
            src="/img/no_data.png"
          />
          <span style={{ fontWeight: 'bold' }}> No Data was found</span>
          <br />
          <span style={{ color: '#9E9E9E' }}>
            Try searching something else <br />
            or visit again later
          </span>
        </div>
      );
    }
    return (
      <>
        <div className="row " style={{ marginTop: '14px', marginBottom: 10 }}>
          <div
            className="col-auto ms-auto text-end"
            id="pageSize"
            style={{ color: '#9E9E9E', marginTop: '8px' }}
          >
            {total ? (
              <Tooltip
                arrow
                title={
                  <span style={{ fontFamily: 'Rubik', fontSize: '11px' }}>
                    Showing {(activePage - 1) * pageSize + 1} -
                    {activePage * pageSize >= total ? total : activePage * pageSize} of &nbsp;
                    {total}
                  </span>
                }
              >
                <div className="text-end row-sm-4">
                  {(activePage - 1) * pageSize + 1} -{' '}
                  {activePage * pageSize >= total ? total : activePage * pageSize} of {total}
                </div>
              </Tooltip>
            ) : null}
          </div>
          <div className="col-auto">
            <Pagination
              shape="rounded"
              count={pages}
              page={activePage}
              defaultPage={1}
              renderItem={(item) => (
                <PaginationItem
                  sx={{
                    fontFamily: 'Rubik',
                    '&.Mui-selected': {
                      backgroundColor: '#a3d0f5b0',
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(55, 162, 248, 0.25)',
                    },
                  }}
                  {...item}
                />
              )}
              onChange={handlePageSelect}
            />
          </div>
          <div className="col-auto ">
            <UncontrolledDropdown>
              <DropdownToggle
                id="page"
                className="form-select"
                style={{
                  backgroundColor: 'white',
                  paddingRight: '60px',
                  marginBottom: '5px',
                  boxShadow: 'none',
                  color: 'black',
                  fontWeight: 'normal',
                  width: '60px',
                  border: '1px solid #c2cfd6',
                }}
              >
                {pageSize}
              </DropdownToggle>
              <DropdownMenu style={{ minWidth: '10px', marginTop: '2px' }}>
                {PAGE_SIZE_OPTIONS.map((value) => (
                  <DropdownItem
                    className="cus-dropdown"
                    style={{ border: 'none' }}
                    value={value}
                    key={value}
                    onClick={(event) => handlePageSize(event)}
                  >
                    {value}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        <div>
          <div className="table-responsive" style={{ borderRadius: '8px' }}>
            <table className="table table-hover" style={{ width: '100%' }}>
              <thead className="newThead">
                <tr>
                  <th style={{ paddingLeft: '30px' }}>Log ID</th>
                  <th>Rider Email</th>
                  <th>Listing ID</th>
                  <th>Status</th>
                  <th>Substatus</th>
                  <th style={{ width: '200px' }}>Logged at</th>
                </tr>
              </thead>
              <tbody>
                {logs && logs.length >= 1 ? (
                  logs.map((logItem) => (
                    <tr
                      className="row-hover"
                      key={logItem.id}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: `${logItem.selected ? '#EBF6FF' : ''}`,
                      }}
                    >
                      <td style={{ width: '200px', paddingLeft: '30px' }}>{logItem.id}</td>
                      <td style={{ width: '300px' }}>
                        {logItem.rider ? logItem.rider.email : ''}{' '}
                      </td>
                      <td>{logItem.propertyId}</td>
                      <td style={{ whiteSpace: 'pre' }}>
                        {logItem.substatus
                          ? setUpperCase(logItem.substatus.replace(/-/g, ' '))
                          : ''}
                      </td>
                      <td style={{ whiteSpace: 'pre' }}>
                        {logItem.reason ? setUpperCase(logItem.reason.replace(/_/g, ' ')) : ''}
                      </td>
                      <td style={{ width: '200px' }}>
                        {logItem.createdAt
                          ? moment(logItem.createdAt).format('MMM DD, HH:mm')
                          : null}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="20" style={{ paddingLeft: '500px' }}>
                      {responseMessage}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  const renderCataloguerTable = () => {
    if (!total && selectedFilter) {
      return (
        <div className="text-center" style={{ marginTop: '50px', marginBottom: '50px' }}>
          <img
            style={{
              width: '456px',
              height: '350px',
              paddingTop: '50px',
              paddingBottom: '15px',
            }}
            className="mx-auto d-block"
            alt=""
            src="/img/no_data.png"
          />
          <span style={{ fontWeight: 'bold' }}> No Data was found</span>
          <br />
          <span style={{ color: '#9E9E9E' }}>
            Try searching something else <br />
            or visit again later
          </span>
        </div>
      );
    }
    return (
      <>
        <div className="row " style={{ marginTop: '14px', marginBottom: 10 }}>
          <div className="col-auto text-start">
            <button
              type="button"
              className={`btn btn-outline-info btn-custom-reporting ${
                activeStatus === 'property-refreshed' ? 'active' : ''
              }`}
              style={{
                borderRadius: 77,
                width: 105,
                marginRight: 10,
                boxShadow: 'none',
                color: activeStatus === 'property-refreshed' ? '#37a2f8' : '#000000',
                border: activeStatus !== 'property-refreshed' ? '1px solid #9e9e9e' : '',
                fontWeight: '400',
                fontSize: '13px',
              }}
              onClick={() => handleActiveStatus('property-refreshed')}
            >
              Refreshed
            </button>
            <button
              type="button"
              className={`btn btn-outline-info btn-custom-reporting ${
                activeStatus === 'property-closed' ? 'active' : ''
              }`}
              style={{
                borderRadius: 77,
                width: 105,
                marginRight: 10,
                boxShadow: 'none',
                color: activeStatus === 'property-closed' ? '#37a2f8' : '#000000',
                border: activeStatus !== 'property-closed' ? '1px solid #9e9e9e' : '',
                fontWeight: '400',
                fontSize: '13px',
              }}
              onClick={() => handleActiveStatus('property-closed')}
            >
              Closed
            </button>
            <button
              type="button"
              className={`btn btn-outline-info btn-custom-reporting ${
                activeStatus === 'updated-description' ||
                activeStatus === 'updated-existing-description' ||
                activeStatus === 'updated-new-description'
                  ? 'active'
                  : ''
              }`}
              style={{
                borderRadius: 77,
                width: 105,
                marginRight: 10,
                boxShadow: 'none',
                color:
                  activeStatus === 'updated-description' ||
                  activeStatus === 'updated-existing-description' ||
                  activeStatus === 'updated-new-description'
                    ? '#37a2f8'
                    : '#000000',
                border:
                  activeStatus !== 'updated-description' &&
                  activeStatus !== 'updated-existing-description' &&
                  activeStatus !== 'updated-new-description'
                    ? '1px solid #9e9e9e'
                    : '',
                fontWeight: '400',
                fontSize: '13px',
              }}
              onClick={() => handleActiveStatus('updated-description')}
            >
              Descriptions
            </button>
          </div>
          <div
            className="col-auto ms-auto text-end"
            id="pageSize"
            style={{ color: '#9E9E9E', marginTop: '8px' }}
          >
            {total ? (
              <Tooltip
                arrow
                title={
                  <span style={{ fontFamily: 'Rubik', fontSize: '11px' }}>
                    Showing {(activePage - 1) * pageSize + 1} -
                    {activePage * pageSize >= total ? total : activePage * pageSize} of &nbsp;
                    {total}
                  </span>
                }
              >
                <div className="text-end row-sm-4">
                  {(activePage - 1) * pageSize + 1} -{' '}
                  {activePage * pageSize >= total ? total : activePage * pageSize} of {total}
                </div>
              </Tooltip>
            ) : null}
          </div>
          <div className="col-auto">
            <Pagination
              shape="rounded"
              count={pages}
              page={activePage}
              defaultPage={1}
              renderItem={(item) => (
                <PaginationItem
                  sx={{
                    fontFamily: 'Rubik',
                    '&.Mui-selected': {
                      backgroundColor: '#a3d0f5b0',
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(55, 162, 248, 0.25)',
                    },
                  }}
                  {...item}
                />
              )}
              onChange={handlePageSelect}
            />
          </div>
          <div className="col-auto ">
            <UncontrolledDropdown>
              <DropdownToggle
                id="page"
                className="form-select"
                style={{
                  backgroundColor: 'white',
                  paddingRight: '60px',
                  marginBottom: '5px',
                  boxShadow: 'none',
                  color: 'black',
                  fontWeight: 'normal',
                  width: '60px',
                  border: '1px solid #c2cfd6',
                }}
              >
                {pageSize}
              </DropdownToggle>
              <DropdownMenu style={{ minWidth: '10px', marginTop: '2px' }}>
                {PAGE_SIZE_OPTIONS.map((value) => (
                  <DropdownItem
                    className="cus-dropdown"
                    style={{ border: 'none' }}
                    value={value}
                    key={value}
                    onClick={(event) => handlePageSize(event)}
                  >
                    {value}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        <div>
          <div className="table-responsive" style={{ borderRadius: '8px' }}>
            <table className="table table-hover" style={{ width: '100%' }}>
              <thead className="newThead">
                <tr>
                  <th style={{ paddingLeft: '30px' }}>Log ID</th>
                  <th>System User Email</th>
                  <th>Listing ID</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th style={{ width: '200px' }}>Logged at</th>
                </tr>
              </thead>
              <tbody>
                {logs && logs.length >= 1 ? (
                  logs.map((logItem) => (
                    <tr
                      className="row-hover"
                      key={logItem.id}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: `${logItem.selected ? '#EBF6FF' : ''}`,
                      }}
                    >
                      <td style={{ width: '200px', paddingLeft: '30px' }}>{logItem.id}</td>
                      <td style={{ width: '300px' }}>
                        {logItem.system_user ? logItem.system_user.email : ''}{' '}
                      </td>
                      <td>{logItem.propertyId}</td>
                      <td style={{ whiteSpace: 'pre' }}>{getDescriptionAction(logItem)}</td>
                      <td style={{ whiteSpace: 'pre' }}>{logItem.actionType}</td>
                      <td style={{ width: '200px' }}>
                        {logItem.createdAt
                          ? moment(logItem.createdAt).format('MMM DD, HH:mm')
                          : null}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="20" style={{ paddingLeft: '500px' }}>
                      {responseMessage}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  const renderAiraTable = () => {
    if (!total && selectedFilter) {
      return (
        <div className="text-center" style={{ marginTop: '50px', marginBottom: '50px' }}>
          <img
            style={{
              width: '456px',
              height: '350px',
              paddingTop: '50px',
              paddingBottom: '15px',
            }}
            className="mx-auto d-block"
            alt=""
            src="/img/no_data.png"
          />
          <span style={{ fontWeight: 'bold' }}> No Data was found</span>
          <br />
          <span style={{ color: '#9E9E9E' }}>
            Try searching something else <br />
            or visit again later
          </span>
        </div>
      );
    }
    return (
      <>
        <div className="row " style={{ marginTop: '14px', marginBottom: 10 }}>
          <div className="col-auto text-start">
            <button
              type="button"
              className={`btn btn-outline-info btn-custom-reporting ${
                activePurpose === 'dart' ? 'active' : ''
              }`}
              style={{
                borderRadius: 77,
                width: 86,
                marginRight: 10,
                boxShadow: 'none',
                color: activePurpose === 'dart' ? '#37a2f8' : '#000000',
                border: activePurpose !== 'dart' ? '1px solid #9e9e9e' : '',
                fontWeight: '400',
                fontSize: '13px',
              }}
              onClick={() => handleActivePurpose('dart')}
            >
              DART
            </button>
            <button
              type="button"
              className={`btn btn-outline-info btn-custom-reporting ${
                activePurpose === 'lcr' ? 'active' : ''
              }`}
              style={{
                borderRadius: 77,
                width: 86,
                marginRight: 10,
                boxShadow: 'none',
                color: activePurpose === 'lcr' ? '#37a2f8' : '#000000',
                border: activePurpose !== 'lcr' ? '1px solid #9e9e9e' : '',
                fontWeight: '400',
                fontSize: '13px',
              }}
              onClick={() => handleActivePurpose('lcr')}
            >
              LCR
            </button>
            <button
              type="button"
              className={`btn btn-outline-info btn-custom-reporting ${
                activePurpose === 'qlr' ? 'active' : ''
              }`}
              style={{
                borderRadius: 77,
                width: 86,
                marginRight: 10,
                boxShadow: 'none',
                color: activePurpose === 'qlr' ? '#37a2f8' : '#000000',
                border: activePurpose !== 'qlr' ? '1px solid #9e9e9e' : '',
                fontWeight: '400',
                fontSize: '13px',
              }}
              onClick={() => handleActivePurpose('qlr')}
            >
              QLR
            </button>
            <button
              type="button"
              className={`btn btn-outline-info btn-custom-reporting ${
                activePurpose === 'airacalls' ? 'active' : ''
              }`}
              style={{
                borderRadius: 77,
                width: 86,
                boxShadow: 'none',
                color: activePurpose === 'airacalls' ? '#37a2f8' : '#000000',
                border: activePurpose !== 'airacalls' ? '1px solid #9e9e9e' : '',
                fontWeight: '400',
                fontSize: '13px',
              }}
              onClick={() => handleActivePurpose('airacalls')}
            >
              Calls
            </button>
          </div>
          <div
            className="col-auto ms-auto text-end"
            id="pageSize"
            style={{ color: '#9E9E9E', marginTop: '8px' }}
          >
            {total ? (
              <Tooltip
                arrow
                title={
                  <span style={{ fontFamily: 'Rubik', fontSize: '11px' }}>
                    Showing {(activePage - 1) * pageSize + 1} -
                    {activePage * pageSize >= total ? total : activePage * pageSize} of &nbsp;
                    {total}
                  </span>
                }
              >
                <div className="text-end row-sm-4">
                  {(activePage - 1) * pageSize + 1} -{' '}
                  {activePage * pageSize >= total ? total : activePage * pageSize} of {total}
                </div>
              </Tooltip>
            ) : null}
          </div>
          <div className="col-auto">
            <Pagination
              shape="rounded"
              count={pages}
              page={activePage}
              defaultPage={1}
              renderItem={(item) => (
                <PaginationItem
                  sx={{
                    fontFamily: 'Rubik',
                    '&.Mui-selected': {
                      backgroundColor: '#a3d0f5b0',
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(55, 162, 248, 0.25)',
                    },
                  }}
                  {...item}
                />
              )}
              onChange={handlePageSelect}
            />
          </div>
          <div className="col-auto ">
            <UncontrolledDropdown>
              <DropdownToggle
                id="page"
                className="form-select"
                style={{
                  backgroundColor: 'white',
                  paddingRight: '60px',
                  marginBottom: '5px',
                  boxShadow: 'none',
                  color: 'black',
                  fontWeight: 'normal',
                  width: '60px',
                  border: '1px solid #c2cfd6',
                }}
              >
                {pageSize}
              </DropdownToggle>
              <DropdownMenu style={{ minWidth: '10px', marginTop: '2px' }}>
                {PAGE_SIZE_OPTIONS.map((value) => (
                  <DropdownItem
                    className="cus-dropdown"
                    style={{ border: 'none' }}
                    value={value}
                    key={value}
                    onClick={(event) => handlePageSize(event)}
                  >
                    {value}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        <div>
          <div className="table-responsive" style={{ borderRadius: '8px' }}>
            <table className="table table-hover" style={{ width: '100%' }}>
              <thead className="newThead">
                <tr>
                  <th style={{ paddingLeft: '30px' }}>Log ID</th>
                  {activePurpose === 'qlr' ? <th style={{ width: 150 }}>Actual Leads</th> : null}
                  {activePurpose === 'qlr' ? <th style={{ width: 150 }}>Rejected Leads</th> : null}
                  {activePurpose === 'lcr' ? (
                    <th style={{ width: '200px' }}>Closed Won Leads</th>
                  ) : null}
                  {activePurpose === 'lcr' ? (
                    <th style={{ width: '200px' }}>Closed Won Project Leads</th>
                  ) : null}
                  {activePurpose === 'dart' || activePurpose === 'lcr' ? (
                    <th
                      style={{
                        textTransform: 'capitalize',
                        width: '200px',
                        paddingLeft: '8px',
                      }}
                    >
                      Total Leads
                    </th>
                  ) : null}
                  <th>System User Email</th>
                  {activePurpose === 'airacalls' ? <th>Lead ID</th> : null}
                  {activePurpose === 'airacalls' ? <th>Action Performed</th> : null}
                  {activePurpose === 'dart' ? <th>DART</th> : null}
                  {activePurpose === 'lcr' ? <th>LCR</th> : null}
                  {activePurpose === 'qlr' ? <th>QLR</th> : null}
                  <th style={{ width: '200px' }}>Logged at</th>
                </tr>
              </thead>
              <tbody>
                {logs && logs.length >= 1 ? (
                  logs.map((logItem) => {
                    const dartStats = parseFloat(logItem.value, 10);
                    const dartHour = Math.floor(dartStats / 60 / 60);
                    const dartMinute = Math.floor(dartStats / 60) - dartHour * 60;
                    const dartSecond = parseInt(dartStats, 10) % 60;
                    const dartFormatted = `${dartHour.toString().padStart(2, '0')}:${dartMinute
                      .toString()
                      .padStart(2, '0')}:${dartSecond.toString().padStart(2, '0')}`;
                    return (
                      <tr
                        className="row-hover"
                        key={logItem.id}
                        style={{
                          cursor:
                            activeIndex === 0 &&
                            (activePurpose === 'dart' ||
                              activePurpose === 'lcr' ||
                              activePurpose === 'qlr')
                              ? 'pointer'
                              : 'default',
                          backgroundColor: `${logItem.selected ? '#EBF6FF' : ''}`,
                        }}
                        onClick={() => getDetailsModal(logItem.id)}
                      >
                        <td style={{ width: '200px', paddingLeft: '30px' }}>{logItem.id}</td>
                        {activePurpose === 'qlr' ? (
                          <td style={{ width: 150 }}>{logItem.actualLeadsCount}</td>
                        ) : null}
                        {activePurpose === 'qlr' ? (
                          <td style={{ width: 150 }}>{logItem.rejectedLeadsCount}</td>
                        ) : null}
                        {activePurpose === 'lcr' ? (
                          <td style={{ width: 200 }}>{logItem.closedWonLeadsCount}</td>
                        ) : null}
                        {activePurpose === 'lcr' ? (
                          <td style={{ width: 200 }}>{logItem.totalClosedWonProjectLeads}</td>
                        ) : null}
                        {activePurpose === 'dart' || activePurpose === 'lcr' ? (
                          <td style={{ width: '220px', paddingLeft: '8px' }}>
                            {activePurpose === 'lcr' ? logItem.totalLeadsCount : logItem.leadsCount}
                          </td>
                        ) : null}
                        <td style={{ width: '300px' }}>
                          {logItem.system_user ? logItem.system_user.email : ''}{' '}
                        </td>
                        {activePurpose === 'airacalls' ? <td>{logItem.leadsId}</td> : null}
                        {activePurpose === 'airacalls' ? <td>{logItem.action}</td> : null}
                        {activePurpose === 'dart' ? (
                          <td style={{ width: '200px' }}>{dartFormatted}</td>
                        ) : null}
                        {activePurpose === 'lcr' || activePurpose === 'qlr' ? (
                          <td style={{ whiteSpace: 'pre' }}>{`${logItem.value} %`}</td>
                        ) : null}
                        <td style={{ width: '200px' }}>
                          {logItem.createdAt
                            ? moment(logItem.createdAt).format('MMM DD, HH:mm')
                            : null}
                        </td>
                        <td>
                          {logItem.created_at
                            ? moment(logItem.created_at).format('MMM DD, HH:mm')
                            : null}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="20" style={{ paddingLeft: '500px' }}>
                      {responseMessage}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  const renderDartDetailsModal = () => (
    <Modal
      contentClassName="activity-modal"
      isOpen={dartDetailsModal}
      toggle={() => {
        setDartDetailsData(null);
        setDartDetailsModal(false);
      }}
      size="lg"
    >
      <ModalHeader
        style={{ backgroundColor: 'rgba(55, 162, 248, 0.15)', borderBottom: 'none' }}
        toggle={() => {
          setDartDetailsModal(false);
          setDartDetailsData(null);
        }}
      >
        <div style={{ fontWeight: 'bold' }}>Log ID: {dartDetailsId}</div>
      </ModalHeader>
      <ModalBody style={{ overflowY: 'auto' }}>
        <div style={{ maxHeight: 285 }}>
          <table className="table">
            <thead className="newThead">
              <tr>
                <th scope="col">Lead ID</th>
                {activePurpose === 'dart' ? <th scope="col">Received at</th> : null}
                {activePurpose === 'dart' ? <th scope="col">Responded at</th> : null}
                {activePurpose === 'lcr' ? <th scope="col">ARMS ID</th> : null}
                {activePurpose === 'lcr' ? <th scope="col">Agent ID</th> : null}
                {activePurpose === 'lcr' ? <th scope="col">Agent Name</th> : null}
                {activePurpose === 'lcr' ? <th scope="col">Status Changed at</th> : null}
                {activePurpose === 'qlr' ? <th scope="col">Assigned/Rejected By</th> : null}
                {activePurpose === 'qlr' ? <th scope="col">Status</th> : null}
                {activePurpose === 'qlr' ? <th scope="col">Status Changed at</th> : null}
              </tr>
            </thead>
            <tbody>
              {dartDetailsData && dartDetailsData.length > 0 ? (
                dartDetailsData.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    {activePurpose === 'dart' ? (
                      <td>
                        {item.businessCreatedAt
                          ? moment(item.businessCreatedAt).format('HH:mm, MMM DD')
                          : ''}
                      </td>
                    ) : null}
                    {activePurpose === 'dart' ? (
                      <td>
                        {item.first_edited_at
                          ? moment(item.first_edited_at).format('HH:mm, MMM DD')
                          : ''}
                      </td>
                    ) : null}
                    {activePurpose === 'lcr' ? getArmsId(item) : null}
                    {activePurpose === 'lcr' ? (
                      <td>{item.armsuser ? item.armsuser.id : ''}</td>
                    ) : null}
                    {activePurpose === 'lcr' ? (
                      <td>
                        {item.armsuser
                          ? `${item.armsuser.firstName} ${item.armsuser.lastName}`
                          : ''}
                      </td>
                    ) : null}
                    {activePurpose === 'lcr' ? <td>{getLeadDate(item)}</td> : null}
                    {activePurpose === 'qlr' ? <td>{getLeadUser(item)}</td> : null}
                    {activePurpose === 'qlr' ? <td>{getStatus(item)}</td> : null}
                    {activePurpose === 'qlr' ? (
                      <td>{item.status_date ? moment(item.status_date).format(dateFormat) : ''}</td>
                    ) : null}
                  </tr>
                ))
              ) : (
                <tr>
                  {loadingModal ? (
                    <td colSpan="12" className="text-center">
                      <div className="col-sm-12 text-center space-1">
                        <i className="fa fa-spinner fa-pulse" style={{ fontSize: '50px' }} />
                      </div>
                    </td>
                  ) : null}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </ModalBody>
    </Modal>
  );

  return (
    <div className="animated fadeIn">
      {window.removeEventListener('beforeunload', handleUnload)}
      {renderDartDetailsModal()}
      <div className="col-12" style={{ marginTop: '15px' }}>
        {renderHeader()}
        <div className="col ms-auto" style={{ width: 'fit-content' }}>
          {open ? (
            <Button
              className="mx-auto d-block"
              style={{
                backgroundColor: 'white',
                border: 'none',
                borderRadius: '4px',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  components={{
                    OpenPickerIcon: CalendarTodayOutlinedIcon,
                  }}
                  componentsProps={{
                    actionBar: startDate ? { actions: ['clear'] } : '',
                  }}
                  maxDate={maxDate}
                  label="Start Date"
                  inputFormat="dd/MM/yyyy"
                  value={startDate}
                  onChange={(dateStart) => onStartDateChange(dateStart)}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{ style: { fontFamily: 'Rubik', color: '#9e9e9e' } }}
                      sx={{
                        svg: { color: '#9e9e9e' },
                        '&:hover': {
                          svg: { color: 'rgb(55, 162, 248)' },
                          Button: {
                            backgroundColor: 'white',
                          },
                        },
                      }}
                      {...params}
                    />
                  )}
                />{' '}
                &nbsp;
                <DatePicker
                  components={{
                    OpenPickerIcon: CalendarTodayOutlinedIcon,
                  }}
                  componentsProps={{
                    actionBar: endDate ? { actions: ['clear'] } : '',
                  }}
                  maxDate={maxDate}
                  label="End Date"
                  inputFormat="dd/MM/yyyy"
                  value={endDate}
                  onChange={(dateEnd) => onEndDateChange(dateEnd)}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{ style: { fontFamily: 'Rubik', color: '#9e9e9e' } }}
                      sx={{
                        svg: { color: '#9e9e9e' },
                        '&:hover': {
                          svg: { color: 'rgb(55, 162, 248)' },
                          Button: {
                            backgroundColor: 'white',
                          },
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Button>
          ) : null}
        </div>
        <div className="row space-1">
          <div className="col-12">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={activityLogsValue}
                onChange={handleActivityLogChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#37A2F8',
                  },
                }}
              >
                <Tab
                  sx={{
                    textTransform: 'capitalize',
                    fontFamily: 'Rubik',
                    fontSize: '15.6px',
                    fontWeight: 'bold',
                  }}
                  label="AIRA Logs"
                  style={{ color: activeIndex === 0 ? '#37A2F8' : '#9E9E9E' }}
                />
                <Tab
                  sx={{
                    textTransform: 'capitalize',
                    fontFamily: 'Rubik',
                    fontSize: '15.6px',
                    fontWeight: 'bold',
                  }}
                  label="Cataloguer Logs"
                  style={{ color: activeIndex === 1 ? '#37A2F8' : '#9E9E9E' }}
                />
                <Tab
                  sx={{
                    textTransform: 'capitalize',
                    fontFamily: 'Rubik',
                    fontSize: '15.6px',
                    fontWeight: 'bold',
                  }}
                  label="FF Logs"
                  style={{ color: activeIndex === 2 ? '#37A2F8' : '#9E9E9E' }}
                />
              </Tabs>
            </Box>
          </div>
        </div>
        {renderLoading()}
        {activeIndex === 0 ? renderAiraTable() : null}
        {activeIndex === 1 ? renderCataloguerTable() : null}
        {activeIndex === 2 ? renderRiderTable() : null}
      </div>
    </div>
  );
}

AiraLogs.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});
export default connect(mapStateToProps)(AiraLogs);
