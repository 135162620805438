/** @format */

import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined';
import PropTypes from 'prop-types';
import { Badge, Nav, NavItem, NavLink as RsNavLink, NavbarBrand } from 'reactstrap';
import classNames from 'classnames';
import nav from '../static/_nav';
import blankUser from '../public/img/blank-user.png';
import appImages from '../assets';
import SidebarFooter from './SidebarFooter';
import SidebarForm from './SidebarForm';
import SidebarHeader from './SidebarHeader';

import HasRole from '../hoc/HasRole';
import { setNavItemsDepartmentRoles, ellipsis } from '../common/actions';
import { generateImageUrl } from '../utils/helper';

const pushNotifications = 'Push Notifications';

const style = {
  img: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    margin: '0 10px',
    verticalAlign: 'top',
  },
  name: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12.1296px',
    lineHeight: '21px',
  },
  email: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '9.96777px',
    lineHeight: '14px',
    paddingLeft: '30px',
  },
  logoWrapper: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
  },
  svg: {
    width: '75%',
    fill: 'white',
    height: '36px',
    marginTop: '10px',
    marginBottom: '0px',
    paddingBottom: '0px',
  },
};
class Sidebar extends Component {
  static handleClick(e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  }

  static activeRoute(route) {
    if (route.children) {
      route.children.map((child) => child);
    }
    return 'nav-item nav-dropdown ';
  }

  static hideMobile() {
    if (document.body.classList.contains('sidebar-mobile-show')) {
      document.body.classList.toggle('sidebar-mobile-show');
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      premiumCount: null,
      mopCount: null,
    };
  }

  render() {
    const { props } = this;
    const { premiumCount, mopCount } = this.state;
    let navList = [];

    // badge addon to NavItem
    const badge = (badgeItem) => {
      if (badgeItem) {
        const classes = classNames(badgeItem.class);
        return (
          <Badge className={classes} color={badgeItem.variant}>
            {badgeItem.text}
          </Badge>
        );
      }
      return null;
    };

    // simple wrapper for nav-title item
    const wrapper = (item) =>
      item.wrapper && item.wrapper.element
        ? React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)
        : item.name;

    // nav list section title
    const title = (theTitle, key) => {
      const classes = classNames('nav-title', theTitle.class);
      return (
        <li key={key} className={classes}>
          {wrapper(theTitle)}{' '}
        </li>
      );
    };

    // nav list divider
    const divider = (theDivider, key) => {
      const classes = classNames('divider', theDivider.class);
      return <li key={key} className={classes} />;
    };

    const isExternal = (url) => {
      const link = url ? url.substring(0, 4) : '';
      return link === 'http';
    };

    const checkAdmin = (item, key, classes, url) => {
      const { user } = this.props;
      if ((item.name === pushNotifications && user.superadmin) || item.name !== pushNotifications) {
        return (
          <NavLink
            to={url}
            className={classes.link}
            activeClassName="active"
            onClick={(e) => {
              Sidebar.hideMobile(e);
            }}
            exact={url === '/'}
          >
            {item.icon === 'custom-icon' ? (
              <PolicyOutlinedIcon
                style={{
                  fontSize: '18px',
                  color: '#536C79',
                  marginRight: '9px',
                  marginLeft: '10px',
                }}
              />
            ) : null}
            {item.icon && item.icon !== 'custom-icon' ? <i className={classes.icon} /> : null}
            {item.name === 'Advanced Projects' ? (
              <div style={{ display: 'inline-block' }}>
                Projects{' '}
                <div className="badge bg-danger" style={{ marginLeft: 10, borderRadius: 5 }}>
                  ADVANCED
                </div>
              </div>
            ) : (
              item.name
            )}
            {badge(item.badge)}
            {item.name === 'Media Requests' && premiumCount > 0 && (
              <span className="badge bg-danger ms-2">{premiumCount}</span>
            )}
            {item.name === 'Properties Media' && mopCount > 0 && (
              <span className="badge bg-danger ms-2">{mopCount}</span>
            )}
          </NavLink>
        );
      }
      return null;
    };

    const checkOtherAdmin = (item, key, classes, url) => {
      const { user } = this.props;
      if ((item.name === pushNotifications && user.superadmin) || item.name !== pushNotifications) {
        return (
          <HasRole departmentRoles={item.departmentRoles}>
            <NavLink
              to={url}
              className={classes.link}
              activeClassName="active"
              onClick={(e) => {
                Sidebar.hideMobile(e);
              }}
              exact={url === '/'}
            >
              {item.icon ? <i className={classes.icon} /> : null}
              {item.name === 'Advanced Projects' ? (
                <div style={{ display: 'inline-block' }}>
                  Projects{' '}
                  <div className="badge bg-danger" style={{ marginLeft: 10, borderRadius: 5 }}>
                    ADVANCED
                  </div>
                </div>
              ) : (
                item.name
              )}
              {badge(item.badge)}
            </NavLink>
          </HasRole>
        );
      }
      return null;
    };

    // nav link
    const navLink = (item, key, classes) => {
      const url = item.url ? item.url : '';
      const hasRoleNavLink = item.departmentRoles
        ? checkOtherAdmin(item, key, classes, url)
        : checkAdmin(item, key, classes, url);
      return (
        <NavItem key={key} className={classes.item}>
          {isExternal(url) ? (
            <RsNavLink
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
              active
            >
              {item.icon ? <i className={classes.icon} /> : null}
              {item.name}
              {badge(item.badge)}
            </RsNavLink>
          ) : (
            hasRoleNavLink
          )}
        </NavItem>
      );
    };

    // nav label with nav link
    const navLabel = (item, key) => {
      const classes = {
        item: classNames('hidden-cn', item.class),
        link: classNames('nav-label', item.class ? item.class : ''),
        icon: classNames(
          !item.icon ? 'fa fa-circle' : item.icon,
          item.label.variant ? `text-${item.label.variant}` : '',
          item.label.class ? item.label.class : ''
        ),
      };
      return navLink(item, key, classes);
    };

    // nav item with nav link
    const navItem = (item, key) => {
      const classes = {
        item: classNames(item.class),
        link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
        icon: classNames(item.icon),
      };
      return navLink(item, key, classes);
    };

    // nav dropdown
    const navDropdown = (item, key) =>
      item.departmentRoles ? (
        <HasRole departmentRoles={item.departmentRoles} key={key}>
          <li className={Sidebar.activeRoute(item, props)} data-index={key}>
            <span
              role="presentation"
              className="nav-link nav-dropdown-toggle cursorPointer"
              onClick={Sidebar.handleClick}
            >
              <i className={item.icon} />
              {item.name}
            </span>
            <ul className="nav-dropdown-items">{navList(item.children)}</ul>
          </li>
        </HasRole>
      ) : (
        <li key={key} className={Sidebar.activeRoute(item, props)} data-index={key}>
          <span
            role="presentation"
            className="nav-link nav-dropdown-toggle cursorPointer"
            onClick={Sidebar.handleClick}
          >
            <i className={item.icon} />
            {item.name}
          </span>
          <ul className="nav-dropdown-items">{navList(item.children)}</ul>
        </li>
      );

    // nav type
    const navType = (item, idx) => {
      let navTypeReturn = navItem(item, idx);

      if (item.title) {
        navTypeReturn = title(item, idx);
      } else if (item.divider) {
        navTypeReturn = divider(item, idx);
      } else if (item.label) {
        navTypeReturn = navLabel(item, idx);
      } else if (item.children) {
        navTypeReturn = navDropdown(item, idx);
      }
      return navTypeReturn;
    };

    // nav list
    navList = (items) => items.map((item, index) => navType(item, index));

    // sidebar-nav root
    const { currentUserFullName, currentUserEmail } = props;
    let { currentUserImage } = props;
    if (currentUserImage) {
      currentUserImage = currentUserImage.replace('http://', 'https://');
    }
    return (
      <div className="sidebar" id="sidebar">
        <SidebarHeader />
        <SidebarForm />
        <NavbarBrand
          className="responsiveGraanaText"
          tag={Link}
          to={window.localStorage.getItem('isHome') ? window.localStorage.getItem('isHome') : '/'}
        >
          <div style={style.logoWrapper} className="svg-logo">
            <svg style={style.svg}>
              <use xlinkHref="/img/logo.svg#logo" />
            </svg>
          </div>
        </NavbarBrand>
        <nav className="sidebar-nav" style={{ position: 'relative' }}>
          <Nav>
            <div className="clearfix topLine " />
            <div className="responsiveProfile">
              {/*<img style={style.img} src={`${currentUserImage || blankUser}`} alt="user" />*/}
              <img style={style.img} src={`${generateImageUrl(currentUserImage || blankUser)}`} alt="user" />
              <div style={{ display: 'inline-block' }}>
                <span style={style.name}> {currentUserFullName}</span>
                <span style={style.email}> {currentUserEmail}</span>
              </div>
            </div>
            <div className="clearfix bottomLine" />
            {navList(
              props.user.superadmin ? nav.items : setNavItemsDepartmentRoles(nav.items, props.user)
            )}
            <div className="clearfix graanaLogo" />
            <div
              style={{
                width: 162,
                height: 129,
                zIndex: 0,
                background: '#37394F',
                position: 'absolute',
                bottom: '-85px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url(${appImages.graanaLogo})`,
              }}
            />
          </Nav>
        </nav>

        <SidebarFooter />
      </div>
    );
  }
}

Sidebar.propTypes = {
  currentUserImage: PropTypes.string,
  currentUserFullName: PropTypes.string,
  currentUserEmail: PropTypes.string,
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => {
  const user = state.user.user || {};
  return {
    user,
    currentUserImage: user.profile_image,
    currentUserFullName: ellipsis(`${user.first_name} ${user.last_name}`),
    currentUserEmail: user.email ? ellipsis(user.email) : null,
  };
};

Sidebar.defaultProps = {
  currentUserImage: '',
  currentUserFullName: '',
  currentUserEmail: '',
};

export default connect(mapStateToProps)(Sidebar);
