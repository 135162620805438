/** @format */

// User actions

export const SET_USER_FROM_TOKEN = 'SET_USER_FROM_TOKEN';

export const LOGOUT_USER = 'LOGOUT_USER';

export const SET_PROPERTYERROR = 'SET_PROPERTYERROR';

export const CLEAR_PROPERTYERROR = 'CLEAR_PROPERTYERROR';

// FILTER ACTIONS

export const SET_FILTERS = 'SET_FILTERS';

export const CLEAR_FILTERS = 'CLEAR_FILTERS';

// RETAIN FILTERS ON BACK

export const SET_PROPERTY = 'SET_PROPERTY';

export const CLEAR_PROPERTY = 'CLEAR_PROPERTY';

export const SET_PROPERTYID = 'SET_PROPERTYID';

export const CLEAR_PROPERTYID = 'CLEAR_PROPERTYID';

export const SET_WANTED = 'SET_WANTED';

export const CLEAR_WANTED = 'CLEAR_WANTED';

export const SET_WANTEDID = 'SET_WANTEDID';

export const CLEAR_WANTEDID = 'CLEAR_WANTEDID';

export const SET_USER = 'SET_USER';

export const CLEAR_USER = 'CLEAR_USER';

export const SET_MOP_PROPERTY = 'SET_MOP_PROPERTY';

export const CLEAR_MOP_PROPERTY = 'CLEAR_MOP_PROPERTY';

export const SET_PROPERTY_REQUEST = 'SET_PROPERTY_REQUEST';

export const CLEAR_PROPERTY_REQUEST = 'CLEAR_PROPERTY_REQUEST';

export const SET_BACK = 'SET_BACK';

export const CLEAR_BACK = 'CLEAR_BACK';

export const SET_TITLE = 'SET_TITLE';

export const CLEAR_TITLE = 'CLEAR_TITLE';

export const SET_HOME = 'SET_HOME';

export const CLEAR_HOME = 'CLEAR_HOME';

export const SET_ADD_USER = 'SET_ADD_USER';

export const CLEAR_ADD_USER = 'CLEAR_ADD_USER';

export const SET_ADD_RIDER = 'SET_ADD_RIDER';

export const CLEAR_ADD_RIDER = 'CLEAR_ADD_RIDER';
