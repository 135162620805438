/** @format */

import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link, Route } from 'react-router-dom';

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import routes from '../static/_routes';

const findRouteName = (url) =>
  (_.find(routes, (route) => route.route === url) &&
    _.find(routes, (route) => route.route === url).name) ||
  null;

const getPaths = (pathname) => {
  const paths = ['/'];

  if (pathname === '/') {
    return paths;
  }

  pathname.split('/').reduce((prev, curr /* , index */) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

const BreadcrumbsItem = ({ match, location /* , ...rest */ }) => {
  const routeName = findRouteName(match.url);
  let matchProperties = false;
  let matchId = '';
  if (routeName) {
    const pathname = location.pathname.split('/');
    pathname.map((pathName) => {
      if (pathName === 'edit-listing-form' || pathName === 'listing-details') {
        const index = pathname[4];
        matchId = index;
        matchProperties = true;
        pathname.splice(-1, 1);
      } else if (pathName === 'add-listing-form') {
        const index = pathname[4];
        matchId = index;
        matchProperties = true;
        // pathname.splice(-1, 1);
      } else if (pathName === 'match-properties' || pathName === 'user-contact-info') {
        matchProperties = true;
        const index = pathname[4];
        matchId = index;
      } else if (pathName === 'add-meta-info-listings' || pathName === 'edit-meta-info-listings') {
        const index = pathname[6];
        matchId = index;
        matchProperties = true;
        pathname.splice(-1, 1);
      } else if (pathName === 'add-area-description') {
        const index = pathname[4];
        matchId = index;
        pathname.splice(-1, 1);
      }
      return pathName;
    });
    const matchpath = match.url.split('/');
    let mess = '';

    if (Number(pathname[pathname.length - 1])) {
      pathname.splice(-1, 1);
    }
    if (
      pathname[pathname.length - 1] === 'admin' ||
      pathname[pathname.length - 1] === 'marketing' ||
      pathname[pathname.length - 1] === 'mop'
    ) {
      pathname.splice(-1, 1);
    }
    if (
      routeName === 'Cataloging' ||
      routeName === 'Wanted' ||
      routeName === 'Users' ||
      routeName === 'Properties Media' ||
      routeName === 'Media Requests'
    ) {
      mess = 'breadcrumbs';
    }
    return (match.isExact && routeName !== 'Home') ||
      (pathname.length === matchpath.length && pathname.join('/') === matchpath.join('/')) ? (
      <BreadcrumbItem active>
        {routeName === 'Advanced Projects' ? 'Projects (ADVANCED)' : routeName}
      </BreadcrumbItem>
    ) : (
      <BreadcrumbItem>
        {matchProperties &&
        match.url !== '/wanted' &&
        match.url !== '/projects' &&
        match.url !== '/projects/meta-info' &&
        match.url !== '/graana-users' &&
        match.url !== '/advanced-projects' &&
        match.url !== '/' ? (
          <Link
            style={{ color: '#9e9e9e', textDecoration: 'none' }}
            to={{
              pathname: `${match.url}/${matchId}` || '',
            }}
          >
            {routeName === 'Advanced Projects' ? 'Projects (ADVANCED)' : routeName}
          </Link>
        ) : (
          <Link
            style={{ color: '#9e9e9e', textDecoration: 'none' }}
            to={{
              pathname: match.url || '',
              state: { message: mess },
            }}
          >
            {routeName === 'Advanced Projects' ? 'Projects (ADVANCED)' : routeName}
          </Link>
        )}
      </BreadcrumbItem>
    );
  }
  return null;
};

BreadcrumbsItem.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

const Breadcrumbs = ({ location: { pathname } /* , match , ...rest */ }) => {
  const paths = getPaths(pathname);
  const items = paths.map((path) => <Route key={path} path={path} component={BreadcrumbsItem} />);

  return <Breadcrumb>{items}</Breadcrumb>;
};

Breadcrumbs.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

const Crumb = (props) => (
  <div>
    <Route path="*" component={Breadcrumbs} {...props} />
  </div>
);

export default Crumb;
