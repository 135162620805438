/** @format */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavbarBrand,
  UncontrolledDropdown,
} from 'reactstrap';
import PropTypes from 'prop-types';

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { connect } from 'react-redux';
import { ellipsis } from '../common/actions';
import HasRole from '../hoc/HasRole';
import blankUser from '../public/img/blank-user.png';
import { generateImageUrl } from '../utils/helper';

const dataEntry = 'data-entry';
const style = {
  logoWrapper: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
  },
  svg: {
    width: '75%',
    fill: 'white',
    height: '36px',
    marginTop: '30px',
  },
  img: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    margin: '0 10px',
    verticalAlign: 'top',
  },
};

class Header extends Component {
  static sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  static mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  static asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  constructor(props) {
    super(props);
    this.state = {
      isExpand: null,
    };
  }

  superadminProfile = () => {
    const { currentUserId, currentSuperAdmin } = this.props;
    window.location.pathname = currentSuperAdmin
      ? `/users/edit-system-user/${currentUserId}`
      : `/users/edit-profile/${currentUserId}`;
  };

  logout = () => {
    window.location.pathname = '/logout';
  };

  showSideBar = (e) => {
    e.preventDefault();

    // if (document.getElementById('sidebar').style.display !== '') {
    //   document.getElementById('sidebar').style.display = 'unset';
    //   document.getElementById('sidebar').style.width = '299px';
    //   // document.getElementById('sideBarIcon').style.marginLeft = '-60px';
    // } else {
    //   document.getElementById('sidebar').style.display = 'none';
    //   // document.getElementById('sideBarIcon').style.marginLeft = '-250px';
    // }
    document.body.classList.toggle('sidebar-mobile-show');
  };

  sidebarMinimize(e) {
    const { isExpand } = this.state;
    this.setState({ isExpand: !isExpand ? true : null });
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
    document.body.classList.toggle('brand-minimized');
  }

  render() {
    let headerTitle;
    const { isExpand } = this.state;

    const {
      currentUserDepartment,
      currentUserFullName,
      currentUserEmail,
      user,
      title,
      addUser,
      addRider,
    } = this.props;
    let { currentUserImage } = this.props;
    if (currentUserDepartment && currentUserDepartment === 'mop') {
      headerTitle = 'MOP Dashboard';
    } else if (currentUserDepartment && user && user.departmentInfo) {
      headerTitle = title;
    }
    if (currentUserImage) {
      currentUserImage = currentUserImage.replace('http://', 'https://');
    }
    return (
      <header className="app-header navbar">
        <div className="sideBarIcon" id="sideBarIcon">
          <MenuOutlinedIcon
            onClick={(e) => {
              this.showSideBar(e);
            }}
          />
        </div>
        <NavbarBrand
          tag={Link}
          to={window.localStorage.getItem('isHome') ? window.localStorage.getItem('isHome') : '/'}
        >
          <div style={style.logoWrapper} className="svg-logo">
            <svg style={style.svg}>
              <use xlinkHref="/img/logo.svg#logo" />
            </svg>
          </div>
          <div style={style.logoWrapper} className="svg-logo-icon">
            <svg style={style.svg}>
              <use xlinkHref="/img/logo.svg#onlyLogo" />
            </svg>
          </div>
        </NavbarBrand>
        <button
          type="button"
          className="badge rounded-pill toogleSidebarIcon"
          onClick={(e) => {
            this.sidebarMinimize(e);
          }}
        >
          {isExpand ? (
            <KeyboardArrowRightIcon style={{ paddingRight: '5px', alignSelf: 'center' }} />
          ) : (
            <KeyboardArrowLeftIcon style={{ paddingRight: '5px', alignSelf: 'center' }} />
          )}
        </button>
        <h3 className="me-auto  admin-heading col">
          {headerTitle === 'Advanced Projects' ? (
            <div style={{ display: 'inline-block' }}>
              Projects{' '}
              <div className="badge bg-danger" style={{ marginLeft: 10, borderRadius: 5 }}>
                ADVANCED
              </div>
            </div>
          ) : (
            headerTitle
          )}
        </h3>
        {addUser && user.department_id === 1 && user.role === 'manager' ? (
          <Link to="/users/add-system-user">
            <button
              type="button"
              className="btn btn"
              style={{
                color: 'white',
                marginRight: '10px',
                marginTop: '4px',
                height: '40px',
                fontWeight: 'normal',
                backgroundColor: 'rgb(55, 162, 248)',
                boxShadow: 'none',
                borderRadius: '8px',
              }}
            >
              + Add New User
            </button>
          </Link>
        ) : null}
        {addRider ? (
          <HasRole
            departmentRoles={{
              admin: ['manager', dataEntry],
              afterSales: ['manager', dataEntry],
            }}
          >
            <Link to="/users/add-rider">
              <button
                type="button"
                className="btn btn"
                style={{
                  color: 'white',
                  marginRight: '10px',
                  marginTop: '4px',
                  height: '40px',
                  fontWeight: 'normal',
                  backgroundColor: 'rgb(55, 162, 248)',
                  boxShadow: 'none',
                  borderRadius: '8px',
                }}
              >
                + Add New Rider
              </button>
            </Link>
          </HasRole>
        ) : null}
        <div className="btn profile">
          <UncontrolledDropdown>
            <DropdownToggle
              style={{
                width: '180px',
                padding: '4px',
                boxShadow: 'none',
                fontSize: '8px',
                textAlign: 'left',
                color: '#aaa',
                fontWeight: 'normal',
                backgroundColor: '#fff',
                borderColor: 'transparent',
              }}
            >
              <div>
                <img style={style.img} src={`${generateImageUrl(currentUserImage || blankUser)}`} alt="user" />
                <div style={{ display: 'inline-block' }}>
                  {currentUserFullName} <br />
                  {currentUserDepartment &&
                    currentUserDepartment.charAt(0).toUpperCase() +
                      currentUserDepartment.slice(1)}{' '}
                  <br />
                  {currentUserEmail}
                </div>
              </div>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.superadminProfile()} style={{ border: 'none' }}>
                Profile
              </DropdownItem>
              <DropdownItem onClick={() => this.logout()} style={{ border: 'none' }}>
                Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  const user = state.user.user || {};
  return {
    currentUserImage: user.profile_image,
    currentUserId: user.id,
    currentUserFullName: ellipsis(`${user.first_name} ${user.last_name}`),
    currentUserEmail: user.email ? ellipsis(user.email) : null,
    currentUserDepartment: user.department && ellipsis(user.department.name),
    currentSuperAdmin: user.superadmin,
    user: state.user.user,
    title: state.title.title,
    home: state.home.home,
    addUser: state.addUser.addUser,
    addRider: state.addRider.addRider,
  };
};

Header.propTypes = {
  currentUserImage: PropTypes.string,
  currentUserId: PropTypes.number,
  currentUserFullName: PropTypes.string,
  currentUserEmail: PropTypes.string,
  currentUserDepartment: PropTypes.string,
  currentSuperAdmin: PropTypes.bool,
  user: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.instanceOf(Object),
  addUser: PropTypes.instanceOf(Object),
  addRider: PropTypes.instanceOf(Object).isRequired,
};

Header.defaultProps = {
  currentUserImage: '',
  currentUserId: undefined,
  currentUserFullName: '',
  currentUserEmail: '',
  currentUserDepartment: '',
  currentSuperAdmin: false,
  title: '',
  addUser: '',
};

export default connect(mapStateToProps)(Header);
