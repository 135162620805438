/** @format */

import React, { useState } from 'react';
import './styles.scss';
import OtpInput from 'react-otp-input';
import OtpTimer from 'otp-timer';
import { Container, Row, Col, CardGroup, Card, CardBody, Button } from 'reactstrap';
import Formsy from 'formsy-react';
import axios from '../utils/axiosInstance';
import GraanaLogo from '../components/GraanaLogo';
import getOtpUrl from '../utils/OtpUrls';
import { VERSION_NUMBER } from '../static/_constants';

const OtpVerification = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [otpValue, setOtpValue] = useState('');
  const [verificationKey, setVerificationKey] = useState(history?.location?.state?.verificationKey);

  const submitForm = () => {
    if (otpValue.length === 4) {
      setLoading(true);
      const data = {
        generatedOTP: otpValue,
        verification_key: verificationKey,
      };
      axios
        .post(getOtpUrl('verify'), data)
        .then((response) => {
          setLoading(false);
          if (response?.data?.Status === 'Success') {
            history.push({
              pathname: '/reset-password',
              state: {
                userIdEncoded: history?.location?.state?.userIdEncoded,
                phoneOrEmail: history?.location?.state?.phoneOrEmail,
              },
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          window.alert(error);
        });
    }
  };

  const resendOtp = () => {
    setOtpValue('');
    setIsDisabled(true);
    const data = history?.location?.state?.data;
    const payload = {};
    if (data?.isMobile) {
      payload.phone_number = data?.phoneNumber;
    } else {
      payload.email = data?.email;
    }
    axios
      .post(getOtpUrl(data?.isMobile ? 'phone' : 'email'), payload)
      .then((response) => {
        if (response?.status === 200) {
          setVerificationKey(response?.data?.Details);
          setLoading(false);
        }
      })
      .catch((/* error */) => {
        // console.log(error);
      });
  };

  return (
    <div className="app flex-row align-items-center animated fadeIn">
      <Container>
        <Row className="justify-content-center">
          <div className="col-md-8">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <div>
                    <div className="text-center mb-4">
                      <img
                        src="../img/verifyOtp.png"
                        alt="forget icon"
                        style={{ height: '77px', width: '77px' }}
                      />
                    </div>
                    <div className="text-center mb-2">
                      <label className="text-center heading">OTP Verification</label>
                    </div>
                    <p className="text mb-4">
                      Please enter the verification code sent to{' '}
                      <strong>{history?.location?.state?.phoneOrEmail}</strong>
                    </p>
                  </div>
                  <div>
                    <Formsy onValidSubmit={submitForm}>
                      <div className="alignOtpField">
                        <OtpInput
                          inputStyle="otpBox"
                          value={otpValue}
                          onChange={(e) => {
                            if (e.length === 4) {
                              setIsDisabled(false);
                              setOtpValue(e);
                            } else {
                              setIsDisabled(true);
                              setOtpValue(e);
                            }
                          }}
                          numInputs={4}
                          required
                        />
                      </div>

                      <div className="text-center">
                        <Row className="mt-4">
                          <Col xs="12">
                            <div>
                              <p className="resendText">
                                Did not receive code? Resend in
                                <OtpTimer
                                  seconds={59}
                                  minutes={0}
                                  resend={resendOtp}
                                  text=" "
                                  textColor="#f24e1e"
                                />
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <Button
                              color="graana-red"
                              style={{ color: 'white' }}
                              className={`px-4 ${loading || isDisabled ? 'disabled' : ''}`}
                            >
                              <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} />{' '}
                              Verify
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Formsy>
                  </div>
                </CardBody>
                <div className="text-center versionLabel">{VERSION_NUMBER}</div>
              </Card>
              <GraanaLogo styles={{ width: '70%', margin: '2% auto' }} marginValue="35" />
            </CardGroup>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default OtpVerification;
