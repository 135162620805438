/** @format */

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/lib/css/_datepicker.css';
import './scss/react_dates_overrides.scss';
// Styles
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.scss';
// Temp fix for reactstrap
import './scss/core/_dropdown-menu-right.scss';

import * as Sentry from '@sentry/react';

import { Route, Switch } from 'react-router-dom';

// Main Container
import { ConnectedRouter } from 'react-router-redux';
// Pages
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import createHistory from 'history/createBrowserHistory';
import configureStore from './store';
import Logout from './pages/Logout';
import Login from './pages/Login';
import ForgetPassword from './pages/ForgetPassword';
import OtpVerification from './pages/OtpVerification';
import ResetPassword from './pages/ResetPassword';
import App from './app';

// import 'font-awesome/css/font-awesome.css';

const history = createHistory({ basename: '/' });
const store = configureStore(history);
// const syncHistory = syncHistoryWithStore(history, store);
// eslint-disable-next-line no-console
console.log('\n=====================\n', process.env.REACT_APP_ENV, '\n=====================\n');

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://1cd0b589915c4de7982ac4fc30cc38d4@sentry.graana.rocks/5',
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/login" name="Login" component={Login} />
        <Route exact path="/forget-password" name="ForgetPassword" component={ForgetPassword} />
        <Route exact path="/otp-verification" name="OtpVerification" component={OtpVerification} />
        <Route exact path="/reset-password" name="ResetPassword" component={ResetPassword} />
        <Route exact path="/logout" name="Logout" component={Logout} />
        <Route path="/*" name="Stats" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
