/** @format */

import moment from 'moment';
import { imagesNamesSort } from './actions';

const dateAdded = 'Date added';
const receivedAt = 'Received at';
// Make HEADER and rowData have same orders to work
export const CUSTOMERS_HEADER = {
  number: 'No.',
  id: 'Customer id',
  first_name: 'First Name',
  last_name: 'Last Name',
  phone: 'Phone',
  email: 'Email',
  cnic: 'Cnic',
};

export function CVS_HEADER(stats) {
  let cvRows = {
    number: 'No.',
    id: 'CV Id',
    name: 'Name',
    job_title: 'Job Title',
    phone: 'Phone',
    email: 'Email',
    cv: 'CV',
    gender: 'Gender',
    career: 'Career Level',
    education: 'Education',
    currentSalary: 'Current Salary',
    expectedSalary: 'Expected Salary',
    relocation: 'Relocation',
    fieldRoles: 'Field Roles',
  };
  let maxE = stats[0].education.length;
  let maxW = stats[0].work_experiences.length;
  if (stats) {
    stats.map((v) => {
      maxE = Math.max(maxE, v.education.length);
      return true;
    });
  }
  if (stats) {
    stats.map((v) => {
      maxW = Math.max(maxW, v.work_experiences.length);
      return true;
    });
  }
  window.value = maxE;
  for (let i = 0; i < maxE; i += 1) {
    cvRows = {
      ...cvRows,
      ...{ [`degree${i}`]: `Degree ${i + 1}` },
      ...{ [`institute${i}`]: `Institute ${i + 1}` },
      ...{ [`field${i}`]: `Field ${i + 1}` },
      ...{ [`result${i}`]: `Result ${i + 1}` },
    };
  }
  for (let i = 0; i < maxW; i += 1) {
    cvRows = {
      ...cvRows,
      ...{ [`company${i}`]: `Company ${i + 1}` },
      ...{ [`job_title${i}`]: `Job Title ${i + 1}` },
      ...{ [`location${i}`]: `Location ${i + 1}` },
      ...{ [`startDate${i}`]: `Start Date ${i + 1}` },
      ...{ [`endDate${i}`]: `End Date ${i + 1}` },
    };
  }
  return cvRows;
}

export const WANTED_HEADER = {
  number: 'No.',
  id: 'ID',
  posted_by: 'Posted by',
  purpose: 'Purpose',
  type: 'Type',
  subtype: 'Subtype',
  city_id: 'City id',
  city: 'City',
  areas: 'Areas',
  size: 'Size',
  size_unit: 'Size unit',
  price: 'Price',
  description: 'Description',
  user_id: 'User id',
  phone: 'Phone',
  status: 'Status',
  arms_status: 'ARMS status',
  reason: 'Reason',
  arms_id: 'ARMS Id',
  assigned_to: 'Assigned to',
  date_assigned: 'Date assigned',
  date_added: dateAdded,
};

export const PROPERTIES_HEADER = {
  number: 'No.',
  property_id: 'Property id',
  title: 'Title',
  custom_title: 'Title by End User',
  purpose: 'Purpose',
  type: 'Type',
  subtype: 'Subtype',
  city_id: 'City id',
  city: 'City',
  size: 'Size',
  area_id: 'Area id',
  area: 'Area',
  size_unit: 'Size unit',
  price: 'Price',
  posted_by: 'Posted by',
  status: 'Status',
  substatus: 'Rejection Reason',
  date_added: dateAdded,
  date_updated: 'Date updated',
  cataloging: 'Cataloging Done',
  edited_at: 'Edited at',
  address: 'Address',
  description: 'Description',
  general_size: 'General size',
  agency_id: 'Agency id',
  agency: 'Agency',
  rider: 'Rider',
  user_id: 'User id',
  phone: 'Phone',
  email: 'Email',
  priority: 'Priority',
  bed: 'Bed',
  bath: 'Bath',
  year_built: 'Year built',
  parking_space: 'Parking space',
  downpayment: 'Downpayment',
  security_deposit: 'Security deposit',
  maintenance_charges: 'Maintenance charges',
  gas: 'Gas',
  sewerage: 'Sewerage',
  electricity: 'Electricity',
  tv_lounge: 'Tv lounge',
  semi_furnished: 'Semi furnished',
  fully_furnished: 'Fully furnished',
  swimming_pool: 'Swimming pool',
  wifi: 'Wifi',
  elevator: 'Elevator',
  powder_room: 'Powder room',
  dirty_kitchen: 'Dirty kitchen',
  study_room: 'Study room',
  accessible_for_specially_abled_persons: 'Accessible for specially-abled persons',
  security_staff: 'Security staff',
  nearby_landmark: 'Nearby landmark',
  corner_house: 'Corner house',
  home_theatre: 'Home theatre',
  laundry_room: 'Laundry room',
  servant_quarter: 'Servant quarter',
  dining_room: 'Dining room',
  drawing_room: 'Drawing room',
  kitchen: 'Kitchen',
  store_room: 'Store room',
  lawn: 'Lawn',
  balcony: 'Balcony',
  maintenance: 'Maintenance',
  dispute: 'Dispute',
  water: 'Water',
  possession: 'Possession',
  corner: 'Corner',
  park_facing: 'Park facing',
  boundary_wall: 'Boundary wall',
  extra_land: 'Extra land',
  main_boulevard: 'Main boulevard',
  central_heating: 'Central heating',
  central_cooling: 'Central cooling',
  public_parking: 'Public parking',
  underground_parking: 'Underground Parking',
  internet: 'Internet',
  cctv: 'Cctv',
  backup_generator: 'Backup generator',
  utilities_paid: 'Utilities paid',
  accessing_boulevard: 'Accessing boulevard',
  north: 'North',
  north_west: 'North west',
  north_east: 'North east',
  south: 'South',
  south_west: 'South west',
  south_east: 'South east',
  east: 'East',
  west: 'West',
  video: 'Video',
  images: 'Images',
};

export const REGIONS_HEADER = {
  number: 'No.',
  id: 'Region Id',
  region_name: 'Region Name',
  zones: 'Zones',
  email: 'Email',
  phone: 'Phone,',
};

export const ZONES_HEADER = {
  number: 'No.',
  id: 'Zone id',
  zone_name: 'Zone Name',
  city: 'City',
  area: 'Area',
  phone: 'Phone',
};

export const AREAGROUPS_HEADER = {
  number: 'No.',
  id: 'Area Group id',
  name: 'Group Name',
  areas: 'Areas',
  am_phone: 'AM Phone',
  am_email: 'AM Email',
};

export const BOOKING_HEADER = {
  number: 'No.',
  id: 'Booking ID',
  order_id: 'Order ID',
  session_id: 'Session ID',
  name: 'Name',
  email: 'Email',
  status: 'Status',
  cnic: 'CNIC',
  amount: 'Payment Amount',
  service_charges: 'Service Charges',
  payment_type: 'Payment Type',
};

export const SYSTEM_USERS_HEADER = {
  number: 'No.',
  id: 'User id',
  first_name: 'First Name',
  last_name: 'last_name',
  email: 'Email',
  phone: 'Phone',
  city: 'City',
  role: 'Role',
  department: 'Department',
};

export const USERS_HEADER = {
  number: 'No.',
  id: 'User id',
  first_name: 'First name',
  last_name: 'Last name',
  email: 'Email',
  phone: 'Phone',
  agency_id: 'Agency id',
  agency_rights_id: 'Agency rights id',
  agency: 'Agency',
  comments: 'Comments',
};

export const PROJECTS_HEADER = {
  number: 'No.',
  project_id: 'Project id',
  project: 'Project',
  area_id: 'Area id',
  area: 'Area',
  city_id: 'City id',
  city: 'City',
  marla_size: 'Marla Size (SqFt)',
  phone: 'Phone',
  lat: 'Latitude',
  lon: 'Longitude',
  developer_id: 'Developer id',
  developer: 'Developer',
  marketedBy: 'Marketed By',
  date_added: dateAdded,
};

export const AGENCIES_HEADER = {
  number: 'No.',
  agency_id: 'Agency id',
  agency: 'Agency',
  city_id: 'City id',
  city: 'City',
  childAgency: 'Child Agencies',
  parentAgency: 'Parent Agencies',
  user_id: 'User id',
  email: 'Email',
  phone: 'Phone',
  cover: 'Cover',
  logo: 'Logo',
  description: 'Description',
  address: 'Address',
  website: 'Website',
  video_title: 'Video title',
  video_link: 'Video link',
  lat: 'Latitude',
  lon: 'Longitude',
  agency_areas: 'Area id',
  date_added: dateAdded,
};

export const DEVELOPERS_HEADER = {
  number: 'No.',
  id: 'Developer id',
  developer: 'Developer',
  address: 'Address',
  phone: 'Phone',
  user_id: 'User id',
  email: 'Email',
  website: 'Website',
  lat: 'Latitude',
  long: 'Longitude',
  logo: 'Logo',
  cover: 'Cover',
  date_added: dateAdded,
};

export const AREAS_HEADER = {
  number: 'No.',
  area_id: 'Area id',
  name: 'Name',
  size: 'Size',
  city_id: 'City id',
  city: 'City',
  parentArea: 'Parent Area',
  childAreas: 'Child Areas',
  areaGroup: 'Area Group',
  marla_size: 'Marla size (SqFt)',
  population: 'Population',
  description: 'Description',
  lat: 'Latitude',
  lon: 'Longitude',
  status: 'Status',
};

export const REQUESTS_HEADER = {
  number: 'No',
  request_id: 'Request id',
  purpose: 'Purpose',
  type: 'Type',
  city: 'City',
  size: 'Size',
  size_unit: 'Size unit',
  price: 'Price',
  posted_by: 'Posted by',
  status: 'Status',
  date_added: dateAdded,
};

export const INQUIRES_HEADER = {
  number: 'No',
  inquiry_id: 'Inquiry id',
  title: 'Title',
  type: 'Type',
  name: 'Name',
  phone: 'Phone',
  email: 'Email',
  received_at: receivedAt,
};

export const CREDIT_REQUEST_HEADER = {
  number: 'No',
  basic: 'Basic',
  premium: 'Premium',
  premium_plus: 'Premium Plus',
  created_at: 'Sent at',
  email: 'Email',
  phone: 'Phone',
  agency: 'Agency',
  status: 'Status',
};

export const USER_FEEDBACK_HEADER = {
  number: 'No',
  id: 'ID',
  gender: 'Gender',
  age: 'Age',
  profession: 'Profession',
  easeOfUse: 'Ease of Use',
  informationRelevance: 'Information Relevance',
  pictureQuality: 'Picture Quality',
  informationQuality: 'Information Quality',
  informationCompleteness: 'Information Completeness',
  portalLayout: 'Portal Layout',
  strengths: 'Strengths',
  weakness: 'Weakness',
  improvements: 'Improvements',
};

export const FEEDBACK_HEADER = {
  number: 'Number',
  description: 'Message',
  property_title: 'Property',
  property_id: 'Property ID',
  agency_name: 'Agency Name',
  city: 'City',
  phone: 'Phone',
  email: 'Email',
  reason: 'Reason',
  date_added: receivedAt,
};

export const COMPLAINTS_HEADER = {
  number: 'Number',
  id: 'ID',
  complaint_title: 'Title',
  name: 'Name',
  email: 'Email',
  agency: 'Agency',
  department: 'Department',
  status: 'Status',
  date_added: receivedAt,
};

export function customersRowData(customerData, index) {
  return {
    number: index + 1,
    id: customerData.id,
    first_name: customerData.first_name,
    last_name: customerData.last_name,
    phone: customerData.phone,
    email: customerData.email,
    cnic: customerData.cnic,
  };
}

export function cvsRowData(stats, careerData, index, getAll, cvs) {
  const eLength = careerData.education;
  const wLength = careerData.work_experiences;
  const dateFormat = 'DD-MM-YYYY';
  let cvRows = {
    number: index + 1,
    id: careerData.id,
    name: `${careerData.first_name} ${careerData.last_name}`,
    job_title: careerData.job ? careerData.job.title : '',
    phone: careerData.phone,
    email: careerData.email,
    cv: careerData.cv_url,
    gender: careerData.gender,
    career: careerData.career_level,
    education: careerData.highest_degree,
    currentSalary: careerData.current_salary,
    expectedSalary: careerData.expected_salary,
    relocation: careerData.relocate,
    fieldRoles: careerData.travelling,
  };
  let maxE;
  if (stats) {
    maxE = stats[0].education.length;
    stats.map((v) => {
      maxE = Math.max(maxE, v.education.length);
      return true;
    });
  }
  if (cvs) {
    maxE = cvs[0].education.length;
    cvs.map((v) => {
      maxE = Math.max(maxE, v.education.length);
      return true;
    });
  }
  for (let i = 0; i < maxE; i += 1) {
    cvRows = {
      ...cvRows,
      ...{ [`degree${i}`]: eLength[i] ? eLength[i].degree : '' },
      ...{ [`institute${i}`]: eLength[i] ? eLength[i].institute : '' },
      ...{ [`field${i}`]: eLength[i] ? eLength[i].field : '' },
      ...{ [`result${i}`]: eLength[i] ? eLength[i].result : '' },
    };
  }
  wLength.map((experiece, i) => {
    cvRows = {
      ...cvRows,
      ...{ [`company${i}`]: experiece.company },
      ...{ [`job_title${i}`]: experiece.job_title },
      ...{ [`location${i}`]: experiece.location },
      ...{
        [`startDate${i}`]: experiece.startDate
          ? moment(experiece.startDate).format(dateFormat)
          : 'No Date',
      },
      ...{
        [`endDate${i}`]: experiece.endDate
          ? moment(experiece.endDate).format(dateFormat)
          : 'No Date',
      },
    };
    return true;
  });
  return cvRows;
}

export function wantedAllRowData(wanted, wantedData, index) {
  let newStatus;
  let assignedUser = null;
  let assignedDate = null;
  if (wantedData.status && wantedData.substatus === 'good') {
    newStatus = 'Assigned';
    if (wantedData.armsuser) {
      assignedUser = `${wantedData.armsuser.firstName} ${wantedData.armsuser.lastName}`;
    }
    assignedDate = moment(wantedData.status_date).format('ll');
  }
  if (wantedData.status === 'pending' && wantedData.substatus === 'new' && !wantedData.reason) {
    newStatus = 'New';
  }
  if (wantedData.status === 'pending' && wantedData.substatus === 'new' && wantedData.reason) {
    newStatus = 'Pending';
  }
  if (wantedData.status === 'rejected') {
    newStatus = 'Rejected';
  }
  return {
    number: index + 1,
    id: wantedData.id,
    posted_by: wantedData.name ? wantedData.name : '',
    purpose: wantedData.purpose === 'sale' ? 'buy' : wantedData.purpose,
    type: wantedData.type,
    subtype: wantedData.subtype,
    city_id: wantedData.city ? wantedData.city.id : '',
    city: wantedData.city ? wantedData.city.name : '',
    areas: wantedData.wanted_areas ? wantedData.wanted_areas.map((area) => area.area.name) : '',
    size: wantedData.size || '',
    size_unit: wantedData.size_unit,
    price:
      wantedData.min_price || wantedData.max_price
        ? `${wantedData.min_price || ''} - ${wantedData.max_price || ''}`
        : wantedData.price,
    description: wantedData.description || '',
    user_id: wantedData.user ? wantedData.user.id : '',
    phone: wantedData.phone,
    status: newStatus,
    arms_status: wantedData.armsLeads
      ? wantedData.armsLeads.map((armsLead) => armsLead.status)
      : '',
    reason: wantedData.reason,
    arms_id: wantedData.armsLeads ? wantedData.armsLeads.map((armsLead) => armsLead.id) : '',
    assigned_to: assignedUser || '',
    date_assigned: assignedDate || '',
    date_added: wantedData.createdAt ? moment(wantedData.createdAt).format('ll') : '',
  };
}

export function propertiesAllRowData(property, index, features, propertyImagesNames) {
  const pType = property.type || property.ptype;
  const dataSet1 = {
    number: index + 1,
    property_id: property.id,
    title: property.custom_title,
    custom_title: property.custom_title_generated,
    purpose: property.purpose,
    type: property.type || property.ptype,
    subtype: property.subtype,
    city_id: property.city ? property.city.id : '',
    city: property.city ? property.city.name : '',
    size: property.size,
    area_id: property.area ? property.area.id : '',
    area: property.area ? property.area.name : '',
    size_unit: property.size_unit,
    price: property.price,
    posted_by: property.user ? property.user.email : '',
    status: property.status,
    substatus: property.substatus ? property.substatus : 'No Data',
    date_added: property.old_created_at
      ? moment(property.old_created_at).format('MMM DD, HH:mm')
      : '',
    date_updated: property.status_changed_at
      ? moment(property.status_changed_at).format('MMM DD, HH:mm')
      : '',
    cataloging: property.catalogue ? property.catalogue : 'FALSE',
    edited_at: property.edited_at ? moment(property.edited_at).format('MMM DD, HH:mm') : '',
    address: property.address,
    description: property.description,
    general_size: property.general_size,
    agency_id: property.agency ? property.agency.id : '',
    agency: property.agency ? property.agency.name : '',
    rider: property.rider ? `${property.rider.first_name} ${property.rider.last_name}` : '',
    user_id: property.user ? property.user.id : '',
    phone: property.phone,
    email: property.user ? property.user.email : '',
    priority: property.priority,
    bed: property.bed,
    bath: property.bath,
    year_built: property.built_year,
  };
  const keysSet1 = [
    'parking_space',
    'downpayment',
    'security_deposit',
    'maintenance_charges',
    'gas',
    'sewerage',
    'electricity',
    'tv_lounge',
    'semi_furnished',
    'fully_furnished',
    'swimming_pool',
    'wifi',
    'elevator',
    'powder_room',
    'dirty_kitchen',
    'study_room',
    'accessible_for_specially_abled_persons',
    'security_staff',
    'nearby_landmark',
    'corner_house',
    'home_theatre',
    'laundry_room',
    'servant_quarter',
    'dining_room',
    'drawing_room',
    'kitchen',
    'store_room',
    'lawn',
    'balcony',
    'maintenance',
    'dispute',
    'water',
  ];
  const keysSet2 = [
    'possession',
    'corner',
    'park_facing',
    'boundary_wall',
    'extra_land',
    'main_boulevard',
    'central_heating',
    'central_cooling',
    'public_parking',
    'underground_parking',
    'internet',
    'cctv',
    'backup_generator',
    'utilities_paid',
    'accessing_boulevard',
  ];
  const keysSet3 = [
    'north',
    'north_west',
    'north_east',
    'south',
    'south_west',
    'south_east',
    'east',
    'west',
    'video',
    'images',
  ];
  const dataSet2 = {};
  keysSet1.forEach((k) => {
    dataSet2[k] = features && features[k] ? features[k] : 'no data';
  });
  const dataSet3 = {};
  keysSet2.forEach((k) => {
    dataSet3[k] = features && features[k] && pType !== 'residential' ? features[k] : 'No Data';
  });
  const dataSet4 = {};
  keysSet3.forEach((k) => {
    if (k === 'images') {
      dataSet4[k] = propertyImagesNames;
    } else if (k === 'video') {
      dataSet4[k] = property.video;
    } else {
      dataSet4[k] = features && features[k] ? features[k] : 'No Data';
    }
  });

  return { ...dataSet1, ...dataSet2, ...dataSet3, ...dataSet4 };
}

export function regionsRowData(regionData, index) {
  return {
    number: index + 1,
    id: regionData.id,
    region_name: regionData.name,
    zones: regionData.zones ? regionData.zones.map((zones) => zones.zone_name) : '',
    email: regionData.email,
    phone: regionData.phone,
  };
}

export function zonesRowData(zoneData, index) {
  return {
    number: index + 1,
    id: zoneData.id,
    zone_name: zoneData.zone_name,
    city: zoneData.city ? zoneData.city.name : '',
    area: zoneData.areas ? zoneData.areas.map((areas) => areas.name) : '',
    phone: zoneData.phone,
  };
}

export function areaGroupsRowData(areaData, index) {
  return {
    number: index + 1,
    id: areaData.id,
    name: areaData.name,
    areas: areaData.areas ? areaData.areas.map((areas) => areas.name) : '',
    am_phone: areaData.phone,
    am_email: areaData.email,
  };
}

export function bookingsRowData(bookingData, index) {
  return {
    number: index + 1,
    id: bookingData.id,
    order_id: bookingData.order_id,
    session_id: bookingData.session_id,
    name: bookingData.name,
    email: bookingData.email,
    status: bookingData.status,
    cnic: bookingData.cnic,
    amount: bookingData.amount,
    service_charges: bookingData.service_charges,
    payment_type: bookingData.payment_type,
  };
}

export function systemUsersRowData(systemUserData, index) {
  return {
    number: index + 1,
    user_id: systemUserData.id,
    first_name: systemUserData.first_name,
    last_name: systemUserData.last_name,
    email: systemUserData.email,
    phone: systemUserData.phone,
    city: systemUserData.system_User_cities
      ? systemUserData.system_User_cities.map((cities) => cities.city.name)
      : '',
    role: systemUserData.role,
    department: systemUserData.departmentInfo ? systemUserData.departmentInfo.name : '',
  };
}

export function usersRowData(user, index) {
  return {
    number: index + 1,
    user_id: user.id,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
    agency_id: user.agency ? user.agency.id : '',
    agency_rights_id: user.agency_rights_id,
    agency: user.agency ? user.agency.name : '',
    comments: user.comments,
  };
}

export function projectsRowData(project, index) {
  return {
    number: index + 1,
    project_id: project.id,
    project: project.name,
    area_id: project.projectArea ? project.projectArea.id : '',
    area: project.projectArea ? project.projectArea.name : '',
    city_id: project.city ? project.city.id : '',
    city: project.city ? project.city.name : '',
    marla_size: project.marla_size,
    phone: project.phone,
    lat: project.lat,
    lon: project.lon,
    developer_id: project.agency ? project.agency.id : '',
    developer: project.agency ? project.agency.name : '',
    marketedBy: project.marketedBy ? project.marketedBy.name : '',
    date_added: project.createdAt ? moment(project.createdAt).format('ll') : '',
  };
}

export function agenciesRowData(agency, index) {
  const imageNames = imagesNamesSort(agency);
  return {
    number: index + 1,
    agency_id: agency.id,
    agency: agency.name,
    city_id: agency.city ? agency.city.id : '',
    city: agency.city ? agency.city.name : '',
    childAgency: agency.childAgency
      ? agency.childAgency.map((child) => `${child.name} (ID: ${child.id}, ${child.city.name}), `)
      : '',
    parentAgency: agency.parentAgency
      ? `${agency.parentAgency.name} (ID: ${agency.parentAgency.id}, ${agency.parentAgency.city.name}), `
      : '',
    user_id: agency.user_id,
    email: agency.email,
    phone: agency.phone,
    cover: imageNames.agencyCoverImagesNames,
    logo: imageNames.agencyLogoImagesNames,
    description: agency.description,
    address: agency.address,
    website: agency.website,
    video_title: agency.video_title,
    video_link: agency.video_link,
    lat: agency.lat,
    lon: agency.lon,
    agency_areas: agency.agency_areas ? agency.agency_areas.map((areas) => areas.area_id) : '',
    date_added: agency.createdAt ? moment(agency.createdAt).format('ll') : '',
  };
}

export function developersRowData(developer, index) {
  return {
    number: index + 1,
    id: developer.id,
    developer: developer.name,
    address: developer.address,
    phone: developer.phone,
    user_id: developer.user_id,
    email: developer.email,
    website: developer.website,
    lat: developer.lat,
    long: developer.lon,
    logo: developer.logo,
    cover: developer.cover,
    date_added: developer.createdAt ? moment(developer.createdAt).format('ll') : '',
  };
}

export function areasRowData(area, index) {
  return {
    number: index + 1,
    area_id: area.id,
    area: area.name,
    size: area.size,
    city_id: area.city ? area.city.id : '',
    city: area.city ? area.city.name : '',
    parentArea: area.parentArea ? area.parentArea.name : '',
    childAreas: area.childArea ? area.childArea.map((childarea) => childarea.name) : '',
    areagroup: area.subzone ? area.subzone.name : '',
    marla_size: area.marla_size,
    population: area.population,
    description: area.description,
    latitude: area.lat,
    longitude: area.lon,
    active: area.active ? 'Active' : 'Inactive',
    graano_page: area.graano_page,
  };
}

export function requestsRowData(request, index) {
  return {
    number: index + 1,
    request_id: request.property ? request.property.id : '',
    purpose: request.property ? request.property.purpose : '',
    type: request.property ? request.property.type : '',
    area: request.property ? request.property.area.name : '',
    city: request.property ? request.property.city.name : '',
    size: request.property ? request.property.size : '',
    size_unit: request.property ? request.property.size_unit : '',
    price: request.property ? request.property.price : '',
    agency: request.property.agency ? request.property.agency.name : '',
    status: request.status ? request.status : '',
    date_added: request.createdAt ? moment(request.createdAt).format('MMM DD, HH:mm') : '',
  };
}

export function inquiriesRowData(inquiry, index) {
  return {
    number: index + 1,
    inquiry_id: inquiry.id,
    title:
      inquiry.inquireable_type && inquiry[inquiry.inquireable_type]
        ? inquiry[inquiry.inquireable_type].name
        : '',
    type: inquiry.inquireable_type,
    name: inquiry.name,
    phone: inquiry.phone,
    email: inquiry.email,
    received_at: inquiry.created_at ? moment(inquiry.created_at).format('ll') : '',
  };
}

export function creditRequestRowData(index, request) {
  return {
    number: index + 1,
    basic: request.basic,
    premium: request.premium,
    premium_plus: request.premium_plus,
    created_at: moment(request.created_at).fromNow(),
    email: request.user ? request.user.email : '',
    phone: request.user ? request.user.phone : '',
    agency: request.agency ? request.agency.name : '',
    status: request.status ? request.status : '',
  };
}

export function userFeedbackRowData(feedback, index) {
  return {
    number: index + 1,
    id: feedback.id,
    gender: feedback.gender,
    age: feedback.age,
    profession: feedback.profession,
    easeOfUse: feedback.easeOfUse,
    informationRelevance: feedback.informationRelevance,
    pictureQuality: feedback.pictureQuality,
    informationQuality: feedback.informationQuality,
    informationCompleteness: feedback.informationCompleteness,
    portalLayout: feedback.portalLayout,
    strengths: feedback.strengths,
    weakness: feedback.weakness,
    improvements: feedback.improvements,
  };
}

export function feedbackRowData(feedback, index) {
  return {
    number: index + 1,
    description: feedback.description ? feedback.description : '',
    property_title: feedback.property
      ? `${feedback.property.size} ${feedback.property.size_unit} for ${feedback.property.purpose}`
      : '',
    property_id: feedback.property ? feedback.property.id : '',
    agency_name: feedback.property && feedback.property.agency ? feedback.property.agency.name : '',
    city: feedback.property && feedback.property.city && feedback.property.city.name,
    phone: feedback.phone ? feedback.phone : '',
    email: feedback.email ? feedback.email : '',
    reason: feedback.reason ? feedback.reason : '',
    date_added: feedback.created_at ? moment(feedback.created_at).format('ll') : '',
  };
}

export function complaintsRowData(complaint, index) {
  return {
    number: index + 1,
    id: complaint.id ? complaint.id : '',
    complaint_title: complaint.title ? complaint.title : '',
    name: complaint.user ? `${complaint.user.first_name} ${complaint.user.last_name}` : '',
    email: complaint.user ? complaint.user.email : '',
    agency: complaint.agency ? complaint.agency.name : '',
    department: complaint.department,
    status: complaint.status ? complaint.status : '',
    date_added: complaint.created_at ? moment(complaint.created_at).format('ll') : '',
  };
}
