/**
 * /* eslint react/prefer-stateless-function: 0
 *
 * @format
 */

import React, { Component, Fragment } from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class CanDo extends Component {
  // FILTERING START AND ENDING SLASHES FROM THE REQUIRED PATH
  static filterPath = (path) => {
    const actualPath = path.pathname || path;
    return `/${_.compact(actualPath.trim().split('/')).join('/')}`;
  };

  static viewableChildren(children) {
    return <>{children}</>;
  }

  traverseChildren(children) {
    const {
      user: { userRoutes },
    } = this.props;

    return React.Children.map(
      children,
      (child) => {
        if (child && child.type && child.type.name === 'Link' && child.props.to) {
          // console.log('child link', userRoutes, child.props.to);

          const linkPath = CanDo.filterPath(child.props.to);
          const matchingUserRoute = _.find(userRoutes, (route) => {
            const routePath = CanDo.filterPath(route.slug);
            const routeParts = routePath.split('/');
            const linkParts = linkPath.split('/');

            if (routeParts.length === linkParts.length) {
              return _.every(
                _.map(routeParts, (part, index) => {
                  if (part.charAt(0) === ':') {
                    return true;
                  }
                  return part === linkParts[index];
                }),
                (part) => part === true
              );
            }
            return false;
          });

          if (!matchingUserRoute) {
            return null;
          }
        } else if (child && child.props && child.props.children) {
          return React.cloneElement(child, {
            children: this.traverseChildren(child.props.children),
          });
        }
        return child;
      },
      this
    );
  }

  render() {
    const { children, match, user, accessLevel, superAdmin } = this.props;
    // EARLY EXIT IF NO CHILDREN ARE INSIDE THIS COMPONENT
    if (!children) {
      return null;
    }
    // EARLY EXIT IF SUPER ADMIN IS REQUESTING VIEW
    if (superAdmin) {
      return CanDo.viewableChildren(children);
    }
    let allowedChildren = children;

    const allowedRoutes = user.userRoutes;
    const routeFound = _.find(
      allowedRoutes,
      (route) => route.slug === match.path || (route.slug === '/' && match.path === '/*')
    );

    if (routeFound && routeFound.role[user.role][accessLevel] === true) {
      if (accessLevel === 'v' && routeFound && routeFound.role[user.role].v === true) {
        allowedChildren = this.traverseChildren(children);
      }
      return CanDo.viewableChildren(allowedChildren);
    }
    return null;
  }
}

CanDo.propTypes = {
  children: propTypes.node,
  match: propTypes.instanceOf(Object).isRequired,
  user: propTypes.instanceOf(Object).isRequired,
  accessLevel: propTypes.string.isRequired,
  superAdmin: propTypes.bool.isRequired,
};

CanDo.defaultProps = {
  children: '',
};

const getMapStateToProps =
  (extendWith = {}) =>
  (state) => {
    const user = state.user.user || {};
    return {
      user,
      superAdmin: user && user.superadmin ? user.superadmin : false,
      ...extendWith,
    };
  };

// export default withRouter(connect(getMapStateToProps())(CanDo));
export const CanView = withRouter(connect(getMapStateToProps({ accessLevel: 'v' }))(CanDo));
export const CanAdd = withRouter(connect(getMapStateToProps({ accessLevel: 'a' }))(CanDo));
export const CanEdit = withRouter(connect(getMapStateToProps({ accessLevel: 'e' }))(CanDo));
export const CanDelete = withRouter(connect(getMapStateToProps({ accessLevel: 'd' }))(CanDo));
