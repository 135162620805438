/** @format */

const getOtpUrl = (type) => {
  if (process.env.REACT_APP_ENV === 'development') {
    return `https://otp-api.dev.graana.rocks/api/v1/${type}/otp`;
  }
  if (window.location.hostname.includes('admin.staging.graana.rocks')) {
    return `https://otp-api.staging.graana.rocks/api/v1/${type}/otp`;
  }
  return `https://otp-api.graana.com/api/v1/${type}/otp`;
};

export const cryptoSecret = () => 'graana@123';

export default getOtpUrl;
