/** @format */

import React from 'react';
import propTypes from 'prop-types';

import './style.scss';

const Loader = ({ loading, position, transparent }) =>
  loading ? (
    <div
      key={1}
      className={`loaderOverlay ${position ? 'fixed' : 'absolute'} ${
        transparent ? 'transparent' : 'opaque'
      }`}
    >
      <div className="logoCenter">
        <div className="logoWrapper">
          <svg>
            <use xlinkHref="/img/logo.svg#onlyLogo" />
          </svg>
        </div>
      </div>
    </div>
  ) : null;

Loader.propTypes = {
  loading: propTypes.bool.isRequired,
  position: propTypes.string.isRequired,
  transparent: propTypes.bool.isRequired,
};

export default Loader;
