/** @format */

// import React from 'react';

const SidebarFooter = (/* props, context */) => null;
// Uncomment following code lines to add Sidebar Footer
// return (
//   <div className="sidebar-footer" />
// );

export default SidebarFooter;
