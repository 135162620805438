/** @format */

import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux';
import user from './user';
import filters from './filters';
import propertyParams from './propertyParams';
import propertyID from './propertyID';
import wantedParams from './wantedParams';
import wantedID from './wantedID';
import userParams from './userParams';
import mopPropertyParams from './mopPropertyParams';
import propertyRequestParams from './propertyRequestParams';
import propertyError from './propertyError';
import back from './back';
import title from './title';
import home from './home';
import addUser from './addUser';
import addRider from './addRider';

export default combineReducers({
  user,
  router,
  filters,
  propertyParams,
  propertyID,
  wantedParams,
  wantedID,
  userParams,
  mopPropertyParams,
  propertyRequestParams,
  back,
  propertyError,
  title,
  home,
  addUser,
  addRider,
});
