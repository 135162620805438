/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import 'react-select/dist/react-select.css';

import { Map as PropertyMap, TileLayer, GeoJSON, Marker } from 'react-leaflet';
import * as turf from '@turf/turf';

import { RES, COM, EDU_INS_PB, OP, HEALTH, PARKINGS, REC } from '../static/_polygonColors';

export default class MapContainer extends React.Component {
  getPolygonStyle = (category) => {
    switch (category) {
      case 'RES':
        return {
          fillColor: RES,
          weight: 2,
          opacity: 1,
          color: RES, // Outline color
          fillOpacity: 0.5,
        };

      case 'COM':
        return {
          fillColor: COM,
          weight: 1,
          opacity: 1,
          color: COM, // Outline color
          fillOpacity: 0.5,
        };

      case 'REC':
        return {
          fillColor: REC,
          weight: 1,
          opacity: 1,
          color: REC, // Outline color
          fillOpacity: 0.5,
        };
      case 'OP':
        return {
          fillColor: OP,
          weight: 1,
          opacity: 1,
          color: OP, // Outline color
          fillOpacity: 0.5,
        };

      case 'EDU':
        return {
          fillColor: EDU_INS_PB,
          weight: 1,
          opacity: 1,
          color: EDU_INS_PB, // Outline color
          fillOpacity: 0.5,
        };

      case 'INS':
        return {
          fillColor: EDU_INS_PB,
          // fillColor: "#ff0000",
          weight: 1,
          opacity: 1,
          // color: EDU_INS_PB,  //Outline color
          color: '#ff0000',
          fillOpacity: 0.5,
        };

      case 'PB':
        return {
          fillColor: EDU_INS_PB,
          // fillColor: "#ff0000",
          weight: 1,
          opacity: 1,
          // color: EDU_INS_PB,  //Outline color
          color: '#ff0000',
          fillOpacity: 0.5,
        };

      case 'REL':
      case 'RELIGIOUS':
        return {
          // fillColor: REL_RELIGION,
          fillColor: '#ffffff',
          weight: 1,
          opacity: 1,
          // color: REL_RELIGION,  //Outline color
          color: '#ff0000', // Outline color
          fillOpacity: 0.5,
        };

      case 'HEALTH':
        return {
          fillColor: HEALTH,
          weight: 1,
          opacity: 1,
          color: HEALTH, // Outline color
          fillOpacity: 0.5,
        };

      case '0':
        return {
          fillColor: PARKINGS,
          weight: 1,
          opacity: 1,
          color: PARKINGS, // Outline color
          fillOpacity: 0.5,
        };
      default:
        return {
          fillColor: 'blue',
          weight: 1,
          opacity: 1,
          color: 'blue', // Outline color
          fillOpacity: 0.5,
        };
    }
  };

  getCentroid = (geoJSON) => {
    const poly =
      geoJSON.type === 'Polygon'
        ? turf.polygon(geoJSON.coordinates)
        : turf.multiPolygon(geoJSON.coordinates);

    const centroid = turf.centroid(poly);
    const lon = centroid.geometry.coordinates[0];
    const lat = centroid.geometry.coordinates[1];
    const marker = [lat, lon];

    return marker;
  };

  setMapview = (center, zoom) => {
    if (this.map && center) {
      this.map.leafletElement.setView([parseFloat(center[0]), parseFloat(center[1])], zoom);
    }
  };

  housingSchemePolygonStyle = () => ({
    fillColor: '#FB88FF',
    weight: 2,
    opacity: 1,
    color: '#EF5350',
    fillOpacity: 0,
  });

  cityPolygonStyle = () => ({
    fillColor: '#8ffdf6',
    weight: 2,
    opacity: 1,
    color: 'blue',
    fillOpacity: 0,
  });

  render() {
    const position = [33.706782, 73.0531593];
    // console.log('Props to map : ', this.props);
    const {
      city,
      mapcenter,
      mapZoom,
      housingScheme,
      phaseSector,
      blockSubsector,
      mapPlots,
      plotMarker,
      onSelect,
      onClick,
    } = this.props;

    if (city || housingScheme || mapPlots) {
      this.setMapview(mapcenter, mapZoom);
    }
    return (
      <div style={{ width: '100%' }}>
        <PropertyMap
          ref={(ref) => {
            this.map = ref;
          }}
          center={position}
          zoom={13}
          scrollWheelZoom={false}
          onClick={(e) => {
            onClick(e);
          }}
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            attribution='&copy; Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            maxNativeZoom={19}
            maxZoom={20}
          />

          {city && (
            <GeoJSON
              key={city?.value}
              data={JSON.parse(city.geoData)}
              style={this.cityPolygonStyle()}
            />
          )}

          {housingScheme && (
            <GeoJSON
              key={housingScheme?.value}
              data={JSON.parse(housingScheme.geoData)}
              style={this.housingSchemePolygonStyle()}
            />
          )}

          {phaseSector && (
            <GeoJSON
              key={phaseSector?.value}
              data={JSON.parse(phaseSector.geoData)}
              style={this.housingSchemePolygonStyle()}
            />
          )}

          {blockSubsector && (
            <GeoJSON
              key={blockSubsector?.value}
              data={JSON.parse(blockSubsector.geoData)}
              style={this.housingSchemePolygonStyle()}
            />
          )}

          {mapPlots &&
            mapPlots.map((plot) => (
              <GeoJSON
                key={plot?.value}
                data={JSON.parse(plot.geoData)}
                style={this.getPolygonStyle(plot.Land_Use)}
                onClick={() => {
                  onSelect(plot);
                }}
              />
            ))}

          {plotMarker && <Marker position={plotMarker} />}
        </PropertyMap>
      </div>
    );
  }
}

MapContainer.propTypes = {
  city: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  mapcenter: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  mapZoom: PropTypes.number,
  housingScheme: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  phaseSector: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  blockSubsector: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  mapPlots: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  plotMarker: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
};

MapContainer.defaultProps = {
  city: '',
  mapcenter: '',
  mapZoom: 8,
  housingScheme: '',
  phaseSector: '',
  blockSubsector: '',
  mapPlots: '',
  plotMarker: '',
  onSelect: '',
  onClick: '',
};
