/** @format */

import sellOut from './img/sellOut.svg';
import noData from './img/no_data.svg';
import rentOut from './img/rentOut.svg';
import residential from './img/residential.svg';
import plot from './img/plot.svg';
import commercial from './img/commercial.svg';
import home from './img/home.svg';
import guestHouse from './img/guestHouse.svg';
import appartment from './img/appartment.svg';
import upperPortion from './img/upperPortion.svg';
import lowerPortion from './img/lowerPortion.svg';
import room from './img/room.svg';
import hostel from './img/hostel.svg';
import basement from './img/basement.svg';
import pentHouse from './img/pentHouse.svg';
import hotelSuits from './img/hotelSuits.svg';
import farmHouse from './img/farmHouse.svg';
import annexe from './img/annexe.svg';
import residentialPlot from './img/residentialPlot.svg';
import commercialPlot from './img/commercialPlot.svg';
import agriculturalLand from './img/agriculturalLand.svg';
import industrialLand from './img/industrialLand.svg';
import plotFile from './img/plotFile.svg';
import office from './img/office.svg';
import shop from './img/shop.svg';
import wareHouse from './img/warehouse.svg';
import factory from './img/factory.svg';
import building from './img/building.svg';
import gym from './img/gym.svg';
import theatre from './img/theatre.svg';
import foodCourt from './img/foodCourt.svg';
import hall from './img/hall.svg';
import land from './img/land.svg';
import plaza from './img/plaza.svg';
import tVLounge from './img/tVLounge.svg';
import storeRoom from './img/storeRoom.svg';
import laundryRoom from './img/laundryRoom.svg';
import studyRoom from './img/studyRoom.svg';
import diningRoom from './img/diningRoom.svg';
import powderRoom from './img/powderRoom.svg';
import dirtyKitchen from './img/dirtyKitchen.svg';
import kitchen from './img/kitchen.svg';
import swimmingPool from './img/swimmingPool.svg';
import homeTheater from './img/homeTheater.svg';
import garden from './img/garden.svg';
import elevator from './img/elevator.svg';
import cornerPlot from './img/cornerPlot.svg';
import roofTop from './img/roofTop.svg';
import seperateEntry from './img/seperateEntry.svg';
import centralCooling from './img/centralCooling.svg';
import centralHeating from './img/centralHeating.svg';
import accessibility from './img/accessibility.svg';
import maintainance from './img/maintainance.svg';
import electricity from './img/electricity.svg';
import water from './img/water.svg';
import gas from './img/gas.svg';
import securityStaff from './img/securityStaff.svg';
import directions from './img/directions.svg';
import camera from './img/camera.svg';
import generator from './img/generator.svg';
import publicParking from './img/publicParking.svg';
import parkFacing from './img/parkFacing.svg';
import utilitiesPaid from './img/utilitiesPaid.svg';
import extraLand from './img/extraLand.svg';
import mainBoulevard from './img/mainBoulevard.svg';
import boundaryWall from './img/boundaryWall.svg';
import garage from './img/garage.svg';
import sidebarBath from './img/sidebarBath.svg';
import sidebarBed from './img/sidebarBed.svg';
import uploadImage from './img/uploadImage.svg';
import threeDots from './img/threeDots.svg';
import serventRoom from './img/serventRoom.svg';
import drawingRoom from './img/drawingRoom.svg';
import balcony from './img/balcony.svg';
import thumbnail from './img/thumbnail.svg';
import pinDrop from './img/pin_drop.svg';
import checkCircle from './img/check_circle.svg';
import sideBarFeaturesIcon from './img/sidebarFeatures.svg';
import homePage from './img/homePage.svg';
import wanted from './img/rwrManagement.svg';
import userManagement from './img/userManagement.svg';
import rwrManagement from './img/wanted.svg';
import projectManagement from './img/projectManagement.svg';
import setUps from './img/setups.svg';
import guruManagement from './img/guruManagement.svg';
import graanaLogo from './img/graanaLogo.svg';
import accessManagement from './img/accessManagement.svg';
import signOut from './img/signOut.svg';
import reports from './img/report.svg';
import dashboards from './img/dashboard.svg';
import media from './img/media.svg';
import responsiveFeaturesIcon from './img/responsiveFeaturesIcon.svg';
import deleteIcon from './img/delete.svg';
import posession from './img/possession.svg';
import internet from './img/internet.svg';
import undergroundParking from './img/undergroundParking.svg';
import warning from './img/warning.svg';
import furnished from './img/furnished.svg';
import semiFurnished from './img/semiFurnished.svg';
import severage from './img/severage.svg';
import wantedBed from './img/wantedBed.svg';
import wantedBath from './img/wantedBath.svg';
import wantedProperty from './img/wantedProperty.svg';
import locationIcon from './img/locationIcon.svg';
import priceIcon from './img/priceIcon.svg';
import userIcon from './img/userIcon.svg';
import prosureIcon from './img/propsure_badge.svg';

const appImages = {
  noData,
  warning,
  undergroundParking,
  internet,
  posession,
  sellOut,
  rentOut,
  residential,
  plot,
  commercial,
  home,
  guestHouse,
  appartment,
  upperPortion,
  lowerPortion,
  room,
  hostel,
  basement,
  pentHouse,
  hotelSuits,
  farmHouse,
  annexe,
  residentialPlot,
  commercialPlot,
  agriculturalLand,
  industrialLand,
  plotFile,
  office,
  shop,
  wareHouse,
  factory,
  building,
  gym,
  theatre,
  foodCourt,
  hall,
  land,
  plaza,
  tVLounge,
  storeRoom,
  laundryRoom,
  studyRoom,
  diningRoom,
  powderRoom,
  dirtyKitchen,
  kitchen,
  swimmingPool,
  homeTheater,
  garden,
  elevator,
  cornerPlot,
  roofTop,
  centralCooling,
  centralHeating,
  seperateEntry,
  accessibility,
  maintainance,
  water,
  gas,
  electricity,
  securityStaff,
  directions,
  garage,
  sidebarBath,
  sidebarBed,
  pinDrop,
  checkCircle,
  sideBarFeaturesIcon,
  homePage,
  wanted,
  rwrManagement,
  projectManagement,
  userManagement,
  setUps,
  guruManagement,
  graanaLogo,
  accessManagement,
  signOut,
  reports,
  dashboards,
  media,
  publicParking,
  thumbnail,
  serventRoom,
  drawingRoom,
  balcony,
  threeDots,
  uploadImage,
  boundaryWall,
  mainBoulevard,
  extraLand,
  utilitiesPaid,
  parkFacing,
  generator,
  camera,
  responsiveFeaturesIcon,
  deleteIcon,
  furnished,
  semiFurnished,
  severage,
  wantedBath,
  wantedProperty,
  wantedBed,
  userIcon,
  priceIcon,
  locationIcon,
  prosureIcon,
};

export default appImages;
