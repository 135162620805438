/** @format */

import React from 'react';
import PropTypes from 'prop-types';

function Total({ activePage, pageSize, total }) {
  return (
    <div className="text-end row-sm-4" style={{ marginBottom: 5 }}>
      Showing {(activePage - 1) * pageSize + 1} -{' '}
      {activePage * pageSize >= total ? total : activePage * pageSize} / {total}
    </div>
  );
}

Total.propTypes = {
  activePage: PropTypes.instanceOf().isRequired,
  pageSize: PropTypes.instanceOf().isRequired,
  total: PropTypes.instanceOf().isRequired,
};

export default Total;
