/** @format */

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import _ from 'lodash';

import { formatDepartmentName } from '../common/actions';

export class RequireRoleBase extends Component {
  static hasRequiredRole({
    requireRoleProps,
    currentUserRole,
    currentUserDepartment,
    superAdmin,
    match,
    user,
  }) {
    // console.log('###', requireRoleProps, currentUserRole, currentUserDepartment, superAdmin);

    const allowedRoutes = user.userRoutes;
    const routeFound = _.find(allowedRoutes, (route) => route.slug === match.path);
    let newRequireRoleProps = requireRoleProps;

    if (routeFound) {
      const departmentName = formatDepartmentName(user.department.name);
      newRequireRoleProps = {
        ...requireRoleProps,
        ...{ [departmentName]: [user.role] },
      };
    }

    const userDepartment = formatDepartmentName(currentUserDepartment);
    return (
      superAdmin ||
      (!!newRequireRoleProps &&
        Object.prototype.hasOwnProperty.call(newRequireRoleProps, userDepartment) &&
        newRequireRoleProps[userDepartment].indexOf(currentUserRole) >= 0)
    );
  }

  static ensureAuth(props) {
    if (
      !RequireRoleBase.hasRequiredRole(props) &&
      props.currentUserRole !== 'nobody' &&
      props.currentUserDepartment !== 'noDepartment' &&
      props.history.location.pathname !== '/'
    ) {
      // send to department dashboard page
      // replaced the current not allowed route
      props.history.replace('/');
    }
    return true;
  }

  componentDidMount() {
    // console.log('# componentWillMount this.props', this.props);
    RequireRoleBase.ensureAuth(this.props);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    // console.log('# componentWillReceiveProps nextProps', nextProps);
    RequireRoleBase.ensureAuth(nextProps);
  }

  render() {
    const {
      props: { children },
      props,
    } = this;
    if (!RequireRoleBase.hasRequiredRole(props)) {
      // don't accidentally render anything
      return null;
    }
    return <>{children}</>;
  }
}

RequireRoleBase.propTypes = {
  children: propTypes.node.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  match: propTypes.instanceOf(Object).isRequired,
  currentUserRole: propTypes.string.isRequired,
  currentUserDepartment: propTypes.string.isRequired,
  requireRoleProps: propTypes.instanceOf(Object),
  superAdmin: propTypes.bool.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

RequireRoleBase.defaultProps = {
  requireRoleProps: null,
};

const mapStateToProps = (state) => {
  const user = state.user.user || {};
  return {
    user,
    currentUserRole: user && user.role ? user.role : 'nobody',
    currentUserDepartment: user && user.department ? user.department.name : 'noDepartment',
    superAdmin: user && user.superadmin ? user.superadmin : false,
  };
};

const RequireRoleConnected = withRouter(connect(mapStateToProps)(RequireRoleBase));
export const RequireRole =
  (WrappedComponent, requireRoleProps = null) =>
  (props) =>
    (
      <RequireRoleConnected requireRoleProps={requireRoleProps}>
        <WrappedComponent {...props} />
      </RequireRoleConnected>
    );
