/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import axios from '../utils/axiosInstance';
import RichTextEditor from 'react-rte';
import { Button, Alert, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as turf from '@turf/turf';
import { convertPrice, propMapApi, handleUnload, setHeader, logPageView } from '../common/actions';
import * as types from '../static/_types';

import 'react-select/dist/react-select.css';
import { RTE_TOOLBAR_CONFIG, PROPERTY_SUBTYPES, PROPERTY_FEATURES } from '../static/_constants';

import { CanView } from '../hoc/CanDo';
import MapContainer from './MapContainer';
import GoogleMapContainer from '../components/google-map';
import Loading from '../components/Loading';
import AlertMessage from '../components/AlertMessage';

const mapEndpoint = propMapApi();
const securityDeposit = 'Security Deposit';
const maintenanceCharges = 'Maintenance Charges';
const leadUpdatedMsg = 'Lead Updated and Property Created';
const wantedRoute = '/wanted/edit-property/:propertyId';
const rwrManagement = '/rwr-management';
const rwrEditPropertyRoute = '/rwr-management/edit-lead/:propertyId';
const addVoiceRoute = '/wanted-voice-leads/voice-lead-form/:leadId';
const editVoiceRoute = '/wanted-voice-leads/edit-voice-lead-form/:propertyId';
const voiceLeadPropertyRoute = '/wanted-voice-leads/edit-voice-lead/:propertyId';
const propertyEndpointUrl = '/api/property';
const voiceLeadsRoute = '/wanted-voice-leads';
const sellRentoutRoute = '/cataloging/sell-rentout-properties';
const propertiesRoute = '/cataloging';
const addPropertiesRoute = '/cataloging/AddProperties';
const cityAreasRoute = '/api/area/city-areas';
class AddProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      setArea: false,
      voiceForm: false,
      isDataChanged: false,
      property: {
        listingType: '',
        type: '',
        purpose: 'sale',
        size_unit: 'sqft',
        city: {},
        phone: '+92',
        area: {},
        agency: {},
        phones: [],
        show_address: false,
        wanted_id: '',
        countryCode: 'pk',
        dialCode: '92',
        ownerCountryCode: 'pk',
        ownerDialCode: '92',
        pocCountryCode: 'pk',
        pocDialCode: '92',
      },
      deletedPhonesId: [],
      description: RichTextEditor.createValueFromString(
        'Contact the agent for more information.',
        'html'
      ),
      isLoadingExternally: false,
      areas: [],
      agencies: [],
      selectedAgencyId: null,
      showUsers: false,
      selectedAgentId: null,
      selectedUserId: null,
      agents: [],
      users: [],
      user: {},
      isLoadingAgents: false,
      isLoadingUsers: false,
      editPropertyPhoneValid: true,
      phoneValid: true,
      phoneCheck: false,
      mapZoom: 8,
      mapCenter: '',
      mapCities: '',
      chosenMapCity: '',
      housingSchemes: '',
      chosenHousingScheme: '',
      phaseSector: '',
      chosenPhaseSector: '',
      blockSubsectors: '',
      chosenBlockSubsector: '',
      mapPlots: '',
      chosenMapPlot: '',
      plotsUnavailable: false,
      userModal: false,
      address: [{ type: '', value: '' }],
      addressType: {
        type: '',
        value: '',
      },
      isManualGeoTag: false,
      baseMapCenter: [33.706782, 73.0531593],
      baseMapZoom: 13,
      chosenStreet: '',
      streets: '',
      filteredPlots: '',
    };
    this.handleType = this.handleType.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddressType = this.handleAddressType.bind(this);
    this.handleAddressInput = this.handleAddressInput.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleUserCheckbox = this.handleUserCheckbox.bind(this);
    this.addProperty = this.addProperty.bind(this);
    this.updateProperty = this.updateProperty.bind(this);
    this.renderGeneralFeatrues = this.renderGeneralFeatrues.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleNewUserCheckbox = this.handleNewUserCheckbox.bind(this);
    this.handleOwnerCheckBox = this.handleOwnerCheckBox.bind(this);
    this.handleExclusiveCheckbox = this.handleExclusiveCheckbox.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
    this.selectedFeatures = {};
  }

  componentDidMount() {
    const { property, description } = this.state;
    const { match, dispatch } = this.props;
    setHeader(dispatch, match.path === addPropertiesRoute ? 'Property Form' : 'Lead Form');
    logPageView(match.path === addPropertiesRoute ? 'Property Form' : 'Lead Form');
    if (
      match.path === wantedRoute ||
      match.path === addVoiceRoute ||
      match.path === editVoiceRoute ||
      match.path === rwrEditPropertyRoute
    ) {
      axios.get(`/api/property/wantedproperty/${match.params.propertyId}`).then((response) => {
        this.setState(
          {
            property: response.data.results,
            ownerPhone: response.data.results.phone,
            voicePropertyId:
              response.data.results.properties && response.data.results.properties.length > 0
                ? response.data.results.properties.map((prop) => prop.id)
                : null,
            ownerName: response.data.results.name,
            description: RichTextEditor.createValueFromString(
              response.data.results.description || '',
              'html'
            ),
            purpose: response.data.results.purpose,
          },
          () => {
            this.setState((prevState) => ({
              wantedArea: prevState.property.wanted_areas[0]
                ? prevState.property.wanted_areas[0].area_id
                : null,
            }));
            this.setState((prevState) => ({
              property: {
                ...prevState.property,
                size: 0,
                phones: [],
              },
            }));
            const { purpose, voicePropertyId } = this.state;
            if (voicePropertyId) {
              axios.get(`/api/property/${voicePropertyId}`).then((responseProp) => {
                this.setState({
                  property: responseProp.data.property,
                  ownerName: responseProp.data.property.owner_name,
                  wantedArea: responseProp.data.property.area_id,
                  cityVoice: responseProp.data.property.city_id,
                  voiceForm: true,
                });
              });
            }
            if (purpose === 'rentout' || purpose === 'rent out') {
              this.setState((prevState) => ({
                property: {
                  ...prevState.property,
                  purpose: 'rent',
                },
              }));
            }
            this.setState({ phoneCheck: true });
            const { cityVoice } = this.state;
            axios
              .get(cityAreasRoute, {
                params: {
                  city_id: cityVoice,
                  all: true,
                  property: true,
                },
              })
              .then((res) => {
                this.setState({
                  isLoadingExternally: false,
                  areasVoice: res.data,
                });
              });
            axios
              .get(cityAreasRoute, {
                params: {
                  city_id: response.data.results.city_id,
                  all: true,
                  property: true,
                },
              })
              .then((res) => {
                this.setState({
                  isLoadingExternally: false,
                  areas: res.data,
                });
              });
          }
        );
      });
      property.wanted_id = match.params.propertyId;
      this.setState({ property });
    }
    property.description = description.toString('html');
    this.setState({
      property,
      description,
    });
    this.getMapCities();
  }

  componentDidUpdate(prevProps) {
    const { wantedSaved, dispatch } = this.props;
    const { voiceForm } = this.state;
    if (wantedSaved !== prevProps.wantedSaved) {
      if (wantedSaved && this.formRef) {
        if (voiceForm) {
          this.updateProperty();
        }
        if (this.formRef.requestSubmit) {
          if (this.formRef.reportValidity()) {
            this.addProperty();
          } else {
            dispatch({
              type: types.SET_PROPERTYERROR,
              payload: true,
            });
          }
        }
      }
    }
  }

  handlePhoneProperty(phone, country) {
    const { property } = this.state;
    this.setState({
      property: {
        ...property,
        countryCode: country.countryCode,
        dialCode: country.dialCode,
        phone,
      },
    });
  }

  handlePhoneOwner(phone, country) {
    const { property } = this.state;
    let { ownerPhone } = this.state;
    property.owner_phone = phone;
    ownerPhone = phone;
    property.ownerCountryCode = country.countryCode;
    property.ownerDialCode = country.dialCode;
    this.setState({
      property: {
        ...property,
      },
      ownerPhone,
    });
    this.setState({ isDataChanged: true });
  }

  handlePhonePoc(phone, country) {
    const { property } = this.state;
    property.poc_phone = phone;
    property.pocCountryCode = country.countryCode;
    property.pocDialCode = country.dialCode;
    this.setState({
      property: {
        ...property,
      },
    });
    this.setState({ isDataChanged: true });
  }

  handleCheckbox(event) {
    const { name, checked } = event.target;
    const { property } = this.state;
    property.lat = null;
    property.lon = null;
    property[name] = checked;
    this.setState({
      property: {
        ...property,
      },
    });
  }

  handleManualGeotagging(status, type) {
    const { property } = this.state;
    if (!status) {
      property.locate_manually = status;
    }
    this.setState({
      property: {
        ...property,
      },
      isManualGeoTag: type !== 'geoTag',
    });
  }

  handleOwnerCheckBox(event) {
    const { checked } = event.target;
    let { sameAsOwner } = this.state;
    sameAsOwner = checked;
    this.setState({ sameAsOwner, isDataChanged: true });
  }

  handleExclusiveCheckbox(event) {
    const { checked } = event.target;
    const { property } = this.state;
    property.exclusiveListing = checked;
    this.setState({ property, isDataChanged: true });
  }

  handleUserCheckbox(event) {
    const { name, checked } = event.target;
    let { showUsers } = this.state;
    const { property } = this.state;
    if (name === 'await_publishing') {
      property.await_publishing = checked;
    } else {
      showUsers = checked;
      property.await_publishing = false;
      property.agency_id = null;
    }
    this.setState({ showUsers, property, isDataChanged: true });
  }

  static getArray(length) {
    return [...Array(length).keys()];
  }

  handleUserPhone(phone) {
    const { user } = this.state;
    user.phone = phone;
    if (user.phone.length > 15) {
      this.setState({ phoneError: true });
    } else {
      this.setState({ phoneError: false });
    }
    this.setState({
      user: {
        ...user,
      },
    });
    this.setState({ isDataChanged: true });
  }

  handleRadio(listingType) {
    const { property } = this.state;
    property.listingType = listingType;

    this.setState({ property });
  }

  handleType(event) {
    const { value, name } = event.target;
    const {
      property,
      property: { type },
    } = this.state;
    property[name] = value;
    this.selectedFeatures = [];
    this.setState({
      property: {
        ...property,
        // UPDATING PROPERTY SUBTYPE ALSO IF TYPE IS CHANGING
        subtype:
          name === 'type' && type !== value ? PROPERTY_SUBTYPES[value][0].value : property.subtype,
      },
    });
  }

  handleInputChange(event) {
    const { value, name } = event.target;
    let { ownerName } = this.state;
    const { bed, bath, gasMeters, electricMeters } = this.state;
    const {
      property,
      property: { type },
    } = this.state;
    if (name === 'owner_name') {
      ownerName = value;
    }
    if (name === 'lat' || name === 'lon') {
      property.locate_manually = true;
    }
    if (name === 'type') {
      this.selectedFeatures = {};
    }
    this.setState({
      bed: null,
      bath: null,
      gasMeters: null,
      electricMeters: null,
    });
    property[name] = value;
    this.setState({
      property: {
        ...property,
        // UPDATING PROPERTY SUBTYPE ALSO IF TYPE IS CHANGING
        subtype:
          name === 'type' && type !== value ? PROPERTY_SUBTYPES[value][0].value : property.subtype,
        bed: name === 'type' ? null : bed,
        bath: name === 'type' ? null : bath,
        gasMeters: name === 'type' ? null : gasMeters,
        electricMeters: name === 'type' ? null : electricMeters,
        gas_meters: null,
        electric_meters: null,
      },
      ownerName,
    });
    this.setState({ isDataChanged: true });
  }

  handleAddressType(event, index) {
    const { address } = this.state;
    const { value } = event.target;
    address[index].type = value;
    this.setState((prevState) => ({
      addressType: {
        ...prevState.addressType,
        type: value,
      },
      address,
    }));
    this.setState({ isDataChanged: true });
  }

  handleAddressInput(event, index) {
    const { address } = this.state;
    const { value } = event.target;
    address[index].value = value;
    address[index].remainingLetters = 65 - value.length;
    this.setState((prevState) => ({
      addressType: {
        ...prevState.addressType,
        value,
      },
      address,
    }));
    this.setState({ isDataChanged: true });
  }

  handleUserInput = (event) => {
    const { value, name } = event.target;
    const { user } = this.state;
    user[name] = value;
    this.setState({ user, isDataChanged: true });
  };

  handleNewUserCheckbox = (event) => {
    const { user } = this.state;
    user[event.target.name] = event.target.checked;
    this.setState({ user, isDataChanged: true });
  };

  handleUserSearch() {
    const { userSearch } = this.state;
    this.setState({ isLoadingUsers: true });
    axios.get(`/api/user/allusers?q=${userSearch}&searchby=name`).then((response) => {
      this.setState(
        {
          isLoadingUsers: false,
          users: response.data,
        },
        () => {
          let { users } = this.state;
          users = users.map((user) => ({
            ...user,
            info: `${user.first_name} ${user.last_name} (ID: ${user.id})`,
          }));
          this.setState({ users, isDataChanged: true });
        }
      );
    });
  }

  getMapCities = async () => {
    const resp = await axios.get(`${mapEndpoint}cities?secure=true`);
    const { data } = resp;
    this.setState({
      mapCities: data,
    });
  };

  getCentroid = (geoJSON) => {
    const poly =
      geoJSON.type === 'Polygon'
        ? turf.polygon(geoJSON.coordinates)
        : turf.multiPolygon(geoJSON.coordinates);
    const centroid = turf.centroid(poly);
    const lon = centroid.geometry.coordinates[0];
    const lat = centroid.geometry.coordinates[1];
    const marker = [lat, lon];
    return marker;
  };

  setStreetValue = () => {
    const { chosenMapCity } = this.state;
    if (chosenMapCity.id === '1') {
      let streets;
      const StreetSuggestion = [];
      const { mapPlots } = this.state;
      streets = mapPlots.filter((item) => item.info !== null);
      streets = [...new Set(mapPlots.map((item) => item.Street))];
      streets.forEach((s) => {
        const obj = {
          value: s,
        };
        StreetSuggestion.push(obj);
      });
      streets = StreetSuggestion.filter((item) => item.value != null);
      this.setState({ streets });
    }
  };

  setMapCity = (city) => {
    const center = this.getCentroid(JSON.parse(city.geoData));
    this.setState({
      chosenMapCity: city,
      housingSchemes: '',
      chosenHousingScheme: '',
      phaseSector: '',
      chosenPhaseSector: '',
      blockSubsectors: '',
      chosenBlockSubsector: '',
      chosenMapPlot: '',
      mapPlots: '',
      mapZoom: 9,
      mapCenter: center,
      filteredPlots: '',
      chosenStreet: '',
      streets: '',
    });
    this.getHousingSchemes(city.id);
    this.setState({ isDataChanged: true });
  };

  getHousingSchemes = async (cityId) => {
    this.setState({ loadingHousing: true });
    const resp = await axios.get(`${mapEndpoint}housing-scheme?cityId=${cityId}&&secure=true`);
    // const data = this.decryptData(resp.data);
    const { data } = resp;
    this.setState({
      housingSchemes: data,
      mapZoom: 10,
      loadingHousing: false,
    });
  };

  setMapHousingScheme = (housingScheme) => {
    const center = this.getCentroid(JSON.parse(housingScheme.geoData));

    this.setState({
      chosenHousingScheme: housingScheme,
      phaseSector: '',
      blockSubsectors: '',
      mapPlots: '',
      mapZoom: 11,
      mapCenter: center,
      filteredPlots: '',
      chosenMapPlot: '',
      chosenStreet: '',
      chosenBlockSubsector: '',
      streets: '',
      chosenPhaseSector: '',
    });

    this.getPhaseSector(housingScheme.id);
    // fetch phase sectors
    this.setState({ isDataChanged: true });
  };

  getPhaseSector = async (housingSchemeId) => {
    this.setState({ loadingPhase: true });
    const resp = await axios.get(
      `${mapEndpoint}phase-sector?housingSchemeId=${housingSchemeId}&&secure=true`
    );
    const { data } = resp;
    this.setState({
      phaseSector: data,
      loadingPhase: false,
    });
    if (data.length <= 0) {
      this.getBlockSubSectorPhase(housingSchemeId);
    }
  };

  setMapPhaseSector = (phaseSector) => {
    const center = this.getCentroid(JSON.parse(phaseSector.geoData));
    this.setState({
      chosenPhaseSector: phaseSector,
      blockSubsectors: '',
      mapPlots: '',
      mapZoom: 14,
      mapCenter: center,
      filteredPlots: '',
      chosenMapPlot: '',
      chosenStreet: '',
      streets: '',
    });

    // fetch block subsectors
    this.getBlockSubSector(phaseSector.id);
    this.setState({ isDataChanged: true });
  };

  getBlockSubSectorPhase = async (housingSchemeId) => {
    const { phaseSector } = this.state;
    const resp = await axios.get(
      `${mapEndpoint}block-subsector-by-housing-scheme?housingSchemeId=${housingSchemeId}&&secure=true`
    );
    const { data } = resp;
    if (!data.length && !phaseSector.length) {
      this.getMapPlotsByHousingScheme(housingSchemeId);
    }

    this.setState({ blockSubsectors: data });
  };

  getBlockSubSector = async (phaseSectorId) => {
    const resp = await axios.get(
      `${mapEndpoint}block-subsector?phaseSectorId=${phaseSectorId}&&secure=true`
    );
    const { data } = resp;

    this.setState({
      blockSubsectors: data,
    });

    if (data.length <= 0) {
      this.getMapPlotsByPhaseSector(phaseSectorId);
    }
  };

  setMapBlockSubSector = (blockSubsector) => {
    const center = this.getCentroid(JSON.parse(blockSubsector.geoData));

    this.setState({
      chosenBlockSubsector: blockSubsector,
      mapZoom: 16,
      mapCenter: center,
    });

    this.getMapPlotsByBlockSubSector(blockSubsector.id);
    this.setState({ isDataChanged: true });
  };

  getMapPlotsByPhaseSector = async (phaseSectorId) => {
    this.setState({ loadingBlock: true });
    const resp = await axios.get(`${mapEndpoint}plots?phaseSectorId=${phaseSectorId}&&secure=true`);
    const { data } = resp;
    if (data.length <= 0) {
      this.setState({ plotsUnavailable: true });
    } else {
      this.setState(
        {
          mapPlots: data,
          mapZoom: 16,
          plotsUnavailable: false,
          loadingBlock: false,
          filteredPlots: data,
        },
        () => {
          let { filteredPlots, mapPlots } = this.state;
          filteredPlots = filteredPlots.map((plot) => ({
            ...plot,
            info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
          }));
          mapPlots = mapPlots.map((plot) => ({
            ...plot,
            info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
          }));
          this.setState({ filteredPlots, mapPlots });
        }
      );
      this.setStreetValue();
    }
  };

  getMapPlotsByBlockSubSector = async (blockSubsectorId) => {
    const resp = await axios.get(
      `${mapEndpoint}plots?blockSubsectorId=${blockSubsectorId}&&secure=true`
    );
    const { data } = resp;
    if (data.length <= 0) {
      this.setState({ plotsUnavailable: true });
    } else {
      this.setState(
        {
          mapPlots: data,
          filteredPlots: data,
          mapZoom: 16,
          plotsUnavailable: false,
        },
        () => {
          let { filteredPlots, mapPlots } = this.state;
          filteredPlots = filteredPlots.map((plot) => ({
            ...plot,
            info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
          }));
          mapPlots = mapPlots.map((plot) => ({
            ...plot,
            info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
          }));
          this.setState({ filteredPlots, mapPlots });
        }
      );
      this.setStreetValue();
    }
  };

  getMapPlotsByHousingScheme = async (housingSchemeId) => {
    const resp = await axios.get(
      `${mapEndpoint}plots?housingSchemeId=${housingSchemeId}&&secure=true`
    );
    const { data } = resp;
    if (data.length <= 0) {
      this.setState({ plotsUnavailable: true });
    } else {
      this.setState(
        {
          mapPlots: data,
          filteredPlots: data,
          mapZoom: 16,
          plotsUnavailable: false,
          loadingBlock: false,
        },
        () => {
          let { filteredPlots, mapPlots } = this.state;
          filteredPlots = filteredPlots.map((plot) => ({
            ...plot,
            info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
          }));
          mapPlots = mapPlots.map((plot) => ({
            ...plot,
            info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
          }));
          this.setState({ filteredPlots, mapPlots });
        }
      );
      this.setStreetValue();
    }
  };

  setMapPlot = (plot) => {
    const center = this.getCentroid(JSON.parse(plot.geoData));
    const { user } = this.props;
    const { property } = this.state;
    property.propsure_id = plot.id;
    if (user) {
      property.geotagged_by = user.email;
    }
    property.platform = 'Graana Admin';
    [property.lat, property.lon] = center;
    this.setState({
      chosenMapPlot: plot,
      mapCenter: center,
      plotMarker: center,
    });
    this.setState({ isDataChanged: true });
  };

  onMapClick = (e) => {
    const { isManualGeoTag } = this.state;
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        propsure_id: null,
      },
    }));
    if (isManualGeoTag) {
      this.setState((prevState) => ({
        property: {
          ...prevState.property,
          lat: e.lat,
          lon: e.lng,
          locate_manually: true,
        },
      }));
    } else {
      // console.log()
    }
  };

  setPropSureId = (plotId, graanaId) => {
    const { user } = this.props;
    axios
      .post(`${mapEndpoint}propsure-plots`, {
        plot_id: plotId,
        propsure_id: graanaId,
        assigned_by: user ? user.email : null,
        geotag_platform: 'Graana Admin',
      })
      .then(() => {
        // console.log('Property updated : ', response);
      });
  };

  setAddFeature(type, value) {
    if (value) {
      this.selectedFeatures[type] = value;
      this.setState((prevState) => ({
        property: {
          ...prevState.property,
          features: this.selectedFeatures,
        },
      }));
    } else {
      delete this.selectedFeatures[type];
    }
    this.setState({ isDataChanged: true });
  }

  setYearBuilt(type, value) {
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        built_year: value,
      },
    }));
    this.setState({ isDataChanged: true });
  }

  setBedBath(type, value) {
    if (type === 'bed') {
      this.setState({ bed: parseInt(value, 10) });
      this.setState((prevState) => ({
        property: {
          ...prevState.property,
          bed: parseInt(value, 10),
        },
      }));
    }
    if (type === 'bath') {
      this.setState({ bath: parseInt(value, 10) });
      this.setState((prevState) => ({
        property: {
          ...prevState.property,
          bath: parseInt(value, 10),
        },
      }));
    }
    this.setState({ isDataChanged: true });
  }

  setGasElectric(type, value) {
    if (type === 'gas') {
      this.setState({ gasMeters: parseInt(value, 10) });
      this.setState((prevState) => ({
        property: {
          ...prevState.property,
          gas_meters: parseInt(value, 10),
        },
      }));
    }
    if (type === 'electric') {
      this.setState({ electricMeters: parseInt(value, 10) });
      this.setState((prevState) => ({
        property: {
          ...prevState.property,
          electric_meters: parseInt(value, 10),
        },
      }));
    }
    this.setState({ isDataChanged: true });
  }

  setFeature(type, value) {
    if (value) {
      if (type === 'year_built' || type === 'parking_space') {
        this.selectedFeatures[type] = parseInt(value, 10);
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            amenities: this.selectedFeatures,
          },
        }));
      }

      if (type === 'floors') {
        this.selectedFeatures[type] = parseInt(value, 10);
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            amenities: this.selectedFeatures,
          },
        }));
      }
      if (type === 'downpayment' || type === 'security_deposit' || type === 'maintenance_charges') {
        this.selectedFeatures[type] = parseInt(value, 10);
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            features: this.selectedFeatures,
          },
        }));
      }
    } else {
      if (type === 'bath') {
        this.setState({ bath: '' });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            bath: null,
          },
        }));
      }
      if (type === 'gas') {
        this.setState({ gasMeters: '' });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            gas_meters: null,
          },
        }));
      }
      if (type === 'electric') {
        this.setState({ electricMeters: '' });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            electric_meters: null,
          },
        }));
      }
      if (type === 'bed') {
        this.setState({ bed: '' });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            bed: null,
          },
        }));
      } else {
        this.forceUpdate();
        delete this.selectedFeatures[type];
      }
    }
    this.setState({ isDataChanged: true });
  }

  static getCity(/* query */) {
    return axios
      .get('/api/city', {
        params: {
          all: true,
        },
      })
      .then((response) => ({ options: response.data.items }));
  }

  setAgentCity(city) {
    this.setState((prevState) => ({
      isLoadingExternally: !!city,
      property: {
        ...prevState.property,
        agency: {
          ...prevState.agency,
          city_id: city ? city.id : null,
        },
      },
    }));

    if (city && city.id) {
      axios
        .get('/api/agency', {
          params: {
            city_id: city.id,
            all: true,
          },
        })
        .then((response) => {
          this.setState(
            {
              isLoadingExternally: false,
              agencies: response.data.items,
            },
            () => {
              let { agencies } = this.state;
              agencies = agencies.map((agency) => ({
                ...agency,
                info: `${agency.name} (ID: ${agency.id})`,
              }));
              this.setState({ agencies });
            }
          );
        });
    }
    this.setState({ isDataChanged: true });
  }

  setCity(city) {
    this.setState({ setArea: true });
    this.setState((prevState) => ({
      isLoadingExternally: !!city,
      areas: [],
      property: {
        ...prevState.property,
        city_id: city ? city.id : null,
        area_id: null,
      },
    }));
    if (city && city.id) {
      this.updateMapCityCenter(city);
      axios
        .get(cityAreasRoute, {
          params: {
            city_id: city.id,
            all: true,
            property: true,
          },
        })
        .then((response) => {
          this.setState({
            isLoadingExternally: false,
            areas: response.data,
          });
        });
    }
    this.setState({ isDataChanged: true });
  }

  setDescription(description) {
    const { property } = this.state;
    property.description = description.toString('html');
    this.setState({
      property,
      description,
    });
    this.setState({ isDataChanged: true });
  }

  setArea(area) {
    const { match } = this.props;
    let cords = null;
    if (
      match.path === wantedRoute ||
      match.path === addVoiceRoute ||
      match.path === editVoiceRoute ||
      match.path === rwrEditPropertyRoute
    ) {
      this.setState({ wantedArea: area ? area.id : null });
    } else {
      this.setState((prevState) => ({
        property: {
          ...prevState.property,
          area_id: area ? area.id : null,
        },
      }));
    }
    if (area && area.id) {
      const { housingSchemes } = this.state;
      if (housingSchemes.length) {
        const selectedArea = housingSchemes.filter(
          (c) =>
            c.housing_scheme_name && c.housing_scheme_name.toLowerCase() === area.name.toLowerCase()
        );
        if (selectedArea.length) {
          const latlon = selectedArea[0].latLon;
          const { coordinates } = latlon;
          cords = coordinates;
          this.setMapHousingScheme(selectedArea[0]);
        }
      }
    }
    this.setState((prevState) => ({
      baseMapCenter: cords && cords.length > 1 ? [cords[1], cords[0]] : prevState.baseMapCenter,
      baseMapZoom: cords && cords.length > 1 ? 13 : prevState.baseMapZoom,
    }));
    this.setState({ isDataChanged: true });
  }

  setAgency(agency) {
    this.fetchAgencyAgents(agency.id);
    this.setState({ selectedAgentId: null, isDataChanged: true });
  }

  setAgents(agent) {
    const { property } = this.state;
    let { countryCode } = this.state;
    property.user_id = agent.id;
    let agentPhone = agent.phone;
    if (agentPhone && (agentPhone.startsWith('92') || agentPhone.startsWith('+92'))) {
      countryCode = 'pk';
    }
    if (agentPhone && countryCode === 'pk') {
      agentPhone = agent.phone.replace(/\s/g, '');
      if (
        agentPhone.charAt(0) !== '+' &&
        agentPhone.charAt(0) !== '9' &&
        agentPhone.charAt(0) !== '0'
      ) {
        agentPhone = `+92${agentPhone}`;
      } else if (agentPhone.includes('9203')) {
        agentPhone = agentPhone.replace('9203', '+923');
      } else if (agentPhone.includes('923') || agentPhone.includes('92 ')) {
        // return agent.phone;
      } else {
        agentPhone = agentPhone.replace('0', '+92');
      }
    }
    if (!agentPhone) {
      agentPhone = '+92';
    }
    this.setState({
      property: {
        ...property,
        phone: agentPhone.startsWith('+0') ? agentPhone.replace(/^.{2}/g, '+92') : agentPhone,
      },
    });
    this.setState({ agentPhone: Math.random() });
    this.setState({ phoneCheck: true });
    this.setState({ selectedAgentId: agent.id });
    this.setState({ isDataChanged: true });
  }

  setUsers(user) {
    const { property } = this.state;
    let { countryCode } = this.state;
    property.user_id = user.id;
    let userPhone = user.phone;
    if (userPhone && (userPhone.startsWith('92') || userPhone.startsWith('+92'))) {
      countryCode = 'pk';
    }
    if (userPhone && countryCode === 'pk') {
      userPhone = user.phone.replace(/\s/g, '');
      if (
        userPhone.charAt(0) !== '+' &&
        userPhone.charAt(0) !== '9' &&
        userPhone.charAt(0) !== '0'
      ) {
        userPhone = `+92${userPhone}`;
      } else if (userPhone.includes('9203')) {
        userPhone = userPhone.replace('9203', '+923');
      } else if (userPhone.includes('923') || userPhone.includes('92 ')) {
        // return agent.phone;
      } else {
        userPhone = userPhone.replace('0', '+92');
      }
    }
    if (!userPhone) {
      userPhone = '+92';
    }
    this.setState({
      property: {
        ...property,
        phone: userPhone,
      },
    });
    this.setState({ userPhone: Math.random() });
    this.setState({ phoneCheck: true });
    this.setState({ selectedUserId: user.id, isDataChanged: true });
  }

  filterPlotsByStreet = (v) => {
    const { mapPlots, chosenMapPlot } = this.state;
    if (chosenMapPlot) {
      this.setState({ chosenMapPlot: '' });
    }
    const plots = mapPlots.filter((i) => i.Street === v.value);
    this.setState({ filteredPlots: plots, chosenStreet: v }, () => {
      let { filteredPlots } = this.state;
      filteredPlots = filteredPlots.map((plot) => ({
        ...plot,
        info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
      }));
      this.setState({ filteredPlots });
    });
  };

  updateMapCityCenter = (city) => {
    const { mapCities } = this.state;
    if (mapCities.length) {
      const selectedCity = mapCities.filter(
        (c) => c.city_name.toLowerCase() === city.name.toLowerCase()
      );
      if (selectedCity.length) {
        const center = this.getCentroid(JSON.parse(selectedCity[0].geoData));
        this.setState({ baseMapCenter: center, baseMapZoom: 10 });
        this.setMapCity(selectedCity[0]);
      }
    }
  };

  getExclusiveBool = () => {
    const { user } = this.props;
    if (user.superadmin) {
      return false;
    }
    if (user.department && user.department.slug === 'am-ops') {
      return false;
    }
    return true;
  };

  fetchAgencyAgents = (agencyId) => {
    const { property } = this.state;
    property.agency_id = agencyId;
    this.setState({ isLoadingAgents: true, selectedAgencyId: agencyId });

    axios.get(`/api/agency/${agencyId}/users`).then((response) => {
      this.setState(
        {
          isLoadingAgents: false,
          agents: response.data,
        },
        () => {
          let { agents } = this.state;
          agents = agents.map((agent) => {
            const newAgent = agent;
            newAgent.full_name = `${newAgent.first_name} ${newAgent.last_name} - (${newAgent.email})`;
            this.setState({ agents });
            return newAgent;
          });
        }
      );
    });
  };

  fetchAgentCreditInfo = (agentId) => {
    axios.get('/api/user', {
      params: {
        agent_id: agentId,
        credit: true,
      },
    });
  };

  setPhoneNumberValue = (phone, index) => {
    const {
      property: { phones },
    } = this.state;
    phones[index].phone = phones[index].phone.replace('-', '');
    phones[index].phone = phones[index].phone.replace(' ', '');
    phones[index].phone = phones[index].phone.split('');
    phones[index].phone = phones[index].phone.join('');
    if (phones[index].phone.charAt(index) !== '+') {
      phones[index].phone = `+${phones[index].phone}`;
    }
    this.setState({ isDataChanged: true });
  };

  setPropertyPhoneValue = (key) => {
    const { property, ownerPhone } = this.state;
    property[key] =
      key === 'owner_phone' ? ownerPhone.replace('-', '') : property[key].replace('-', '');
    property[key] = property[key].replace(' ', '');
    property[key] = property[key].split('');
    property[key] = property[key].join('');
    if (property[key].charAt(0) !== '+') {
      property[key] = `+${property[key]}`;
    }
  };

  getSelectedLatLng = () => {
    const { property } = this.state;
    if (property.lat && property.lon) {
      return (
        <div className="col-md-6 col-sm-6 offset-md-3">
          <div className="alert alert-primary text-center">
            <b>Latitude: </b>
            {`${property.lat} `}
            <b>Longitude: </b>
            {property.lon}
          </div>
        </div>
      );
    }
    return null;
  };

  addMorePhoneNumber() {
    const { property } = this.state;
    const { phones } = property;
    const phoneCount = phones.length + 1;
    if (phoneCount < 3) {
      this.setState({ extraNumber: false });
      phones.push('');
      this.setState({ property });
    } else {
      this.setState({ extraNumber: true }, () => {
        window.scrollTo(0, this.extraNumberRef.offsetTop);
      });
    }
    this.setState({ isDataChanged: true });
  }

  addAddressField(index) {
    const { address, addressType } = this.state;
    if (address[index] && address[index].type) {
      address.push(addressType);
      this.setState({ address });
      address[address.length - 1].type = '';
      address[address.length - 1].value = '';
      // document.getElementById(index + 1).style.display = 'none';
      // document.getElementById(index + 7).style.display = 'block';
    } else {
      window.alert('Fill previous field first');
    }
    // const test = JSON.stringify(address);
    this.setState({ isDataChanged: true });
  }

  removeAddressField(index) {
    const { address } = this.state;
    address.splice(index, 1);
    this.setState({ address, isDataChanged: true });
    // document.getElementById(index + 1).style.display = 'block';
    // document.getElementById(index + 7).style.display = 'none';
  }

  removePhoneNumber(index, contact) {
    const { property, deletedPhonesId } = this.state;
    const { phones } = property;
    this.setState({ removeKey: Math.random() });
    deletedPhonesId.push(contact.id);
    phones.splice(index, 1);
    this.setState({ property, deletedPhonesId });
    this.setState({ extraNumber: false, isDataChanged: true });
  }

  changePhoneNumber(value, index, country) {
    const { property } = this.state;
    const { phones } = property;
    if (phones[index].id) {
      phones[index].phone = value;
    } else {
      phones[index] = {
        phone: value,
        countryCode: country.countryCode,
        dialCode: country.dialCode,
      };
    }
    this.setState({ property, isDataChanged: true });
  }

  createUser(event) {
    event.preventDefault();
    const { loading, user, phoneError } = this.state;
    if (!loading) {
      this.setState({
        loading: true,
      });
      if (!phoneError) {
        axios
          .post('/api/user/', user)
          .then((response) => {
            if (response) {
              window.alert('User created successfully');
            }
            this.setState({
              loading: false,
              userModal: false,
            });
          })
          .catch((error) => {
            if (error.response.data === 'Email already exists') {
              window.alert('User already exists');
            }
            this.setState({
              loading: false,
            });
          });
      } else {
        window.alert('Enter valid phone number');
        this.setState({ loading: false });
      }
    }
  }

  updateProperty(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ voiceForm: false }, () => {
      const { history } = this.props;
      const {
        loading,
        property,
        deletedPhonesId,
        customer,
        sameAsOwner,
        logIds,
        voicePropertyId,
        // wantedUserId,
        // wantedUserId,
        // deleteLogs,
        // wantedUserId,
      } = this.state;
      let { address, propsureId } = this.state;
      const aLength = address.length;
      if (address[aLength - 1] && address[aLength - 2]) {
        if (address[aLength - 1].type === address[aLength - 2].type) {
          address.pop();
        }
      }
      address = address.filter((ad) => ad.value);
      property.categorized_address = address;
      const { phones } = property;
      if (property.phone) {
        property.phone = property.phone.replace('-', '');
        property.phone = property.phone.replace(' ', '');
        property.phone = property.phone.split('');
        property.phone = property.phone.join('');
        if (property.phone.charAt(0) !== '+') {
          property.phone = `+${property.phone}`;
        }
      }
      if (property.countryCode === 'pk') {
        if (property.phone[0] !== '+') {
          property.phone = property.phone.split('');
          property.phone.splice(0, 1, '+9');
          property.phone = property.phone.join('');
        }
      }
      if (property.phones) {
        if (property.phones[1] && !property.phones[1].phone) {
          deletedPhonesId.push(property.phones[1].id);
          property.phones.splice(1, 2);
        }
        if (property.phones[0] && !property.phones[0].phone) {
          deletedPhonesId.push(property.phones[0].id);
          property.phones.splice(0, 1);
        }
      }
      if (property.phone) {
        property.phone = property.phone.replace('-', '');
        property.phone = property.phone.replace(' ', '');
        property.phone = property.phone.split('');
        property.phone = property.phone.join('');
        if (property.phone.charAt(0) !== '+') {
          property.phone = `+${property.phone}`;
        }
      }
      if (property.owner_phone) {
        property.owner_phone = property.owner_phone.replace('-', '');
        property.owner_phone = property.owner_phone.replace(' ', '');
        property.owner_phone = property.owner_phone.split('');
        property.owner_phone = property.owner_phone.join('');
        if (property.owner_phone.charAt(0) !== '+') {
          property.owner_phone = `+${property.owner_phone}`;
        }
      }
      if (property.poc_phone) {
        property.poc_phone = property.poc_phone.replace('-', '');
        property.poc_phone = property.poc_phone.replace(' ', '');
        property.poc_phone = property.poc_phone.split('');
        property.poc_phone = property.poc_phone.join('');
        if (property.poc_phone.charAt(0) !== '+') {
          property.poc_phone = `+${property.poc_phone}`;
        }
      }
      if (phones && phones.length === 1) {
        if (phones[0].phone) {
          phones[0].phone = phones[0].phone.replace('-', '');
          phones[0].phone = phones[0].phone.replace(' ', '');
          phones[0].phone = phones[0].phone.split('');
          phones[0].phone = phones[0].phone.join('');
          if (phones[0].phone.charAt(0) !== '+') {
            phones[0].phone = `+${phones[0].phone}`;
          }
        }
      }
      if (phones && phones.length === 2) {
        if (phones[0].phone) {
          phones[0].phone = phones[0].phone.replace('-', '');
          phones[0].phone = phones[0].phone.replace(' ', '');
          phones[0].phone = phones[0].phone.split('');
          phones[0].phone = phones[0].phone.join('');
          if (phones[0].phone.charAt(0) !== '+') {
            phones[0].phone = `+${phones[0].phone}`;
          }
        }
        if (phones[1].phone) {
          phones[1].phone = phones[1].phone.replace('-', '');
          phones[1].phone = phones[1].phone.replace(' ', '');
          phones[1].phone = phones[1].phone.split('');
          phones[1].phone = phones[1].phone.join('');
          if (phones[1].phone.charAt(0) !== '+') {
            phones[1].phone = `+${phones[1].phone}`;
          }
        }
      }
      if (sameAsOwner) {
        property.poc_name = property.owner_name;
        property.poc_phone = property.owner_phone;
        this.setState({ property });
      }
      property.propsure_id = propsureId;
      if (property.locate_manually) {
        property.propsure_id = null;
        propsureId = null;
      }
      const updatedProperty = {
        ...property,
        amenities: this.selectedFeatures,
        phones,
        area_id: property.area_id
          ? property.area_id
          : property.wantedAreas && property.wanted_areas.map((areas) => areas.area_id),
        deletedPhonesId,
        features: null,
        customer,
        logId: logIds,
      };
      if (!loading) {
        this.setState({ loading: true });
        if (phones && phones.length >= 1) {
          if (phones.length === 1) {
            if (phones[0].phone) {
              if (phones[0].phone.length > 10 && property.phone.length > 10) {
                axios
                  .put(`/api/property/${voicePropertyId}`, updatedProperty)
                  .then((/* response */) => {
                    this.setPropSureId(propsureId, voicePropertyId);
                    history.push({
                      pathname: voiceLeadsRoute,
                      state: { message: 'submit' },
                    });
                  });
              } else {
                this.setState({ loading: false });
                this.setState({ editPropertyPhoneValid: false }, () => {
                  window.scrollTo(0, this.additionalNumberRef.offsetTop);
                });
              }
            } else {
              this.setState({ loading: false });
              this.setState({ editPropertyPhoneValid: false }, () => {
                window.scrollTo(0, this.additionalNumberRef.offsetTop);
              });
            }
          } else if (phones.length === 2) {
            if (phones[0].phone && phones[1].phone) {
              if (
                phones[0].phone.length > 10 &&
                phones[1].phone.length > 10 &&
                property.phone.length > 10
              ) {
                axios
                  .put(`/api/property/${voicePropertyId}`, updatedProperty)
                  .then((/* response */) => {
                    this.setPropSureId(propsureId, voicePropertyId);
                    history.push({
                      pathname: voiceLeadsRoute,
                      state: { message: 'submit' },
                    });
                  });
              } else {
                this.setState({ loading: false });
                this.setState({ editPropertyPhoneValid: false }, () => {
                  window.scrollTo(0, this.additionalNumberRef.offsetTop);
                });
              }
            } else {
              this.setState({ loading: false });
              this.setState({ editPropertyPhoneValid: false }, () => {
                window.scrollTo(0, this.additionalNumberRef.offsetTop);
              });
            }
          }
        } else if (property.phone && property.phone.length > 10) {
          axios.put(`/api/property/${voicePropertyId}`, updatedProperty).then((/* response */) => {
            this.setPropSureId(propsureId, voicePropertyId);
            history.push({
              pathname: voiceLeadsRoute,
              state: { message: 'submit' },
            });
          });
        } else {
          this.setState({ loading: false });
          this.setState({ editPropertyPhoneValid: false }, () => {
            window.scrollTo(0, this.additionalNumberRef.offsetTop);
          });
        }
      }
      this.setState({ voicePropertyId: null });
    });
    this.setState({ isDataChanged: false });
  }

  saveWantedProperty(property) {
    const { wantedSaved, leadPropertyPayload, match, history } = this.props;
    axios.put(`/api/property/update-wanted/${wantedSaved}`, leadPropertyPayload).then(() => {
      axios.post(propertyEndpointUrl, property).then((response) => {
        this.setPropSureId(property.propsure_id, response.data.id);
        if (wantedSaved) {
          if (match.path !== voiceLeadPropertyRoute && match.path !== addVoiceRoute) {
            window.alert(leadUpdatedMsg);
          }
          if (match.path === voiceLeadPropertyRoute) {
            history.push(voiceLeadsRoute);
          } else if (match.path === addVoiceRoute || match.path === wantedRoute) {
            history.push('/wanted');
          } else if (match.path === rwrEditPropertyRoute) {
            history.push(rwrManagement);
          }
        } else {
          history.push(sellRentoutRoute);
        }
      });
    });
  }

  addProperty(event) {
    if (event) {
      event.preventDefault();
    }
    const { match, history, wantedSaved, dispatch } = this.props;
    const { loading, property, wantedArea, sameAsOwner, ownerPhone, ownerName, voicePropertyId } =
      this.state;
    const { phones } = property;
    let { countryCode } = this.state;
    let { address } = this.state;
    const aLength = address.length;
    if (address[aLength - 1] && address[aLength - 2]) {
      if (address[aLength - 1].type === address[aLength - 2].type) {
        address.pop();
      }
    }
    address = address.filter((ad) => ad.value);
    property.categorized_address = address;
    if (property.phone) {
      if (property.phone.startsWith('92') || property.phone.startsWith('+92')) {
        countryCode = 'pk';
      }
    }
    if (
      match.path !== wantedRoute &&
      match.path !== addVoiceRoute &&
      match.path !== editVoiceRoute &&
      match.path !== rwrEditPropertyRoute
    ) {
      property.wanted_id = undefined;
      this.setState({ property });
    }
    if (phones.length) {
      phones.forEach((phone, index) => {
        this.setPhoneNumberValue(phone, index);
      });
    }
    if (property.phone && countryCode === 'pk') {
      this.setPropertyPhoneValue('phone');
    }
    if (ownerPhone) {
      this.setPropertyPhoneValue('owner_phone');
    }
    if (property.poc_phone) {
      this.setPropertyPhoneValue('poc_phone');
    }
    property.owner_name = ownerName;
    if (sameAsOwner) {
      property.poc_name = property.owner_name;
      property.poc_phone = property.owner_phone;
      this.setState({ property });
    }
    if (!loading) {
      if (match.path === addPropertiesRoute) {
        if (phones && phones.length >= 1) {
          if (phones.length === 1) {
            if (phones[0].phone) {
              if (phones[0].phone.length > 12) {
                this.setState({ loading: true });
                if (voicePropertyId) {
                  axios
                    .put(`${propertyEndpointUrl}/${voicePropertyId}`, property)
                    .then((response) => {
                      this.setPropSureId(property.propsure_id, response.data.id);
                      history.push(
                        match.path === voiceLeadPropertyRoute || match.path === addVoiceRoute
                          ? voiceLeadsRoute
                          : '/wanted'
                      );
                    });
                } else {
                  axios.post(propertyEndpointUrl, property).then((response) => {
                    this.setPropSureId(property.propsure_id, response.data.id);
                    history.push(propertiesRoute);
                  });
                }
              } else {
                this.setState({ loading: false });
                this.setState({ editPropertyPhoneValid: false }, () => {
                  window.scrollTo(0, this.additionalNumberRef.offsetTop);
                });
              }
            } else {
              this.setState({ loading: false });
              this.setState({ editPropertyPhoneValid: false }, () => {
                window.scrollTo(0, this.additionalNumberRef.offsetTop);
              });
            }
          } else if (phones.length === 2) {
            if (phones[0].phone && phones[1].phone) {
              if (phones[0].phone.length > 12 && phones[1].phone.length > 12) {
                this.setState({ loading: true });
                if (voicePropertyId) {
                  axios
                    .put(`${propertyEndpointUrl}/${voicePropertyId}`, property)
                    .then((response) => {
                      this.setPropSureId(property.propsure_id, response.data.id);
                      history.push(propertiesRoute);
                    });
                } else {
                  axios.post(propertyEndpointUrl, property).then((response) => {
                    this.setPropSureId(property.propsure_id, response.data.id);
                    history.push(
                      match.path === voiceLeadPropertyRoute || match.path === addVoiceRoute
                        ? voiceLeadsRoute
                        : '/wanted'
                    );
                  });
                }
              }
            } else {
              this.setState({ loading: false });
              this.setState({ editPropertyPhoneValid: false }, () => {
                window.scrollTo(0, this.additionalNumberRef.offsetTop);
              });
            }
          }
        } else if (countryCode !== 'pk') {
          this.setState({ loading: true });
          this.setState({ phoneValid: true });
          if (voicePropertyId) {
            axios.put(`${propertyEndpointUrl}/${voicePropertyId}`, property).then((response) => {
              this.setPropSureId(property.propsure_id, response.data.id);
              history.push(
                match.path === voiceLeadPropertyRoute || match.path === addVoiceRoute
                  ? voiceLeadsRoute
                  : '/wanted'
              );
            });
          } else {
            axios.post(propertyEndpointUrl, property).then((response) => {
              this.setPropSureId(property.propsure_id, response.data.id);
              history.push(propertiesRoute);
            });
          }
        } else if (property.phone.length > 12) {
          this.setState({ loading: true });
          this.setState({ phoneValid: true });
          if (voicePropertyId) {
            axios.put(`${propertyEndpointUrl}/${voicePropertyId}`, property).then((response) => {
              this.setPropSureId(property.propsure_id, response.data.id);
              history.push(
                match.path === voiceLeadPropertyRoute || match.path === addVoiceRoute
                  ? voiceLeadsRoute
                  : '/wanted'
              );
            });
          } else {
            axios.post(propertyEndpointUrl, property).then((response) => {
              this.setPropSureId(property.propsure_id, response.data.id);
              history.push(propertiesRoute);
            });
          }
        } else {
          this.setState({ loading: false });
          this.setState({ phoneValid: false }, () => {
            window.scrollTo(0, this.phoneRef.offsetTop);
          });
        }
      } else if (
        match.path === wantedRoute ||
        match.path === addVoiceRoute ||
        match.path === editVoiceRoute ||
        match.path === rwrEditPropertyRoute
      ) {
        property.wanted_areas = undefined;
        property.added_by_armsroleid = undefined;
        property.added_by_armsuser_id = undefined;
        property.added_by_organization = undefined;
        property.zone_id = undefined;
        property.id = undefined;
        property.created_at = undefined;
        property.area_id = wantedArea;
        property.readyForCataloguer = false;
        if (match.path === addVoiceRoute || match.path === editVoiceRoute) {
          property.wanted_id = wantedSaved;
        } else {
          property.wanted_id = match.params.propertyId;
        }
        if (property.purpose === 'buy' || property.purpose === 'sell') {
          property.purpose = 'sale';
        }
        if (property.purpose === 'rentout' || property.purpose === 'rent out') {
          property.purpose = 'rent';
        }
        if (!property.size_unit) {
          property.size_unit = 'sqft';
        }
        this.setState({ property });
        if (phones && phones.length >= 1) {
          if (phones.length === 1) {
            if (phones[0].phone) {
              if (phones[0].phone.length > 12) {
                this.setState({ loading: true });
                if (voicePropertyId) {
                  axios
                    .put(`${propertyEndpointUrl}/${voicePropertyId}`, property)
                    .then((response) => {
                      this.setPropSureId(property.propsure_id, response.data.id);
                      if (match.path === voiceLeadPropertyRoute) {
                        history.push(voiceLeadsRoute);
                      } else if (match.path === addVoiceRoute) {
                        history.push('/wanted');
                      } else if (match.path === rwrEditPropertyRoute) {
                        history.push(rwrManagement);
                      }
                    });
                } else {
                  this.saveWantedProperty(property);
                }
              } else {
                this.setState({ loading: false });
                this.setState({ editPropertyPhoneValid: false }, () => {
                  window.scrollTo(0, this.additionalNumberRef.offsetTop);
                });
              }
            } else {
              this.setState({ loading: false });
              this.setState({ editPropertyPhoneValid: false }, () => {
                window.scrollTo(0, this.additionalNumberRef.offsetTop);
              });
            }
          } else if (phones.length === 2) {
            if (phones[0].phone && phones[1].phone) {
              if (phones[0].phone.length > 12 && phones[1].phone.length > 12) {
                this.setState({ loading: true });
                if (voicePropertyId) {
                  axios
                    .put(`${propertyEndpointUrl}/${voicePropertyId}`, property)
                    .then((response) => {
                      this.setPropSureId(property.propsure_id, response.data.id);
                      if (match.path === voiceLeadPropertyRoute) {
                        history.push(voiceLeadsRoute);
                      } else if (match.path === addVoiceRoute) {
                        history.push('/wanted');
                      } else if (match.path === rwrEditPropertyRoute) {
                        history.push(rwrManagement);
                      }
                    });
                } else {
                  this.saveWantedProperty(property);
                }
              }
            } else {
              this.setState({ loading: false });
              this.setState({ editPropertyPhoneValid: false }, () => {
                window.scrollTo(0, this.additionalNumberRef.offsetTop);
              });
            }
          }
        } else if (
          (property.phone && property.phone.length > 12) ||
          match.path === addVoiceRoute ||
          match.path === editVoiceRoute
        ) {
          this.setState({ loading: true });
          this.setState({ phoneValid: true });
          if (voicePropertyId) {
            axios.put(`${propertyEndpointUrl}/${voicePropertyId}`, property).then((response) => {
              this.setPropSureId(property.propsure_id, response.data.id);
              if (match.path === voiceLeadPropertyRoute) {
                history.push(voiceLeadsRoute);
              } else if (match.path === addVoiceRoute) {
                history.push('/wanted');
              } else if (match.path === rwrEditPropertyRoute) {
                history.push(rwrManagement);
              }
            });
          } else {
            this.saveWantedProperty(property);
          }
        } else if (
          (match.path === wantedRoute || match.path === rwrEditPropertyRoute) &&
          property.phone &&
          property.phone.length <= 12
        ) {
          dispatch({
            type: types.SET_PROPERTYERROR,
            payload: true,
          });
          this.setState({ loading: false });
          this.setState({ phoneValid: false }, () => {
            window.scrollTo(0, this.phoneRef.offsetTop);
          });
        } else {
          this.setState({ loading: false });
          this.setState({ phoneValid: false }, () => {
            window.scrollTo(0, this.phoneRef.offsetTop);
          });
        }
      }
    }
    this.setState({ isDataChanged: false });
  }

  renderGeneralFeatrues() {
    const { property } = this.state;
    const { type } = property;
    const { bed, bath, gasMeters, electricMeters } = this.state;
    if (!type || !PROPERTY_FEATURES[type] || !PROPERTY_FEATURES[type].general) {
      return null;
    }
    const renderFeatures = [];
    let rentalFeatures = [];
    let option;
    let renderIndex;
    let featureLabel;
    let key;
    Object.keys(PROPERTY_FEATURES[type].general).forEach((feature, index, array) => {
      if (feature === 'year_built') {
        option = (
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Year Built</label>
            <div className="col-md-6 col-sm-6" key={feature}>
              <select
                name="year_built"
                className="form-select block custom-select"
                value={property.built_year}
                onChange={(event) => this.setYearBuilt(event.target.name, event.target.value)}
              >
                <option value="">Year Built</option>
                {new Array(100).fill(undefined).map((val, id) => (
                  <option key={`${moment().year() - id}`} value={moment().year() - id}>
                    {moment().year() - id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      } else if (feature === 'bed') {
        option = (
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Bed</label>
            <div className="col-md-6 col-sm-6" key={feature}>
              <select
                name="bed"
                className="form-select custom-select"
                value={bed}
                onChange={(event) => this.setBedBath(event.target.name, event.target.value)}
              >
                <option value="">Any</option>
                {new Array(30).fill(undefined).map((val, id) => (
                  <option key={`bedoption ${id + 1}`} value={1 + id}>
                    {1 + id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      } else if (feature === 'gas') {
        option = (
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Gas Meters</label>
            <div className="col-md-6 col-sm-6" key={feature}>
              <select
                name="gas"
                className="form-select custom-select"
                value={gasMeters}
                onChange={(event) => this.setGasElectric(event.target.name, event.target.value)}
              >
                <option value="">Any</option>
                {new Array(30).fill(undefined).map((val, id) => (
                  <option key={`gasoption ${id + 1}`} value={1 + id}>
                    {1 + id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      } else if (feature === 'electric') {
        option = (
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Electric Meters</label>
            <div className="col-md-6 col-sm-6" key={feature}>
              <select
                name="electric"
                className="form-select custom-select"
                value={electricMeters}
                onChange={(event) => this.setGasElectric(event.target.name, event.target.value)}
              >
                <option value="">Any</option>
                {new Array(30).fill(undefined).map((val, id) => (
                  <option key={`electricoption ${id + 1}`} value={1 + id}>
                    {1 + id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      } else if (feature === 'bath') {
        option = (
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Bath</label>
            <div className="col-md-6 col-sm-6" key={feature}>
              <select
                name="bath"
                className="form-select custom-select"
                value={bath}
                onChange={(event) => this.setBedBath(event.target.name, event.target.value)}
              >
                <option value="">Any</option>
                {new Array(30).fill(undefined).map((val, id) => (
                  <option key={`bathoption ${id + 1}`} value={1 + id}>
                    {1 + id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      } else {
        featureLabel = PROPERTY_FEATURES[type].general[feature].label;
        key = PROPERTY_FEATURES[type].general[feature].id;
        option = (
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">{featureLabel}</label>
            <div className="col-md-6 col-sm-6" key={feature}>
              <select
                name={key}
                className="form-select custom-select"
                value={this.selectedFeatures[key]}
                onChange={(event) => this.setFeature(event.target.name, event.target.value)}
              >
                <option value="">{featureLabel}</option>
                {new Array(10).fill(undefined).map((val, id) => (
                  <option key={`feature ${id + 1}`} value={1 + id}>
                    {1 + id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      }
      renderIndex = array.length / 2 > index ? 0 : 1;
      if (renderFeatures[renderIndex]) {
        renderFeatures[renderIndex].push(option);
      } else {
        renderFeatures[renderIndex] = [option];
      }
    });
    if (property.purpose === 'rent') {
      rentalFeatures = PROPERTY_FEATURES.rentalOptions.map((item, index) => (
        <div className="form-group row" key={`feature ${index + 1}`}>
          <label className="control-label col-md-3 col-sm-3">{item.label}</label>
          <div
            className={`col-md-6 col-sm-6 ${
              item.label === securityDeposit ||
              item.label === 'Downpayment' ||
              item.label === maintenanceCharges
                ? 'price-label-container'
                : ''
            }`}
          >
            <input
              className="form-control"
              type="number"
              name={item.name}
              display="price"
              onWheel={(e) => e.target.blur()}
              placeholder="Price in (PKR)"
              value={this.selectedFeatures[item.name]}
              onChange={(event) => this.setFeature(event.target.name, event.target.value)}
              min={1}
            />
            {(item.label === securityDeposit ||
              item.label === 'Downpayment' ||
              item.label === maintenanceCharges) &&
            this.selectedFeatures[item.name] >= 1000 ? (
              <span className="price-label">
                {convertPrice(this.selectedFeatures[item.name], 'PKR')}
              </span>
            ) : null}
          </div>
        </div>
      ));
    }

    return (
      <div>
        {renderFeatures[0]}
        <div>{renderFeatures[1]}</div>
        <div>{rentalFeatures}</div>
      </div>
    );
  }

  renderAdditionalFeatures() {
    const { property } = this.state;
    const { type } = property;
    if (!type || !PROPERTY_FEATURES[type]) {
      return null;
    }
    const options = [];
    let optionIndex;
    let elem;
    PROPERTY_FEATURES[type].features.options.map((option, index) => {
      elem = (
        <div className="">
          <label key={`feature ${Math.random()} ${index + 1}`}>
            <input
              type="checkbox"
              name={option.replace(/ /g, '_')}
              defaultChecked={this.selectedFeatures[option.replace(/ /g, '_')] || false}
              onChange={(event) => this.setAddFeature(event.target.name, event.target.checked)}
            />{' '}
            {option.replace(/\b\w/g, (l) => l.toUpperCase())}
          </label>
        </div>
      );

      optionIndex = index < 12 ? 0 : 1;
      if (options[optionIndex]) {
        options[optionIndex].push(elem);
      } else {
        options[optionIndex] = [elem];
      }
      return true;
    });
    return (
      <div className="">
        <div className="">
          <h5 className="gray">{PROPERTY_FEATURES[type].features.title}</h5>
          <div className="row">
            <div className="col-sm-6">{options[0]}</div>
            <div className="col-sm-6">{options[1]}</div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <h5 className="gray">Utilities</h5>

              {PROPERTY_FEATURES[type].utilities.map((utility, index) => (
                <div className="row">
                  <div className="col-sm-12">
                    <label key={`feature ${Math.random()} ${index + 1}`}>
                      <input
                        type="checkbox"
                        name={utility}
                        defaultChecked={this.selectedFeatures[utility.replace(/ /g, '_')] || false}
                        onChange={(event) =>
                          this.setAddFeature(event.target.name, event.target.checked)
                        }
                      />{' '}
                      {utility.replace(/\b\w/g, (l) => l.toUpperCase())}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-sm-12 no-padding">
            <h5 className="gray">Facing</h5>
            <div className="row">
              {[
                'north',
                'north_west',
                'north_east',
                'south',
                'south_west',
                'south_east',
                'east',
                'west',
              ].map((amenity, index) => (
                <div className="col-sm-6">
                  <label key={`feature ${Math.random()} ${index + 1}`}>
                    <input
                      type="checkbox"
                      className="marginFix"
                      defaultChecked={this.selectedFeatures[amenity] || false}
                      onChange={(event) =>
                        this.setAddFeature(event.target.name, event.target.checked)
                      }
                      name={amenity}
                      special={`${amenity.replace(/\b\w/g, (l) => l.toUpperCase())}`}
                    />
                    {amenity
                      .split('_')
                      .join(' ')
                      .replace(/\b\w/g, (l) => l.toUpperCase())}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGeotagButtons() {
    const { property, isManualGeoTag } = this.state;
    return (
      <div className="form-group row">
        <div className="col-md-6 col-sm-6 text-end" style={{ paddingRight: 0, paddingBottom: 5 }}>
          <button
            type="button"
            className={`btn btn-outline-primary ${
              !property.locate_manually && !isManualGeoTag ? 'active' : ''
            }`}
            onClick={() => this.handleManualGeotagging(false, 'geoTag')}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            PropSure Geotagging
          </button>
        </div>
        <div className="col-md-6 col-sm-6" style={{ paddingLeft: 0, paddingBottom: 5 }}>
          <button
            type="button"
            className={`btn btn-outline-primary ${
              property.locate_manually || isManualGeoTag ? 'active' : ''
            }`}
            onClick={() => this.handleManualGeotagging(true, 'manual')}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            Manual Geotagging
          </button>
        </div>
      </div>
    );
  }

  render() {
    let isWantedProperty = false;
    let isWanted;
    let wantedPropertyAreas = '';
    const { match } = this.props;
    const { property, extraNumber, removeKey, editPropertyPhoneValid } = this.state;
    const { type } = property;
    const {
      loading,
      description,
      isLoadingExternally,
      isLoadingAgents,
      areas,
      agencies,
      agents,
      selectedAgentId,
      selectedAgencyId,
      agentPhone,
      userPhone,
      phoneValid,
      sameAsOwner,
      phoneCheck,
      wantedArea,
      mapZoom,
      mapCenter,
      mapCities,
      chosenMapCity,
      housingSchemes,
      chosenHousingScheme,
      phaseSector,
      chosenPhaseSector,
      blockSubsectors,
      chosenBlockSubsector,
      mapPlots,
      chosenMapPlot,
      plotMarker,
      plotsUnavailable,
      users,
      selectedUserId,
      isLoadingUsers,
      showUsers,
      userSearch,
      userModal,
      address,
      ownerPhone,
      ownerName,
      loadingHousing,
      loadingPhase,
      loadingBlock,
      isManualGeoTag,
      chosenStreet,
      streets,
      filteredPlots,
      voicePropertyId,
      areasVoice,
      isDataChanged,
      setArea,
    } = this.state;
    if (match.path === propertiesRoute) {
      isWantedProperty = true;
    }
    if (
      match.path === wantedRoute ||
      match.path === addVoiceRoute ||
      match.path === editVoiceRoute ||
      match.path === rwrEditPropertyRoute
    ) {
      isWanted = true;
    }
    if (isWantedProperty && property && property.wanted_areas) {
      property.wanted_areas.map((area) => {
        wantedPropertyAreas = wantedPropertyAreas.concat(area.area.name, ', ');
        return area;
      });
    }
    // let wantedArea;
    // if (isWanted && property && property.wanted_areas) {
    //   wantedArea = property.wanted_areas[0].area_id;
    // }
    if (wantedPropertyAreas) {
      wantedPropertyAreas = wantedPropertyAreas.slice(0, -2);
    }
    return (
      <div className="row animated fadeIn">
        {isDataChanged
          ? window.addEventListener('beforeunload', handleUnload)
          : window.removeEventListener('beforeunload', handleUnload)}
        <AlertMessage isDataChanged={isDataChanged} />
        <Modal
          size="md"
          isOpen={userModal}
          toggle={() => {
            this.setState({ userModal: false });
          }}
        >
          <ModalHeader
            toggle={() => {
              this.setState({ userModal: false });
            }}
          >
            <div className="gray">Sign Up</div>
          </ModalHeader>
          <ModalBody>
            <form
              onSubmit={(event) => {
                this.createUser(event);
              }}
            >
              <div>
                <input
                  className="modalInput"
                  type="text"
                  name="first_name"
                  placeholder="First name*"
                  required
                  onChange={this.handleUserInput}
                  pattern="[a-zA-Z][a-zA-Z ]*"
                  title="This field cannot start with an empty space or include a number."
                />
                <input
                  className="modalInput"
                  type="text"
                  name="last_name"
                  placeholder="Last name*"
                  required
                  onChange={this.handleUserInput}
                  pattern="[a-zA-Z][a-zA-Z ]*"
                  title="This field cannot start with an empty space or include a number."
                />
                <input
                  className="modalInput"
                  type="email"
                  name="email"
                  placeholder="Email Address*"
                  required
                  onChange={this.handleUserInput}
                  autoComplete="off"
                />
                <input
                  className="modalInput"
                  type="password"
                  name="password"
                  placeholder="Password*"
                  required
                  onChange={this.handleUserInput}
                  autoComplete="new-password"
                />
                <label>
                  <input
                    type="checkbox"
                    name="is_propsure_user"
                    onChange={this.handleNewUserCheckbox}
                  />{' '}
                  Propsure User
                </label>
                <PhoneInput
                  name="phone"
                  country="pk"
                  inputStyle={{ width: '100%' }}
                  enableLongNumbers
                  onChange={(phone) => this.handleUserPhone(phone)}
                  required
                />
                <h5 style={{ marginTop: '10px' }}>Birthday</h5>
                <div className="row space-2">
                  <div className="col-5">
                    <select
                      name="month"
                      className="form-select custom-select"
                      onChange={this.handleUserInput}
                      required
                    >
                      <option value="this.state.user.month">Month</option>
                      {[
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ].map((month, index) => (
                        <option key={month} value={index}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3 no-padding">
                    <select
                      name="day"
                      className="form-select custom-select"
                      onChange={this.handleUserInput}
                      required
                    >
                      <option value="user.day">Day</option>
                      {AddProperties.getArray(31).map((val, index) => (
                        <option key={val} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-4">
                    <select
                      name="year"
                      className="form-select custom-select"
                      onChange={this.handleUserInput}
                      required
                    >
                      <option value="this.state.user.year">Year</option>
                      {AddProperties.getArray(100).map((val, index) => (
                        <option key={val} value={2016 - index}>
                          {2016 - index}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <label>
                  <input type="checkbox" /> I’d like to receive coupons, promotions, surveys, and
                  updates via email about Graana and its partners.
                </label>
                <Button
                  className={`btn btn-block btn-xlg btn-graana-red space-1 ${
                    loading ? 'disabled' : ''
                  }`}
                >
                  <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} /> Sign up
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
        <div className="col-12">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="x_panel">
                <div className="x_title">
                  <h2>Add Agent/User Details</h2>
                  <div className="clearfix" />
                </div>
                <br />
                <br />
                <form
                  id="demo-form-add"
                  className="form-horizontal form-label-left"
                  onSubmit={voicePropertyId ? this.updateProperty : this.addProperty}
                  ref={(r) => {
                    this.formRef = r;
                  }}
                >
                  <div>
                    <div className="form-group row">
                      <div
                        className="col-md-1 col-sm-1 offset-md-3"
                        style={{ marginRight: '-57px' }}
                      >
                        <input
                          type="checkbox"
                          name="showUsers"
                          checked={showUsers}
                          onChange={this.handleUserCheckbox}
                          style={{ marginTop: 13 }}
                        />
                      </div>
                      <label
                        className="control-label col-md-3 col-sm-3"
                        style={{ textAlign: 'left' }}
                      >
                        Property for Client
                      </label>
                    </div>
                    {showUsers ? (
                      <div className="form-group row">
                        <div
                          className="col-md-1 col-sm-1 offset-md-3"
                          style={{ marginRight: '-57px' }}
                        >
                          <input
                            type="checkbox"
                            name="await_publishing"
                            checked={property.await_publishing}
                            onChange={this.handleUserCheckbox}
                            style={{ marginTop: 13 }}
                          />
                        </div>
                        <label
                          className="control-label col-md-3 col-sm-3"
                          style={{ textAlign: 'left' }}
                        >
                          Client asked not to publish
                        </label>
                      </div>
                    ) : null}
                    {!showUsers ? (
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">City*</label>
                        <div className="col-md-6 col-sm-6">
                          <Select.Async
                            name="city"
                            multi={false}
                            value={property.agency ? property.agency.city_id : null}
                            onChange={(value) => this.setAgentCity(value)}
                            loadOptions={(input) => AddProperties.getCity(input)}
                            valueKey="id"
                            labelKey="name"
                            clearable={false}
                            required={match.path !== addVoiceRoute && match.path !== editVoiceRoute}
                          />
                        </div>
                      </div>
                    ) : null}
                    {isWantedProperty ? (
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">Area (Read Only)</label>
                        <div className="col-md-6 col-sm-6">
                          <input
                            type="text"
                            name="area"
                            className="form-control disabled"
                            value={wantedPropertyAreas}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <CanView>
                          {property.area && (
                            <strong>
                              {Object.prototype.hasOwnProperty.call(property.area, 'active') &&
                                !property.area.active && (
                                  <div className="form-group row">
                                    <label className="control-label col-md-3 col-sm-3">
                                      Please Activate the area
                                    </label>
                                    <div
                                      className="col-md-6 col-sm-6"
                                      style={{ paddingTop: '8px' }}
                                    >
                                      <Link to={`/areas/edit-area/${property.area.id}`}>
                                        {property.area.name}
                                      </Link>
                                    </div>
                                  </div>
                                )}
                            </strong>
                          )}
                        </CanView>
                        {!showUsers && (
                          <div className="form-group row">
                            <label className="control-label col-md-3 col-sm-3">Agency*</label>
                            <div className="col-md-6 col-sm-6">
                              <Select
                                name="agency"
                                isLoading={isLoadingExternally}
                                multi={false}
                                value={selectedAgencyId}
                                onChange={(value) => this.setAgency(value)}
                                options={agencies}
                                valueKey="id"
                                labelKey="info"
                                clearable={false}
                                required={
                                  match.path !== addVoiceRoute && match.path !== editVoiceRoute
                                }
                              />
                            </div>
                          </div>
                        )}
                        {!showUsers && (
                          <div className="form-group row">
                            <label className="control-label col-md-3 col-sm-3">Agent*</label>
                            <div className="col-md-6 col-sm-6">
                              <Select
                                name="agent"
                                isLoading={isLoadingAgents}
                                multi={false}
                                value={selectedAgentId}
                                onChange={(value) => this.setAgents(value)}
                                options={agents}
                                valueKey="id"
                                labelKey="full_name"
                                clearable={false}
                                required={
                                  match.path !== addVoiceRoute && match.path !== editVoiceRoute
                                }
                              />
                            </div>
                          </div>
                        )}
                        {showUsers && (
                          <div className="form-group row">
                            <label className="control-label col-md-3 col-sm-3">User*</label>
                            <div className="userSearch">
                              <input
                                // style={{
                                //   width: '50%',
                                //   display: 'inline-flex',
                                //   position: 'relative',
                                //   top: '2px',
                                // }}
                                className="form-control"
                                value={userSearch}
                                onChange={(event) =>
                                  this.setState({
                                    userSearch: event.target.value ? event.target.value : undefined,
                                  })
                                }
                                onKeyPress={(event) => {
                                  if (event.key === 'Enter') {
                                    this.handleUserSearch();
                                  }
                                }}
                                placeholder="Press Enter to search user"
                              />
                              <button
                                aria-label="button"
                                type="button"
                                className="fa fa-search commentBtn"
                                onClick={() => {
                                  this.handleUserSearch();
                                }}
                              />
                            </div>
                            <div className="col-md-3 col-sm-3">
                              <Select
                                name="user"
                                isLoading={isLoadingUsers}
                                multi={false}
                                value={selectedUserId}
                                onChange={(value) => this.setUsers(value)}
                                options={users}
                                valueKey="id"
                                labelKey="info"
                                clearable={false}
                                required={
                                  match.path !== addVoiceRoute && match.path !== editVoiceRoute
                                }
                              />
                            </div>
                            {isWanted && (
                              <div className="additionalPhonesA">
                                <button
                                  className="btn btn-primary btn-sm additionalPhonesB"
                                  type="button"
                                  title="add"
                                  onClick={() => this.setState({ userModal: true })}
                                >
                                  Create User
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="x_content" />
                  <div className="x_title">
                    <h2>{voicePropertyId ? 'Edit Property Details' : 'Add Property'}</h2>
                    <div className="clearfix" />
                  </div>
                  <div className="x_content">
                    <br />
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Purpose*</label>
                      <div className="col-md-6 col-sm-6">
                        <select
                          name="purpose"
                          value={property.purpose}
                          className="form-select custom-select"
                          onChange={this.handleInputChange}
                          required={match.path !== addVoiceRoute && match.path !== editVoiceRoute}
                        >
                          <option value="sale">Sale</option>
                          <option value="rent">Rent</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Type*</label>
                      <div className="col-md-6 col-sm-6" value={property.type}>
                        <input
                          name="type"
                          type="radio"
                          value="residential"
                          checked={property.type === 'residential'}
                          onChange={this.handleInputChange}
                          required={match.path !== addVoiceRoute && match.path !== editVoiceRoute}
                        />
                        &nbsp;Residential
                        <br />
                        <input
                          name="type"
                          type="radio"
                          value="plot"
                          checked={property.type === 'plot'}
                          onChange={this.handleInputChange}
                        />
                        &nbsp;Plot
                        <br />
                        <input
                          name="type"
                          type="radio"
                          value="commercial"
                          checked={property.type === 'commercial'}
                          onChange={this.handleInputChange}
                        />
                        &nbsp;Commercial
                        <br />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Subtype*</label>
                      <div className="col-md-6 col-sm-6">
                        <select
                          style={{ textTransform: 'capitalize' }}
                          value={property.subtype}
                          name="subtype"
                          className="form-select custom-select"
                          onChange={this.handleInputChange}
                        >
                          {PROPERTY_SUBTYPES[property.type] &&
                            PROPERTY_SUBTYPES[property.type].map((subtype) => (
                              <option key={subtype.name} value={subtype.value}>
                                {subtype.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">City*</label>
                      <div className="col-md-6 col-sm-6">
                        <Select.Async
                          name="city"
                          multi={false}
                          value={property.city_id}
                          onChange={(value) => this.setCity(value)}
                          loadOptions={(input) => AddProperties.getCity(input)}
                          valueKey="id"
                          labelKey="name"
                          clearable={false}
                          required={match.path !== addVoiceRoute && match.path !== editVoiceRoute}
                        />
                      </div>
                    </div>
                    {isWantedProperty ? (
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">Area (Read Only)</label>
                        <div className="col-md-6 col-sm-6">
                          <input
                            type="text"
                            name="area"
                            className="form-control disabled"
                            value={wantedPropertyAreas}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <CanView>
                          {property.area && (
                            <strong>
                              {Object.prototype.hasOwnProperty.call(property.area, 'active') &&
                                !property.area.active && (
                                  <div className="form-group row">
                                    <label className="control-label col-md-3 col-sm-3">
                                      Please Activate the area
                                    </label>
                                    <div
                                      className="col-md-6 col-sm-6"
                                      style={{ paddingTop: '8px' }}
                                    >
                                      <Link to={`/areas/edit-area/${property.area.id}`}>
                                        {property.area.name}
                                      </Link>
                                    </div>
                                  </div>
                                )}
                            </strong>
                          )}
                        </CanView>
                        <div className="form-group row">
                          <label className="control-label col-md-3 col-sm-3">Area*</label>
                          <div className="col-md-6 col-sm-6">
                            <Select
                              style={{ zIndex: '0' }}
                              name="area"
                              isLoading={isLoadingExternally}
                              multi={false}
                              value={isWanted ? wantedArea : property.area_id}
                              onChange={(value) => this.setArea(value)}
                              options={
                                match.path === editVoiceRoute && !setArea ? areasVoice : areas
                              }
                              valueKey="id"
                              labelKey="name"
                              clearable={false}
                              required={
                                match.path !== addVoiceRoute && match.path !== editVoiceRoute
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Title</label>
                      <div className="col-md-6 col-sm-6">
                        <input
                          type="text"
                          name="custom_title"
                          className="form-control"
                          value={property.custom_title}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    {
                      !property.locate_manually || isManualGeoTag ? (
                        <div>
                          <div className="x_title">
                            <h2> Property Geotagging</h2>
                            <div className="clearfix" />
                          </div>
                          {isManualGeoTag ? (
                            <div>
                              <div style={{ zIndex: '0' }} className="col-md-12 col-sm-12">
                                {this.renderGeotagButtons()}
                                <GoogleMapContainer onSelect={this.onMapClick} />
                              </div>
                              <div className="form-group row" style={{ paddingTop: 70 }}>
                                {this.getSelectedLatLng()}
                              </div>
                            </div>
                          ) : null}
                          {!isManualGeoTag ? (
                            <div>
                              {this.renderGeotagButtons()}
                              <div className="form-group row">
                                <div className="col-md-12 col-sm-12" style={{ zIndex: '0' }}>
                                  <MapContainer
                                    mapZoom={mapZoom}
                                    city={chosenMapCity}
                                    housingScheme={chosenHousingScheme}
                                    phaseSector={chosenPhaseSector}
                                    blockSubsector={chosenBlockSubsector}
                                    mapPlots={mapPlots}
                                    mapCenter={mapCenter}
                                    plotMarker={plotMarker}
                                    onSelect={this.setMapPlot}
                                    onClick={this.onMapClick}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="control-label col-md-3 col-sm-3">
                                  Select City
                                </label>
                                <div className="col-md-6 col-sm6">
                                  <Select
                                    name="map-city"
                                    multi={false}
                                    value={chosenMapCity || ''}
                                    onChange={(value) => {
                                      this.setMapCity(value);
                                    }}
                                    options={mapCities}
                                    valueKey="id"
                                    labelKey="city_name"
                                    clearable={false}
                                  />
                                </div>
                              </div>
                              {loadingHousing ? <Loading /> : null}
                              {housingSchemes && housingSchemes.length > 0 && (
                                <div className="form-group row">
                                  <label className="control-label col-md-3 col-sm-3">
                                    Housing Scheme
                                  </label>
                                  <div className="col-md-6 col-sm-6">
                                    <Select
                                      name="housing-scheme"
                                      multi={false}
                                      value={chosenHousingScheme || ''}
                                      onChange={(value) => {
                                        this.setMapHousingScheme(value);
                                      }}
                                      options={housingSchemes}
                                      valueKey="id"
                                      labelKey="housing_scheme_name"
                                      clearable={false}
                                    />
                                  </div>
                                </div>
                              )}
                              {loadingPhase ? <Loading /> : null}
                              {phaseSector && phaseSector.length > 0 && (
                                <div className="form-group row">
                                  <label className="control-label col-md-3 col-sm-3">
                                    Select Phase / Sector
                                  </label>
                                  <div className="col-md-6 col-sm-6">
                                    <Select
                                      name="phase-sector"
                                      multi={false}
                                      value={chosenPhaseSector || ''}
                                      onChange={(value) => {
                                        this.setMapPhaseSector(value);
                                      }}
                                      options={phaseSector}
                                      valueKey="id"
                                      labelKey="phase_sector_name"
                                      clearable={false}
                                    />
                                  </div>
                                </div>
                              )}
                              {loadingBlock ? <Loading /> : null}
                              {blockSubsectors.length > 0 && (
                                <div className="form-group row">
                                  <label className="control-label col-md-3 col-sm-3">
                                    {' '}
                                    Select Block / Sub-Sector{' '}
                                  </label>
                                  <div className="col-md-6 col-sm-6">
                                    <Select
                                      name="block-subsector"
                                      multi={false}
                                      value={chosenBlockSubsector || ''}
                                      onChange={(value) => {
                                        this.setMapBlockSubSector(value);
                                      }}
                                      options={blockSubsectors}
                                      valueKey="id"
                                      labelKey="block_subsector_name"
                                      clearable={false}
                                    />
                                  </div>
                                </div>
                              )}
                              {streets.length > 0 && chosenMapCity.id === '1' && (
                                <div className="form-group row">
                                  <label className="control-label col-md-3 col-sm-3">
                                    {' '}
                                    Select Street No{' '}
                                  </label>
                                  <div className="col-md-6 col-sm-6">
                                    <Select
                                      name="map-plot"
                                      multi={false}
                                      value={chosenStreet || ''}
                                      onChange={(value) => {
                                        this.filterPlotsByStreet(value);
                                      }}
                                      valueKey="value"
                                      labelKey="value"
                                      options={streets}
                                      clearable={false}
                                    />
                                  </div>
                                </div>
                              )}
                              {mapPlots.length > 0 && (
                                <div className="form-group row">
                                  <label className="control-label col-md-3 col-sm-3">
                                    {' '}
                                    Select Plot{' '}
                                  </label>
                                  <div className="col-md-6 col-sm-6">
                                    <Select
                                      name="map-plot"
                                      multi={false}
                                      value={chosenMapPlot || ''}
                                      onChange={(value) => {
                                        this.setMapPlot(value);
                                      }}
                                      options={filteredPlots}
                                      valueKey="id"
                                      labelKey="info"
                                      clearable={false}
                                    />
                                  </div>
                                </div>
                              )}
                              {plotsUnavailable && (
                                <div className="form-group row">
                                  <div className="col-md-6 col-sm-6 offset-md-3">
                                    <h6>Geo Data for Plots not available in this area</h6>
                                  </div>
                                </div>
                              )}
                              {chosenMapPlot && (
                                <div className="form-group row">
                                  <div className="col-md-6 col-sm-6 offset-md-3">
                                    <div className="alert alert-primary">{`Propsure Plot ID is ${chosenMapPlot.id}`}</div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : null}

                          <div className="x_title">
                            <div className="clearfix" />
                          </div>

                          <br />
                        </div>
                      ) : null
                      // <div className="form-group row">
                      //   <label className="control-label col-md-3 col-sm-3">Latitude</label>
                      //   <div className="col-md-2 col-sm-2">
                      //     <input
                      //       type="text"
                      //       name="lat"
                      //       className="form-control"
                      //       value={property.lat}
                      //       onChange={this.handleInputChange}
                      //       pattern="-?[0-9]{1,3}[.][0-9]{1,7}"
                      //       required
                      //       disabled
                      //     />
                      //   </div>
                      //   <label className="control-label col-md-2 col-sm-2">Longitude</label>
                      //   <div className="col-md-2 col-sm-2">
                      //     <input
                      //       type="text"
                      //       name="lon"
                      //       className="form-control"
                      //       value={property.lon}
                      //       onChange={this.handleInputChange}
                      //       pattern="-?[0-9]{1,3}[.][0-9]{1,7}"
                      //       required
                      //       disabled
                      //     />
                      //   </div>
                      // </div>
                    }
                    <div className="x_title">
                      <h2>Other Particulars</h2>
                      <div className="clearfix" />
                    </div>
                    {address ? (
                      <div className="" style={{ paddingTop: 15 }}>
                        <div>
                          <label className="control-label col-md-3 col-sm-3">Address</label>
                          {address.map((ad, index) => {
                            const aLength = address.length;
                            let subsectorCheck = false;
                            let blockCheck = false;
                            let phaseCheck = false;
                            let galiCheck = false;
                            let streetCheck = false;
                            let plotCheck = false;
                            let i = 0;
                            while (i < aLength) {
                              if (address[i].type === 'subsector') {
                                subsectorCheck = true;
                              }
                              if (address[i].type === 'block') {
                                blockCheck = true;
                              }
                              if (address[i].type === 'phase') {
                                phaseCheck = true;
                              }
                              if (address[i].type === 'gali') {
                                galiCheck = true;
                              }
                              if (address[i].type === 'street') {
                                streetCheck = true;
                              }
                              if (address[i].type === 'plot_no') {
                                plotCheck = true;
                              }
                              i += 1;
                            }
                            return (
                              <div
                                key={`${removeKey} ${index + 1}`}
                                style={{ position: 'relative', top: '-36px' }}
                                className="form-group row"
                              >
                                <div
                                  className="col-md-2 col-sm-2 offset-md-3"
                                  style={{ paddingRight: 0 }}
                                >
                                  <select
                                    name="addressType"
                                    className="form-select custom-select"
                                    onChange={(e) => this.handleAddressType(e, index)}
                                    value={address[index].type ? address[index].type : ''}
                                  >
                                    <option value="">Select type</option>
                                    <option disabled={streetCheck} value="street">
                                      Street
                                    </option>
                                    <option disabled={subsectorCheck} value="subsector">
                                      Subsector
                                    </option>
                                    <option disabled={blockCheck} value="block">
                                      Block
                                    </option>
                                    <option disabled={phaseCheck} value="phase">
                                      Phase
                                    </option>
                                    <option disabled={galiCheck} value="gali">
                                      Gali
                                    </option>
                                    <option disabled={plotCheck} value="plot_no">
                                      Plot no.
                                    </option>
                                  </select>
                                </div>
                                <div className="col-md-4 col-sm-4" style={{ paddingLeft: 10 }}>
                                  <input
                                    name="address"
                                    type="text"
                                    maxLength={65}
                                    className="form-control"
                                    value={address[index].value ? address[index].value : ''}
                                    onChange={(e) => this.handleAddressInput(e, index)}
                                  />
                                  <p>
                                    Max Length : 65 Letters &nbsp; &nbsp; &nbsp;{' '}
                                    {address[index].remainingLetters} remaining
                                  </p>
                                </div>
                                {address.length < 6 &&
                                address[index] === address[address.length - 1] ? (
                                  <div id={`${index + 1}`} className="col-md-3 additionalPhonesA">
                                    <button
                                      aria-label="button"
                                      className="btn btn-primary btn-sm additionalPhonesB fa fa-plus"
                                      type="button"
                                      title="add"
                                      onClick={() => this.addAddressField(index)}
                                    />
                                  </div>
                                ) : null}
                                {(address[index].type &&
                                  address[index] !== address[address.length - 1]) ||
                                address[5] ? (
                                  <div id={`${index + 7}`} className="col-md-3 additionalPhonesA">
                                    <button
                                      aria-label="button"
                                      className="btn btn-primary btn-sm additionalPhonesB fa fa-minus"
                                      type="button"
                                      title="remove"
                                      onClick={() => this.removeAddressField(index)}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Size*</label>
                      <div className="col-md-6 col-sm-6">
                        <input
                          type="number"
                          name="size"
                          className="form-control"
                          value={property.size}
                          onChange={this.handleInputChange}
                          onWheel={(e) => e.target.blur()}
                          min={
                            match.path !== addVoiceRoute && match.path !== editVoiceRoute ? 1 : 0
                          }
                          step="0.001"
                          onKeyDown={(evt) =>
                            (evt.key === 'e' || evt.key === 'E') && evt.preventDefault()
                          }
                          required={match.path !== addVoiceRoute && match.path !== editVoiceRoute}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Size Unit*</label>
                      <div className="col-md-6 col-sm-6">
                        <select
                          name="size_unit"
                          className="form-select custom-select"
                          value={property.size_unit}
                          onChange={this.handleInputChange}
                          required={match.path !== addVoiceRoute && match.path !== editVoiceRoute}
                        >
                          <option value="sqft">Square Feet</option>
                          <option value="sqyd">Square Yard</option>
                          <option value="sqm">Square Meter</option>
                          <option value="kanal">Kanal</option>
                          <option value="marla">Marla</option>
                          <option value="acre">Acre</option>
                        </select>
                      </div>
                    </div>
                    {isWantedProperty ? (
                      <div>
                        <div className="form-group row">
                          <label className="control-label col-md-3 col-sm-3">Min Price</label>
                          <div className="col-md-6 col-sm-6">
                            <input
                              type="text"
                              name="min_price"
                              className="form-control"
                              value={property.min_price}
                              onChange={this.handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="control-label col-md-3 col-sm-3">Max Price</label>
                          <div className="col-md-6 col-sm-6">
                            <input
                              type="text"
                              name="max_price"
                              className="form-control"
                              value={property.max_price}
                              onChange={this.handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">Price*</label>
                        <div className="col-md-6 col-sm-6 price-label-container">
                          <input
                            type="number"
                            name="price"
                            className="form-control"
                            value={property.price}
                            onChange={this.handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            min={1}
                            required={match.path !== addVoiceRoute && match.path !== editVoiceRoute}
                          />
                          {property.price >= 1000 ? (
                            <span className="price-label">
                              {convertPrice(property.price, 'PKR')}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Description</label>
                      <div style={{ zIndex: 1 }} className="col-md-6 col-sm-6">
                        <RichTextEditor
                          value={description}
                          toolbarConfig={RTE_TOOLBAR_CONFIG}
                          onChange={(e) => {
                            this.setDescription(e);
                          }}
                        />
                      </div>
                    </div>
                    {!isWantedProperty && (
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">Video URL</label>
                        <div className="col-md-6 col-sm-6">
                          <input
                            type="textarea"
                            name="video"
                            className="form-control"
                            value={property.video}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Comments</label>
                      <div className="col-md-6 col-sm-6">
                        <input
                          type="text"
                          name="comments"
                          className="form-control"
                          value={property.comments}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    {!showUsers ? (
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">
                          Agent Phone Number*
                        </label>
                        <div className="col-md-6 col-sm-6">
                          {phoneCheck || addVoiceRoute || editVoiceRoute ? (
                            <PhoneInput
                              key={agentPhone}
                              name="phone"
                              country="pk"
                              value={property.phone}
                              onChange={(phone, country) =>
                                this.handlePhoneProperty(phone, country)
                              }
                              enableLongNumbers
                              countryCodeEditable={false}
                            />
                          ) : (
                            <input
                              type="text"
                              name="phone"
                              className="form-control"
                              value={property.phone}
                              onChange={this.handleInputChange}
                              required={
                                match.path !== addVoiceRoute && match.path !== editVoiceRoute
                              }
                              pattern="\s*\S+.*"
                              title="This field cannot start with an empty space."
                            />
                          )}
                        </div>
                        <div className="col-md-3 additionalPhonesA">
                          <button
                            className="btn btn-primary btn-sm additionalPhonesB"
                            type="button"
                            title="add"
                            onClick={() => this.addMorePhoneNumber()}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">User Phone Number</label>
                        <div className="col-md-6 col-sm-6">
                          {phoneCheck ? (
                            <PhoneInput
                              key={userPhone}
                              name="phone"
                              country="pk"
                              value={property.phone}
                              onChange={(phone, country) =>
                                this.handlePhoneProperty(phone, country)
                              }
                              enableLongNumbers
                              countryCodeEditable={false}
                            />
                          ) : (
                            <input
                              type="text"
                              name="phone"
                              className="form-control"
                              value={property.phone}
                              onChange={this.handleInputChange}
                              required={
                                match.path !== addVoiceRoute && match.path !== editVoiceRoute
                              }
                              pattern="\s*\S+.*"
                              title="This field cannot start with an empty space."
                            />
                          )}
                        </div>
                        <div className="col-md-3 additionalPhonesA">
                          <button
                            className="btn btn-primary btn-sm additionalPhonesB"
                            type="button"
                            title="add"
                            onClick={() => this.addMorePhoneNumber()}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    )}
                    {!phoneValid && (
                      <div className="form-group row">
                        <div
                          ref={(ref) => {
                            this.phoneRef = ref;
                          }}
                          className="col-md-6 col-sm-6 offset-md-3"
                        >
                          <Alert color="danger">Please enter valid number</Alert>
                        </div>
                      </div>
                    )}
                    {property.phones && (
                      <div className="">
                        {property.phones.map((phone, index) => (
                          <div key={`${removeKey} ${index + 1}`} className="form-group row">
                            <label className="control-label col-md-3 col-sm-3">
                              {' '}
                              Additional Phone # {index + 1}
                            </label>
                            <div className="col-md-6 col-sm-6">
                              <PhoneInput
                                name="phone"
                                country="pk"
                                value={phone.phone}
                                onChange={(phonea, country) =>
                                  this.changePhoneNumber(phonea, index, country)
                                }
                                enableLongNumbers
                              />
                            </div>
                            <div className="col-md-3 additionalPhonesA">
                              <button
                                className="btn btn-primary btn-sm additionalPhonesB"
                                type="button"
                                title="remove"
                                onClick={() => this.removePhoneNumber(index, phone)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {!editPropertyPhoneValid && (
                      <div className="form-group row">
                        <div
                          ref={(ref) => {
                            this.additionalNumberRef = ref;
                          }}
                          className="col-md-6 col-sm-6 offset-md-3"
                        >
                          <Alert color="danger">Please enter valid number</Alert>
                        </div>
                      </div>
                    )}
                    {extraNumber && (
                      <div className="form-group row">
                        <div
                          ref={(ref) => {
                            this.extraNumberRef = ref;
                          }}
                          className="col-md-6 col-sm-6 offset-md-3"
                        >
                          <Alert color="danger">
                            Only two additional phone numbers are allowed
                          </Alert>
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Owner Name</label>
                      <div className="col-md-6 col-sm-6">
                        <input
                          type="text"
                          name="owner_name"
                          className="form-control"
                          value={
                            match.path === wantedRoute ||
                            match.path === addVoiceRoute ||
                            match.path === editVoiceRoute ||
                            match.path === rwrEditPropertyRoute
                              ? ownerName
                              : property.owner_name
                          }
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Owner Phone Number</label>
                      <div className="col-md-6 col-sm-6">
                        <PhoneInput
                          // key={agentPhone}
                          name="owner_phone"
                          country="pk"
                          value={
                            match.path === wantedRoute ||
                            match.path === addVoiceRoute ||
                            match.path === editVoiceRoute ||
                            match.path === rwrEditPropertyRoute
                              ? ownerPhone
                              : property.owner_phone
                          }
                          onChange={(phone, country) => this.handlePhoneOwner(phone, country)}
                          enableLongNumbers
                          countryCodeEditable={false}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Same as Owner</label>
                      <div className="col-md-6 col-sm-6">
                        <input
                          name="same_as_owner"
                          type="checkbox"
                          checked={sameAsOwner}
                          style={{ marginTop: '12px' }}
                          onChange={this.handleOwnerCheckBox}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">POC Name</label>
                      <div className="col-md-6 col-sm-6">
                        <input
                          type="text"
                          name="poc_name"
                          className="form-control"
                          value={property.poc_name}
                          onChange={this.handleInputChange}
                          disabled={!!sameAsOwner}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">POC Phone Number</label>
                      <div className="col-md-6 col-sm-6">
                        <PhoneInput
                          // key={agentPhone}
                          name="poc_phone"
                          country="pk"
                          value={property.poc_phone}
                          onChange={(phone, country) => this.handlePhonePoc(phone, country)}
                          enableLongNumbers
                          countryCodeEditable={false}
                          disabled={!!sameAsOwner}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Exclusive Listing</label>
                      <div className="col-md-6 col-sm-6">
                        <input
                          name="same_as_owner"
                          type="checkbox"
                          checked={property.exclusiveListing}
                          style={{ marginTop: '12px' }}
                          onChange={this.handleExclusiveCheckbox}
                          disabled={this.getExclusiveBool()}
                        />
                      </div>
                    </div>
                    <div className="ln_solid" />
                    {type && (
                      <div>
                        <div>
                          <div>
                            <h2>Additional Details</h2>
                            <div className="ln_solid" />
                            {this.renderGeneralFeatrues()}
                            <div>
                              {property.purpose === 'sale' && (
                                <div className="form-group row">
                                  <label className="control-label col-md-3 col-sm-3">
                                    Down payment
                                  </label>
                                  <div className="col-md-6 col-sm-6 price-label-container">
                                    <input
                                      className="form-control"
                                      type="number"
                                      name="downpayment"
                                      display="price"
                                      placeholder="Price in (PKR)"
                                      value={this.selectedFeatures.downpayment}
                                      onChange={(event) => {
                                        this.setFeature(event.target.name, event.target.value);
                                      }}
                                      onWheel={(e) => e.target.blur()}
                                      min={1}
                                    />
                                    {this.selectedFeatures.downpayment >= 1000 && (
                                      <span className="price-label">
                                        {convertPrice(this.selectedFeatures.downpayment, 'PKR')}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-md-6 col-sm-6 offset-md-3">
                              {this.renderAdditionalFeatures()}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {match.path.includes(addPropertiesRoute) && (
                      <div>
                        <div className="ln_solid" />
                        <div className="form-group row">
                          <div className="col-md-6 col-sm-6 offset-md-3">
                            <Button
                              className={`btn btn-success btn-lg ${loading ? 'disabled' : ''}`}
                            >
                              <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} />{' '}
                              Save
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddProperties.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  wantedSaved: PropTypes.instanceOf(Boolean).isRequired,
  leadPropertyPayload: PropTypes.instanceOf().isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(AddProperties);
