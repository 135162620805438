/** @format */

import React, { useState } from 'react';
import './styles.scss';
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import Formsy from 'formsy-react';
import axios from '../utils/axiosInstance';
import GraanaLogo from '../components/GraanaLogo';
import getOtpUrl from '../utils/OtpUrls';
import { VERSION_NUMBER } from '../static/_constants';

const ForgetPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [emailPhone, setEmailPhone] = useState(null);

  const sendOtp = (data, userId) => {
    const payload = {};
    const { history } = props;
    if (data?.isMobile) {
      payload.phone_number = data?.phoneNumber;
    } else {
      payload.email = data?.email;
    }
    axios
      .post(getOtpUrl(data?.isMobile ? 'phone' : 'email'), payload)
      .then((response) => {
        if (response?.status === 200) {
          setLoading(false);
          history.push({
            pathname: '/otp-verification',
            state: {
              userIdEncoded: userId,
              verificationKey: response?.data?.Details,
              phoneOrEmail: data?.isMobile ? data?.phoneNumber : data.email,
              data,
            },
          });
        }
      })
      .catch((/* error */) => {
        // console.log(error);
      });
  };

  const submitForm = () => {
    setLoading(true);
    let data;
    if (emailPhone) {
      const result = emailPhone.search('@');
      if (result === -1) {
        data = {
          phoneNumber: emailPhone,
          isMobile: true,
        };
      } else {
        data = {
          email: emailPhone,
          isMobile: false,
        };
      }
      axios
        .post('/api/system-user/verifyPhoneNumberOrEmail', data)
        .then((response) => {
          if (response?.data?.exist) {
            sendOtp(data, response?.data?.userId);
          } else {
            alert(response?.data?.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          window.alert(error);
        });
    } else {
      setLoading(false);
      window.alert('Please provide email/phone');
    }
  };

  const backToLogin = () => {
    const { history } = props;
    history.push('/login');
  };

  return (
    <div className="app flex-row align-items-center animated fadeIn">
      <Container>
        <Row className="justify-content-center">
          <div className="col-md-8">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <div>
                    <div className="text-center mb-4">
                      <img
                        src="../img/forgetPassword.png"
                        alt="forget icon"
                        style={{ height: '77px', width: '77px' }}
                      />
                    </div>
                    <div className="text-center mb-2">
                      <label className="text-center heading">Forgot Password</label>
                    </div>
                    <p className="text mb-4">
                      Enter your registered Email/phone number so that we will send you a{' '}
                      <strong>One-Time Password (OTP)</strong>
                    </p>
                  </div>
                  <div>
                    <Formsy onValidSubmit={submitForm}>
                      <InputGroup className="mb-3">
                        <InputGroupText
                          style={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}
                        >
                          <img
                            src="../img/phoneEmail.png"
                            alt="phone/email icon"
                            style={{ height: '17px' }}
                          />
                        </InputGroupText>
                        <Input
                          style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                          type="text"
                          placeholder="Email/Phone number"
                          required
                          pattern="^([0-9]{11})|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$"
                          onChange={(e) => setEmailPhone(e.target.value)}
                        />
                      </InputGroup>
                      <div className="text-center">
                        <Row>
                          <Col xs="12">
                            <Button
                              color="graana-red"
                              style={{ color: 'white' }}
                              className={`px-4 ${loading ? 'disabled' : ''}`}
                            >
                              <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} />{' '}
                              Get OTP
                            </Button>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col xs="12">
                            <button type="button" className="forgetLabel" onClick={backToLogin}>
                              Back to Login
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Formsy>
                  </div>
                </CardBody>
                <div className="text-center versionLabel">{VERSION_NUMBER}</div>
              </Card>
              <GraanaLogo styles={{ width: '70%', margin: '2% auto' }} marginValue="32" />
            </CardGroup>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ForgetPassword;
