/** @format */

import React from 'react';
import { Card, CardBody } from 'reactstrap';

const GraanaLogo = ({ styles, marginValue }) => {
  const style = {
    logoWrapper: {
      width: '100%',
      margin: `${marginValue}% auto -16px`,
      height: `${75}px`,
      display: 'flex',
      justifyContent: 'center',
    },
    svg: {
      width: '100%',
      fill: '#ffffff',
    },
  };

  return (
    <>
      <Card className="text-white bg-graana-red py-5 d-md-down-none" style={{ width: `${44}%` }}>
        <CardBody className="text-center">
          <div>
            <div style={style.logoWrapper}>
              <svg style={style.svg}>
                <use xlinkHref="/img/logo.svg#logo" />
              </svg>
            </div>
            <p className="text-end" style={styles}>
              Group of Companies
            </p>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default GraanaLogo;
