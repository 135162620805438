/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import * as types from '../static/_types';

class Aside extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: [{ id: shortid.generate(), type: '', value: '' }],
    };

    this.add = this.add.bind(this);
    this.subtract = this.subtract.bind(this);
    this.setType = this.setType.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  setType(e, item) {
    e.preventDefault();
    const newItem = item;
    const { features } = this.state;
    newItem.type = e && e.target && e.target.value;
    this.setState({ features });
  }

  setValue(e, item) {
    e.preventDefault();
    const newItem = item;
    const { features } = this.state;
    newItem.value = e && e.target && e.target.value;
    this.setState({ features });
  }

  add(e) {
    e.preventDefault();
    const { filters } = this.state;
    this.setState({
      filters: [...filters, ...[{ id: shortid.generate(), type: '', value: '' }]],
    });
  }

  subtract(e, id) {
    e.preventDefault();
    const { filters } = this.state;
    this.setState({
      filters: filters.filter((filter) => filter.id !== id),
    });
  }

  applyFilters() {
    const { dispatch } = this.props;
    const { filters } = this.state;

    let cleanedFilters = filters.filter((filter) => filter.type && filter.value);
    cleanedFilters = cleanedFilters.map((filter) => ({ type: filter.type, value: filter.value }));
    dispatch({
      type: types.SET_FILTERS,
      payload: cleanedFilters && cleanedFilters.length ? cleanedFilters : null,
    });
  }

  resetFilters() {
    const { dispatch } = this.props;
    this.setState({ filters: [{ id: shortid.generate(), type: '', value: '' }] });
    dispatch({
      type: types.CLEAR_FILTERS,
    });
  }

  render() {
    const { filters } = this.state;
    return (
      <aside className="aside-menu">
        <div className="text-center">
          <h5 className="mt-3">Filters</h5>
        </div>
        <div className="aside-menu-content">
          {filters.map((item, index) => (
            <div className="filter-item" key={item.id}>
              <select
                required
                name="searchBy"
                value={item.type}
                className="form-control custom-select"
                onChange={(e) => this.setType(e, item)}
              >
                <option value="">by</option>
                <option value="id">Id</option>
                <option value="city">City</option>
                <option value="email">Email</option>
                <option value="phone">Phone</option>
                <option value="agency">Agency</option>
                <option value="purpose">Purpose</option>
                <option value="area">Area</option>
              </select>
              <input
                className="form-control"
                type={item.type === 'id' ? 'number' : 'text'}
                name="search"
                placeholder="Search"
                value={item.value}
                onChange={(e) => this.setValue(e, item)}
                // onKeyPress={(event) => { if (event.key === 'Enter') { this.handleSearch(); } }}
              />
              <div className="text-center">
                {index === filters.length - 1 ? (
                  <span
                    role="presentation"
                    className="add-subtract-identifier"
                    onClick={(e) => this.add(e)}
                  >
                    {' '}
                    +{' '}
                  </span>
                ) : (
                  <span
                    role="presentation"
                    className="add-subtract-identifier"
                    onClick={(e) => this.subtract(e, item.id)}
                  >
                    {' '}
                    -{' '}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="aside-menu-footer">
          <div className="filter-buttons">
            <button
              type="button"
              onClick={() => this.applyFilters()}
              className="btn btn-primary apply-btn"
            >
              Apply
            </button>
            <button
              type="button"
              onClick={() => this.resetFilters()}
              className="btn btn-danger apply-btn"
            >
              Reset
            </button>
          </div>
        </div>
      </aside>
    );
  }
}

Aside.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Aside);
