/** @format */

import React, { Component } from 'react';
import { GoogleApiWrapper, Map as ReactMap, Marker } from 'google-maps-react';
import PropTypes from 'prop-types';

class GoogleMapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: {
        lat: parseFloat(33.6844),
        lng: parseFloat(73.0479),
      },
      zoom: 11,
      markerPostition: null,
      apiReady: false,
      showSearch: false,
    };
    this.map = React.createRef();
    this.searchBox = React.createRef();
  }

  setLatLng = (data) => {
    const { onSelect } = this.props;
    this.setState((prevState) => ({
      center: {
        ...prevState.center,
        lat: parseFloat(data.lat),
        lng: parseFloat(data.lng),
      },
      zoom: 18,
      markerPostition: {
        ...prevState.markerPostition,
        lat: parseFloat(data.lat),
        lng: parseFloat(data.lng),
      },
    }));
    onSelect(data);
  };

  onApiLoad = () => {
    const { google } = this.props;
    this.setState({ apiReady: true });
    const autoComplete = new google.maps.places.Autocomplete(this.searchBox?.current, {
      componentRestrictions: { country: 'pk' },
    });
    this.map.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.searchBox?.current);
    setTimeout(() => {
      if (this.searchBox.current) {
        this.searchBox.current.style.top = '5px';
        this.searchBox.current.style.left = '5px';
        this.setState({ showSearch: true });
      }
    }, 2000);
    // Bias the SearchBox results towards current map's viewport.
    this.map.map.addListener('bounds_changed', () => {
      setTimeout(() => {
        if (this.searchBox.current) {
          this.searchBox.current.style.top = '5px';
          this.searchBox.current.style.left = '5px';
          this.setState({ showSearch: true });
        }
      }, 1000);

      autoComplete.setBounds(this.map?.map.getBounds());
    });

    autoComplete.addListener('place_changed', () => {
      const place = autoComplete.getPlace();

      if (place.length === 0) {
        return null;
      }
      const coordinates = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.setLatLng(coordinates);
      return place;
    });
  };

  render() {
    const { center, zoom, apiReady, showSearch, markerPostition } = this.state;
    const { onSelect, google } = this.props;
    const bool = true;

    return (
      <div style={{ height: '70vh', width: '100%' }}>
        <ReactMap
          ref={(ref) => {
            this.map = ref;
          }}
          containerStyle={{
            position: 'absolute',
            width: '70%',
            height: '70vh',
          }}
          // streetViewControlOptions={false}
          google={google}
          initialCenter={center}
          center={center}
          defaultZoom={zoom}
          zoom={zoom}
          streetViewControl={false}
          mapTypeControl={false}
          fullscreenControl={bool}
          scrollwheel={bool}
          onReady={() => {
            this.onApiLoad();
          }}
          onClick={(ev, m, e) => {
            this.setState((prevState) => ({
              markerPostition: {
                ...prevState.markerPostition,
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
              },
            }));
            onSelect({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          }}
        >
          {markerPostition && (
            <Marker
              title="The marker`s title will appear as a tooltip."
              name="SOMA"
              position={markerPostition}
            />
          )}
          {apiReady && (
            <input
              ref={this.searchBox}
              hidden={!showSearch}
              placeholder="Search Places..."
              type="text"
              style={{
                width: '90%',
                borderRadius: '6px',
                height: '55px',
                border: 'none',
                fontSize: '15px',
                padding: '10px',
                marginTop: '20px ',
                marginLeft: '20px ',
              }}
            />
          )}
        </ReactMap>
      </div>
    );
  }
}
GoogleMapContainer.propTypes = {
  onSelect: PropTypes.func,
  // google: PropTypes.objectOf,
};

GoogleMapContainer.defaultProps = {
  onSelect: '',
  // google: '',
};
export default GoogleApiWrapper({
  // apiKey: 'AIzaSyAr0sQtYcNfPolIa-vGAgE4tGotE1vbl90',
  apiKey: 'AIzaSyCuQmxqgWcP4flO66XKWNICyUNHVA0cAbg',
  libraries: ['places'],
})(GoogleMapContainer);
