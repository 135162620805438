/** @format */

import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import Cookie from 'js-cookie';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from '../utils/axiosInstance';
import './styles.scss';
import GraanaLogo from '../components/GraanaLogo';
import { VERSION_NUMBER } from '../static/_constants';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // user: null,
      username: '',
      password: '',
      loading: false,
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {}

  path = (userData) => {
    const { history } = this.props;
    let i;
    for (i = 0; i <= userData.length; i += 1) {
      if (userData[i].route === '/') {
        if (userData[i + 1].slug.indexOf(':') < -1) {
          history.push(userData[i + 1].route);
          return userData[i + 1].route;
        }
        if (userData[i + 1].slug.indexOf(':') > -1) {
          history.push(userData[i + 2].route);
          return userData[i + 2].route;
        }
      } else {
        history.push(userData[i].route);
        return userData[i].route;
      }
    }
    return 1;
  };

  path = (userData, superadmin, userInfo) => {
    const { history } = this.props;
    if (superadmin) {
      history.push('/cataloging');
      return '/cataloging';
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/cataloging' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/cataloging');
        return '/cataloging';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/media-requests' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/media-requests');
        return '/media-requests';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/fresh-listings' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/fresh-listings');
        return '/fresh-listings';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/properties-media' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/properties-media');
        return '/properties-media';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/AreasMedia' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/AreasMedia');
        return '/AreasMedia';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/graana-users' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/graana-users');
        return '/graana-users';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/customers-management' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/customers-management');
        return '/customers-management';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/agencies-management' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/agencies-management');
        return '/agencies-management';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/developers-management' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/developers-management');
        return '/developers-management';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/guru-management' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/guru-management');
        return '/guru-management';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/cities' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/cities');
        return '/cities';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/areas' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/areas');
        return '/areas';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/area-groups' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/area-groups');
        return '/area-groups';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/zones' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/zones');
        return '/zones';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/regions' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/regions');
        return '/regions';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/projects' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/projects');
        return '/projects';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/cover-footer-ads' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/cover-footer-ads');
        return '/cover-footer-ads';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/featured-mega' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/featured-mega');
        return '/featured-mega';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/wanted-voice-leads' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/wanted-voice-leads');
        return '/wanted-voice-leads';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/rwr-management' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/rwr-management');
        return '/rwr-management';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/opvs-requests' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/opvs-requests');
        return '/opvs-requests';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/zonal-dashboard' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/zonal-dashboard');
        return '/zonal-dashboard';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/geotagged-properties' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/geotagged-properties');
        return '/geotagged-properties';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/careers-cvs' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/careers-cvs');
        return '/careers-cvs';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/jobs-dashboard' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/jobs-dashboard');
        return '/jobs-dashboard';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/inquiries' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/inquiries');
        return '/inquiries';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/invest-leads-wanted' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/invest-leads-wanted');
        return '/invest-leads-wanted';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/feedback' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/feedback');
        return '/feedback';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/complaints' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/complaints');
        return '/complaints';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/wanted' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/wanted');
        return '/wanted';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/aira-logs' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/aira-logs');
        return '/aira-logs';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/cataloger-logs' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/cataloger-logs');
        return '/cataloger-logs';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/field-force-logs' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/field-force-logs');
        return '/field-force-logs';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/graana-users' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/graana-users');
        return '/graana-users';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/field-force-management' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/field-force-management');
        return '/field-force-management';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/permission-groups' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/permission-groups');
        return '/permission-groups';
      }
    }
    for (let i = 0; i < userData.length; i += 1) {
      if (
        userData[i].route === '/push-notification-form' &&
        ((userInfo.role === 'manager' && userData[i].roles && userData[i].roles.manager) ||
          (userInfo.role === 'data-entry' &&
            userData[i].roles &&
            userData[i].roles['data-entry']) ||
          (userInfo.role === 'view-only' && userData[i].roles && userData[i].roles['view-only']))
      ) {
        history.push('/push-notification-form');
        return '/push-notification-form';
      }
    }
    return true;
    //   if (usrData.route === '/access-management') {
    //     history.push('/access-management');
    //     return '/access-management';
    //   }
    //   if (usrData.route === '/push-notification-form') {
    //     history.push('/push-notification-form');
    //     return '/push-notification-form';
    //   }
    //   return true;
    // });
  };

  forgetPassword = () => {
    const { history } = this.props;
    history.push('/forget-password');
  };

  submit() {
    const { loading } = this.state;
    const { history } = this.props;
    if (!loading) {
      const { username, password } = this.state;
      const user = {
        email: username,
        password,
      };
      this.setState({ loading: true });
      axios
        .post('/api/user/system-user-login', user)
        .then((response) => {
          if (response && response.status === 200) {
            if (response?.data?.forceReset === true) {
              const tokenString = response?.data?.token;
              axios.defaults.headers.common.Authorization = `Bearer ${tokenString}`;
              Cookie.set('graana_admin_access_token_reset', `${tokenString}`, { expires: 14 });
              history.push({
                pathname: '/reset-password',
              });
            } else {
              const userData = JSON.parse(response.data.systemuser.departmentInfo.components);
              const { token } = response.data;
              axios.defaults.headers.common.Authorization = `Bearer ${token}`;
              Cookie.set('graana_admin_access_token', `${token}`, { expires: 14 });
              history.push('/');
              window.localStorage.setItem(
                'isHome',
                this.path(userData, response.data.systemuser.superadmin, response.data.systemuser)
              );
              // dispatch({
              //   type: types.SET_HOME,
              //   payload: this.path(userData),
              // });
              // this.path(userData);
            }
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          window.alert(error.response.data);
        });
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="app flex-row align-items-center animated fadeIn">
        <Container>
          <Row className="justify-content-center">
            <div className="col-md-8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1 className="text-center">Login</h1>
                    <p className="text-muted text-center">Sign In to your account</p>
                    <Formsy onValidSubmit={this.submit}>
                      <InputGroup className="mb-3">
                        <InputGroupText
                          style={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}
                        >
                          <i className="icon-user" />
                        </InputGroupText>
                        <Input
                          style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                          type="email"
                          placeholder="Username"
                          required
                          ref={(input) => {
                            this.username = input;
                          }}
                          onChange={(e) => this.setState({ username: e.target.value })}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupText
                          style={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}
                        >
                          <i className="icon-lock" />
                        </InputGroupText>
                        <Input
                          style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                          type="password"
                          placeholder="Password"
                          required
                          ref={(input) => {
                            this.password = input;
                          }}
                          onChange={(e) => this.setState({ password: e.target.value })}
                        />
                      </InputGroup>
                      <div className="alignLabel">
                        <Row>
                          <Col xs="12">
                            <button
                              type="button"
                              className="forgetLabel"
                              onClick={this.forgetPassword}
                            >
                              Forgot Password?
                            </button>
                          </Col>
                        </Row>
                      </div>
                      <div className="text-center">
                        <Row>
                          <Col xs="12">
                            <Button
                              color="graana-red"
                              style={{ color: 'white' }}
                              className={`px-4 ${loading ? 'disabled' : ''}`}
                            >
                              <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} />{' '}
                              Login
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Formsy>
                  </CardBody>
                  <div className="text-center versionLabel">{VERSION_NUMBER}</div>
                </Card>
                <GraanaLogo styles={{ width: '70%', margin: '2% auto' }} marginValue="18" />
              </CardGroup>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

Login.propTypes = {
  // dispatch: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(connect()(Login));
